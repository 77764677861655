import React, { lazy } from 'react'

const SystemAdminListing = lazy(() =>
  import('./SystemAdminListing').then(module => ({
    default: module.SystemAdminListing,
  }))
)
const RolesListing = lazy(() =>
  import('./Roles/RolesListing').then(module => ({
    default: module.RolesListing,
  }))
)
const RolesDetail = lazy(() =>
  import('./Roles/RoleDetail').then(module => ({
    default: module.RoleDetail,
  }))
)
const RolesForm = lazy(() =>
  import('./Roles/RolesForm').then(module => ({
    default: module.RolesForm,
  }))
)
const DefaultMenuOption = lazy(() =>
  import('./Roles/DefaultMenuOption').then(module => ({
    default: module.DefaultMenuOption,
  }))
)
const UserListing = lazy(() =>
  import('./User/UserListing').then(module => ({
    default: module.UserListing,
  }))
)
const UserForm = lazy(() =>
  import('./User/UserForm').then(module => ({
    default: module.UserForm,
  }))
)
const UserDetail = lazy(() =>
  import('./User/UserDetail').then(module => ({
    default: module.UserDetail,
  }))
)
const AuthorisationListing = lazy(() =>
  import('./Authorisation/AuthorisationListing').then(module => ({
    default: module.AuthorisationListing,
  }))
)
const AuthorisationForm = lazy(() =>
  import('./Authorisation/AuthorisationForm').then(module => ({
    default: module.AuthorisationForm,
  }))
)


const SystemAdminRoutes = [
  {
    props: { exact: true, path: '/systemadmin' },
    component: <SystemAdminListing />,
  },
  {
    props: { exact: true, path: '/systemadmin/roles' },
    component: <RolesListing />,
  },
  {
    props: { exact: true, path: '/systemadmin/roles/add' },
    component: <RolesForm formMode={'add'} />,
  },
  {
    props: { exact: true, path: '/systemadmin/roles/types' },
    component: <DefaultMenuOption />,
  },
  {
    props: { exact: true, path: '/systemadmin/roles/edit' },
    component: <RolesForm formMode={'edit'} />,
  },
  {
    props: { exact: true, path: '/systemadmin/roles/detail' },
    component: <RolesDetail />,
  },
  {
    props: { exact: true, path: '/systemadmin/user' },
    component: <UserListing />,
  },
  {
    props: { exact: true, path: '/systemadmin/user/add' },
    component: <UserForm formMode="add" />,
  },
  {
    props: { exact: true, path: '/systemadmin/user/edit' },
    component: <UserForm formMode="edit" />,
  },
  {
    props: { exact: true, path: '/systemadmin/user/detail' },
    component: <UserDetail />,
  },
  {
    props: { exact: true, path: '/systemadmin/authorisation' },
    component: <AuthorisationListing />,
  },
  {
    props: { exact: true, path: '/systemadmin/authorisation/add' },
    component: <AuthorisationForm />,
  },
]

export default SystemAdminRoutes
