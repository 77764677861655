import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { YoutubeSearchedFor } from '@material-ui/icons'
import React, { FunctionComponent } from 'react'
import { List } from '@material-ui/core'

/**
 * # Suggestion
 *
 * ## Thin Wrapper Policy
 * - We should try to make our components as thin as possible a wrapper to reduce complexity
 * and interoperability issues.
 *
 * ## Sectional Parameters Policy
 * - Sections can either take a React component or parameters for JSX interpolation.
 * - If a React component is provided, it will replace the entire section and the parameters will be ignored.
 */

export interface StandardDialogProps extends DialogProps {
  component?: FunctionComponent
  sections?: {
    header?: {
      component?: FunctionComponent
      title?: string
      children?: any
      dynamic?: any
      subtitle?: FunctionComponent | string
      total?: FunctionComponent | string
      actions?: {
        displayText?: string
        props?: ButtonProps
      }[]
    }
    body?: FunctionComponent
    footer?: {
      component?: FunctionComponent
      actions?: {
        displayText?: string
        props?: ButtonProps
      }[]
    }
  }
}

export function CommonDialog(props: StandardDialogProps) {
  /* const classes = useStyles() */

  return (
    <Dialog {...props /* thin wrapper policy, hence just `...props` */}>
      {props?.component?.({}) || (
        <>
          <DialogTitle className="dialog-title">
            {props?.sections?.header?.children ? (
              <List className="core-list  subheader">
                {props?.sections?.header?.children}
              </List>
            ) : null}
            {props?.sections?.header?.dynamic ? (
              <>{props?.sections?.header?.dynamic}</>
            ) : null}
          </DialogTitle>
          <DialogContent className="dialog-content">
            {props?.sections?.body?.({})}
          </DialogContent>
          <DialogActions className="dialog-footer">
            {props?.sections?.footer.component?.({}) ||
              props?.sections?.footer.actions.map((v, i) => (
                <Button
                  variant="contained"
                  size="small"
                  className="btn-dialog btn-primary"
                  key={i}
                  {...v.props}
                >
                  {v.displayText}
                </Button>
              ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
