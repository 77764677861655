import React, { lazy } from 'react'
// import { DocumentType, FrontDeskType } from 'containers/FrontDeskModule/Common'

const GuestProfile = lazy(() =>
  import('./GuestProfile').then(module => ({
    default: module.GuestProfile,
  }))
)
// const GuestProfileDetails = lazy(() =>
//   import('./GuestProfileDetails').then(module => ({
//     default: module.GuestProfileDetails,
//   }))
// )
const GuestPorfileForm = lazy(() =>
  import('./GuestPorfileForm').then(module => ({
    default: module.GuestPorfileForm,
  }))
)
const GuestFolio = lazy(() =>
  import('./GuestFolio').then(module => ({
    default: module.GuestFolio,
  }))
)
const GuestInformation = lazy(() =>
  import('./GuestInformation').then(module => ({
    default: module.GuestInformation,
  }))
)
const GuestDocument = lazy(() =>
  import('./GuestDocument').then(module => ({
    default: module.GuestDocument,
  }))
)
const GuestHistoryFolioList = lazy(() =>
  import('./GuestHistoryFolioList').then(module => ({
    default: module.GuestHistoryFolioList,
  }))
)
const BIDocument = lazy(() =>
  import('../BusinessInsightModule/BIDigitalDocument/BIDocument').then(module => ({
    default: module.BIDocument,
  }))
)
export const GuestProfileURL = '/guestprofile'
export const GuestHistoryFolioURL = `/guestprofile/guesthistoryfoliolist/view`
export const GuestEditHistoryFolioURL = `/guestprofile/guesthistoryfoliolist/edit`
export const GuestIndividualFolioURL = `/guestprofile/individual/folio`
export const GuestProfileViewURL = `/guestprofile/GuestInformation`
export const GuestProfileEditURL = `/guestprofile/GuestProfileForm`
export const GuestFolioURL = `/guestprofile/GuestDocument`
const GuestProfileRoutes = [
  {
    props: { exact: true, path: GuestProfileURL },
    component: <GuestProfile />,
  },
  // {
  //   props: { exact: true, path: '/guestprofile/GuestProfileDetails' },
  //   component: < GuestProfileDetails />,
  // },
  {
    props: { exact: true, path: GuestProfileEditURL },
    component: <GuestPorfileForm />,
  },
  {
    props: { exact: true, path: '/guestprofile/Folio' },
    component: <GuestFolio />,
  },
  {
    props: { exact: true, path: GuestProfileViewURL },
    component: <GuestInformation />,
  },
  {
    props: { exact: true, path: GuestFolioURL },
    component: <GuestDocument type="Folio" />,
  },
  {
    props: { exact: true, path: GuestHistoryFolioURL },
    component: <GuestHistoryFolioList type="Folio" mode="View" />,
  },
  {
    props: { exact: true, path: GuestEditHistoryFolioURL },
    component: <GuestHistoryFolioList type="Folio" mode="Edit" />,
  },
  {
    props: { exact: true, path: GuestIndividualFolioURL },
    component: <BIDocument mode="DueOut" type="Folio" />,
  },
]

export default GuestProfileRoutes
