import React, { lazy } from 'react'

const DebtorProfile = lazy(() =>
  import('./DebtorProfile').then(module => ({
    default: module.DebtorProfile,
  }))
)

const DebtorProfileListing = lazy(() =>
  import('./DebtorProfileListing').then(module => ({
    default: module.DebtorProfileListing,
  }))
)

const DebtorProfileForm = lazy(() =>
  import('../../../SettingsModule/DebtorProfile/DebtorProfileForm').then(module => ({
    default: module.DebtorProfileForm,
  }))
)
const debtorProfileRoutes = [
  // {
  //   props: { exact: true, path: '/cityledger/setup/debtorprofile' },
  //   component: <DebtorProfile />,
  // },
  {
    props: { exact: true, path: '/cityledger/setup/debtorprofile' },
    component: <DebtorProfileListing />,
  },
  {
    props: { exact: true, path: '/cityledger/setup/debtorprofile/add' },
    component: <DebtorProfileForm FormMode={'Add'} />,
  },
  {
    props: { exact: true, path: '/cityledger/setup/debtorprofile/edit' },
    component: <DebtorProfileForm FormMode={'Edit'} />,
  },
]

export default debtorProfileRoutes
