import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogoutMutation } from 'generated/graphql'
import { setAccessToken } from 'AccessToken'
import { client as LogOutClinet } from 'client'
import localForage from 'localforage'
import { SocketContext } from 'containers/Socket/SocketContext'

export const Logout = () => {
  const history = useHistory()
  const { socket}: any =
  useContext(SocketContext)
  
  const [logout, { client }] = useLogoutMutation({ client: LogOutClinet })
  useEffect(() => {
    async function userLogout() {
      await logout()
      socket.emit('data', {Status : 'LOGOUT'})
      setAccessToken('')
      await client.resetStore()
      localStorage.clear()
      localForage.clear()
    }

    localStorage.clear()
    userLogout()
    history.push('/login')
  }, [])
  return <></>
}
