import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'
const AdvancePaymentListing = lazy(() =>
  import('./AdvancePaymentListing').then(module => ({
    default: module.AdvancePaymentListing,
  }))
)
const AdvancePaymentDoc = lazy(() =>
  import('./AdvancePaymentDoc').then(module => ({
    default: module.AdvancePaymentDoc,
  }))
)
const AdvancePaymentDocument = lazy(() =>
  import('./AdvancePaymentDocument').then(module => ({
    default: module.AdvancePaymentDocument,
  }))
)



export const advancePaymentDocumentUrl = `/frontdesk/duein/advancepayment/redirect/:status`

//DUE IN
export const dueInAdvancePaymentUrl = `/frontdesk/duein/detail/advancepayment`
export const dueInAdvancePaymentDocUrl = `/frontdesk/duein/advancepayment/folio`
//IN HOUSE
export const inHouseAdvancePaymentUrl = `/frontdesk/inhouse/advancepayment`
export const inHouseAdvancePaymentDocUrl = `/frontdesk/inhouse/advancepayment/folio`


export const AdvancePaymentRoutes = [
  {
    props: { exact: true, path: dueInAdvancePaymentUrl },
    component: <AdvancePaymentListing mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: inHouseAdvancePaymentUrl },
    component: <AdvancePaymentListing mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: dueInAdvancePaymentDocUrl },
    component: <AdvancePaymentDoc mode={FrontDeskType.DueIn} type={'Receipt'} />,
  },
  {
    props: { exact: true, path: inHouseAdvancePaymentDocUrl },
    component: <AdvancePaymentDoc mode={FrontDeskType.InHouse} type={'Receipt'} />,
  },

  {
    props: { exact: true, path: advancePaymentDocumentUrl },
    component: <AdvancePaymentDocument />,
  },
]
