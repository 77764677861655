import React from 'react'
import { parsePhoneNumber } from 'libphonenumber-js'

export const usePhoneValidation = () => {
  /**
   *
   * @param contactNo to verify
   * @returns boolean
   */
  const isValidPoneNumber = (contactNo: string) => {
    try {
      let phoneNumber = parsePhoneNumber(contactNo)
      return phoneNumber?.isValid()
    } catch (error) {
      return false
    }
  }
  /**
   *
   * @param contactNo to include "+" if missing
   * @returns contactNo
   */
  const validatePhonePrefix = (contactNo: string) => {
    if (contactNo?.charAt(0) !== '+') {
      contactNo = `+${contactNo}`
    }
    return contactNo
  }
  return {
    validatePhonePrefix,
    isValidPoneNumber,
  }
}
