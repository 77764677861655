import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'

const GroupCheckInList = lazy(() =>
  import('./GroupCheckInList').then(module => ({
    default: module.GroupCheckInList,
  }))
)
const GroupCheckIn = lazy(() =>
  import('./GroupCheckIn').then(module => ({
    default: module.GroupCheckIn,
  }))
)
export const groupCheckInListUrl = `/frontdesk/groupcheckin`
export const groupCheckInUrl = `/frontdesk/groupcheckin/checkin`
export const dueOutBSPath = `/frontdesk/dueout/detail/billingschedule`

export const GroupCheckInRoutes = [
  {
    props: { exact: true, path: groupCheckInListUrl },
    component: <GroupCheckInList />,
  },
  {
    props: { exact: true, path: groupCheckInUrl },
    component: <GroupCheckIn />,
  },
]
