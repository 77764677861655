import { Button, ButtonProps } from '@material-ui/core'
import React from 'react'
interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number
  single?: Boolean
  isHidden?: boolean
  fixed?: boolean
}
export const Footer = (props: IFooterProps) => {
  const { chipLabel, options, value, isHidden, fixed } = props
  let single: boolean = false

  if (options.length === 1) {
    single = true
  }

  return (
    <div
      id="footer"
      className="footer"
      style={{ position: fixed ? 'fixed' : null }}
    >
      {chipLabel && (
        <div className="footer-label">
          <div className="chip-label">{chipLabel}</div>

          <span className="label-value">{value}</span>
        </div>
      )}
      {isHidden ? null : (
        <div className="footer-btn">
          {options.map((el, index) => (
            <Button
              key={index}
              type="submit"
              color={el.color}
              variant="contained"
              style={{ minWidth: options.length > 2 ? '50px' : '126px' }}
              className={single ? 'single' : ''}
              onClick={el.onClick}
              disabled={el.disabled}
              startIcon={el.startIcon}
              {...el.props}
            >
              {el.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}
