import React, { lazy } from 'react'

const RatePolicy = lazy(() =>
  import('./RatePolicy').then(module => ({
    default: module.RatePolicy,
  }))
)

const RatePolicyForm = lazy(() =>
  import('./RatePolicyForm').then(module => ({
    default: module.RatePolicyForm,
  }))
)

const ratePolicyRoutes = [
  {
    props: { exact: true, path: '/settings/ratepolicysetup' },
    component: <RatePolicy />,
  },

  {
    props: { exact: true, path: '/settings/ratepolicysetup/add' },
    component: <RatePolicyForm mode="Add" />,
  },

  {
    props: {
      exact: true,
      path: '/settings/ratepolicysetup/edit',
    },
    component: <RatePolicyForm mode="Edit" />,
  },
]

export default ratePolicyRoutes
