import { BIDocument } from 'containers/BusinessInsightModule/BIDigitalDocument/BIDocument'
import React, { lazy } from 'react'
import { SuspendDocumentPromenadeBintulu } from './SuspendDocumentCustom/SuspendDocumentPromenadeBintulu'
import { SuspendDocumentPromenadeKK } from './SuspendDocumentCustom/SuspendDocumentPromenadeKK'
import { SuspendDocumentMaya } from './SuspendDocumentCustom/SuspendDocumentMaya'
const SuspendFolioList = lazy(() =>
  import('./SuspendFolioList').then(module => ({
    default: module.SuspendFolioList,
  }))
)
const SuspendFolioForm = lazy(() =>
  import('./SuspendFolioForm').then(module => ({
    default: module.SuspendFolioForm,
  }))
)
const SuspendFolio = lazy(() =>
  import('./SuspendFolio').then(module => ({
    default: module.SuspendFolio,
  }))
)
const SuspendDocument = lazy(() =>
  import('./SuspendDocument').then(module => ({
    default: module.SuspendDocument,
  }))
)
export const SuspendFolioUrl = '/'
export const SuspendFolioRoutes = [
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist' },
    component: <SuspendFolioList />,
  },
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist/add' },
    component: <SuspendFolioForm mode={'Add'} />,
  },
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist/edit' },
    component: <SuspendFolioForm mode={'Edit'} />,
  },
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist/folio' },
    component: <SuspendFolio />,
  },
  {
    props: {
      exact: true,
      path: '/frontdesk/suspendfoliolist/folio/redirect/:status',
    },
    component: <SuspendFolio />,
  },
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist/folio/print' },
    component: <SuspendDocument mode={''} type="NoGuest" />,
  },
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist/folio/printCustomPromenadeMaya' },
    component: <SuspendDocumentMaya mode={''} type="NoGuest" />,
  },
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist/folio/printCustomPromenadeBintulu' },
    component: <SuspendDocumentPromenadeBintulu mode={''} type="NoGuest" />,
  },
  {
    props: { exact: true, path: '/frontdesk/suspendfoliolist/folio/printCustomPromenadeKK' },
    component: <SuspendDocumentPromenadeKK mode={''} type="NoGuest" />,
  },
]
