import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'
import { AttachmentsListing } from 'containers/AttachmentsModule/AttachmentsListing'
const DueOut = lazy(() =>
  import('./DueOut').then(module => ({
    default: module.DueOut,
  }))
)
const DueOutSubmenu = lazy(() =>
  import('./DueOutSubmenu').then(module => ({
    default: module.DueOutSubmenu,
  }))
)
const FolioHistory = lazy(() =>
  import('./FolioHistory').then(module => ({
    default: module.FolioHistory,
  }))
)
const BIDocument = lazy(() =>
  import('../../BusinessInsightModule/BIDigitalDocument/BIDocument').then(module => ({
    default: module.BIDocument,
  }))
)
const ReceiptDocument = lazy(() =>
  import('../../BusinessInsightModule/BIDigitalDocument/ReceiptDocument').then(module => ({
    default: module.ReceiptDocument,
  }))
)
const CompanyDetail = lazy(() =>
  import('../DueIn/CompanyDetail').then(module => ({
    default: module.CompanyDetail,
  }))
)

export const dueOutUrl = `/frontdesk/dueout`
export const dueOutDetailUrl = `/frontdesk/dueout/detail`
export const dueOutFolioHistoryUrl = `/frontdesk/dueout/history`
export const dueOutAttachmentsUrl = `/frontdesk/dueout/attachments`
export const dueOutFolioHistoryDocUrl = `/frontdesk/dueout/history/folio`
export const dueOutReceiptHistoryDocUrl = `/frontdesk/dueout/history/receipt`
export const dueOutCompanyUrl = `/frontdesk/dueout/corporatedetail`

export const DueOutRoutes = [
  {
    props: { exact: true, path: dueOutUrl },
    component: <DueOut />,
  },
  {
    props: { exact: true, path: dueOutDetailUrl },
    component: <DueOutSubmenu />,
  },
  {
    props: { exact: true, path: dueOutFolioHistoryUrl },
    component: <FolioHistory mode={FrontDeskType.DueOut} />,
  },
  {
    props: { exact: true, path: dueOutAttachmentsUrl },
    component: <AttachmentsListing type={'Registration'}/>,
  },
  {
    props: { exact: true, path: dueOutFolioHistoryDocUrl },
    component: <BIDocument mode={FrontDeskType.DueOut} />,
  },
  {
    props: { exact: true, path: dueOutReceiptHistoryDocUrl },
    component: <ReceiptDocument mode={FrontDeskType.DueOut} />,
  },
  {
    props: { exact: true, path: dueOutCompanyUrl },
    component: <CompanyDetail />,
  },
]
