import React, { lazy } from 'react'

const Location = lazy(() =>
  import('./Location').then(module => ({
    default: module.Location,
  }))
)

const LocationForm = lazy(() =>
  import('./LocationForm').then(module => ({
    default: module.LocationForm,
  }))
)

const FloorPlan = lazy(() =>
  import('../FloorPlan/FloorPlanNew').then(module => ({
    default: module.FloorPlanNew,
  }))
)

const locationRoutes = [
  {
    props: { exact: true, path: '/settings/location' },
    component: <Location />,
  },
  {
    props: { exact: true, path: '/settings/location/add' },
    component: <LocationForm mode="New" />,
  },
  {
    props: { exact: true, path: '/settings/location/edit' },
    component: <LocationForm mode="Edit" />,
  },
]

export default locationRoutes
