import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { SearchActionHeader } from 'components/Header/SearchActionHeader'
import AppContext from 'containers/App/Store/AppContext'
import React, {
  Reducer,
  useContext,
  useReducer,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router'
import { Filter } from 'containers/helper/SearchFilter'
import { useMenuOption } from 'containers/helper/hooks/useMenuOption'
import { MoreVert } from '@material-ui/icons'
import { IAction } from 'containers/helper/models'
import { AttachmentsDeleteDialog } from './AttachmentsDeleteDialog/AttachmentsDeleteDialog'
import { AttachmentsDialog } from './AttachmentsDialog/AttachmentsDialog'
import { HotelPermission, useBookingAttachmentListingQuery } from 'generated/graphql'
import { AttachmentsDownloadDialog } from './AttachmentsDownloadDialog/AttachmentsDownloadDialog'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { usePermissionChecker } from 'containers/helper/hooks/usePermissionChecker'
let dateFormat = require("dateformat");

interface Props {
  Remark?: string
  RegistrationID?: any
  Attachment?: any
  addDialog?: boolean
  mode?: 'add' | 'edit'
  menu?: any
  attachmentDetail?: any
}

interface PageProps {
  type?: string
}

export const AttachmentsListing = (props: PageProps) => {
  const {type } = props
  let history = useHistory()
  const location = useLocation()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [openDeleteDialog, setDeleteDialog] = useState(false)
  const [openDownloadDialog, setDownloadDialog] = useState(false)
  const { snackFunc, close, snackBarMessage, openSnackBar } = useSnackBar()
  const bookingInfo: any = location.state

  const [filterSearch, setFilterSearch] = useState<string>(
    localStorage.getItem('filterValue') || ''
  )
  const { handlePermDisabled } = usePermissionChecker()
  window.onbeforeunload = event => {
    localStorage.removeItem('filterValue')
  }
  const { globalState, dispatch: globalDispatch } = useContext(
    AppContext as any
  )
  const triggerDeleteDialogOpen = () => {
    setDeleteDialog(true)
  }
  const triggerDeleteDialogClose = () => {
    setDeleteDialog(false)
    snackFunc(
      'Successfully delete attachments',
      false
    )
  }
  const triggerDownloadDialogOpen = () => {
    setDownloadDialog(true)
  }
  const triggerDownloadDialogClose = () => {
    setDownloadDialog(false)
  }
  const initialState: Props = {
    Remark: '',
    Attachment: [],
    addDialog: false,
    mode: 'add',
    menu: '',
    RegistrationID: type === "Registration" ? bookingInfo?.guest?.ID : null ,
    attachmentDetail: []
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      case 'addDialog':
        return { ...state, addDialog: action.payload, mode: 'add' } // Set mode to 'add' when opening the dialog
      case 'editDialog':
        return { ...state, addDialog: action.payload, mode: 'edit' } // Set mode to 'edit' when opening the dialog
      default:
        return { ...state, [action.type]: action.payload }
    }
  }


  console.log(type,"ada type")
  const [state, dispatch] = useReducer(reducer, initialState)
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    refetch,
    loading: BookingAttachmentLoading,
    data: { BookingAttachmentListing } = {
      BookingAttachmentListing: [],
    },
  } = useBookingAttachmentListingQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
      BookingID: bookingInfo?.Booking?.ID || bookingInfo?.guest?.BookingID,
      RegistrationID: type === "Registration" ? bookingInfo?.guest?.ID : null
    },
    fetchPolicy: 'no-cache',
  })

  console?.log(type,BookingAttachmentLoading)

  return BookingAttachmentLoading ? <Loading /> : (
    
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.goBack()}
        smTitle="HMS"
        title={JSON.parse(localStorage.getItem('Hotel'))?.HotelName}
        routeSegments={
          [
            { name: 'Booking' },
            { name: 'Booking' },
            { name: 'Attachments', current: true },
          ]
        }
      />
      <SearchActionHeader
        search
        title="Attachments"
        value={BookingAttachmentListing?.length}
        onChangeAction={e => {
          setFilterSearch(e.target.value)
        }}
        onCloseAction={e => {
          setFilterSearch('')
          localStorage.removeItem('filterValue')
        }}
        mic
        filterSearch={filterSearch}
        setFilterSearch={setFilterSearch}
      />
      <ContentWrapper search float>
        <List className="core-list">
          {BookingAttachmentListing?.length === 0 ? (
            <EmptyList title="No Record Found" />
          ) : null}
          {Filter(BookingAttachmentListing, filterSearch, [
            'Date',
            'Remark'
          ])?.map((el, index) => (
            <ListItem
              key={index}
            >
              <ListItemText
                primary={
                  <>
                    <span className="xsTitle flex-space ">
                      {el?.Remark}
                    </span>
                    <span className="desc">
                      <AttachFileIcon style={{ fontSize: 'small', verticalAlign: 'middle' }}
                        onClick={() => {
                          setSelectedIndex(index); // Set the selected index
                          triggerDownloadDialogOpen(); // Open the download dialog
                        }} />
                      {dateFormat(el?.CreatedDT, "dd mmm yyyy")}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc">
                      &nbsp;
                    </span>
                  </>
                }
              />
              <ListItemSecondaryAction style={{ top: '30px' }}>
                <IconButton
                  edge="end"
                  onClick={e => {
                    localStorage.setItem('filterValue', filterSearch)
                    handleClick(e, el.ID, index, el)
                  }}
                >
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch({ type: 'menu', payload: menu?.obj })
            dispatch({ type: 'editDialog', payload: true })
          }}
          disabled={
            handlePermDisabled({
              hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
              permEnum: type === 'Booking' 
              ? HotelPermission.BookingAttachmentsEdit 
              : HotelPermission.FrontDeskInhouseAttachmentsEdit,
            })
          }
        >
          Edit
        </MenuItem>
        <MenuItem
           disabled={
            handlePermDisabled({
              hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
              permEnum: type === 'Booking' 
              ? HotelPermission.BookingAttachmentsDelete
              : HotelPermission.FrontDeskInhouseAttachmentsDelete,
            })
          }
          onClick={() => triggerDeleteDialogOpen()
          }        >
          Delete
        </MenuItem>
        <AttachmentsDeleteDialog
          open={openDeleteDialog}
          close={triggerDeleteDialogClose}
          state={menu?.obj}
          refetch={refetch}
        />
        <AttachmentsDownloadDialog
          open={openDownloadDialog}
          close={triggerDownloadDialogClose}
          state={BookingAttachmentListing}
          index={selectedIndex}
        />
      </Menu>
      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

      <FloatButton
            disabled={
              handlePermDisabled({
                hotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
                permEnum: type === 'Booking'
                ? HotelPermission.BookingAttachmentsCreate
                : HotelPermission.FrontDeskInhouseAttachmentsCreate

              ,
              })
            }
        onClick={() => dispatch({ type: 'addDialog', payload: true })}
      />
      <AttachmentsDialog
        open={state.addDialog}
        mode={state.mode}
        dispatch={dispatch}
        state={state}
        bookingInfo={bookingInfo}
        refetch={refetch}
      />
    </>
  )
}
