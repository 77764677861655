import React, { lazy } from 'react'
import HouseKeepingDetailRoutes from './HousekeepingDetail/HousekeepingDetailRoutes'

const Housekeeping = lazy(() =>
  import('./Housekeeping').then(module => ({
    default: module.Housekeeping,
  }))
)

const HousekeepingRoom = lazy(() =>
  import('./HousekeepingRoom').then(module => ({
    default: module.HousekeepingRoom,
  }))
)


const HousekeepingRoutes = [
  {
    props: { exact: true, path: '/housekeeping' },
    component: <Housekeeping />,
  },
  {
    props: { exact: true, path: '/housekeeping/room' },
    component: <HousekeepingRoom />,
  },
]

HouseKeepingDetailRoutes.forEach(route => {
  HousekeepingRoutes.push(route)
})

export default HousekeepingRoutes
