import React, { lazy } from 'react'

// const ServiceRequest = lazy(() =>
//   import('./ServiceRequest').then(module => ({
//     default: module.ServiceRequest,
//   }))
// )
// const ServiceRequestList = lazy(() =>
//   import('./ServiceRequestList').then(module => ({
//     default: module.ServiceRequestList,
//   }))
// )
const ServiceRequestDetail = lazy(() =>
  import('./ServiceRequestDetail').then(module => ({
    default: module.ServiceRequestDetail,
  }))
)
const ServiceRequestForm = lazy(() =>
  import('./ServiceRequestForm').then(module => ({
    default: module.ServiceRequestForm,
  }))
)
const ServiceRequests = lazy(() =>
  import('./ServiceRequests').then(module => ({
    default: module.ServiceRequests,
  }))
)

export const serviceReqUrl = `/frontdesk/servicerequest`
export const serviceReqListUrl = `/frontdesk/servicerequest/list`
export const serviceReqAddUrl = `/frontdesk/servicerequest/add`
export const serviceReqEditUrl = `/frontdesk/servicerequest/edit`
export const serviceReqDetailUrl = `/frontdesk/servicerequest/detail`
export const ServiceRequestRoutes = [
  {
    props: { exact: true, path: '/servicerequest' },
    component: <ServiceRequests Module="ServiceRequest" />,
  },
  // {
  //   props: { exact: true, path: '/servicerequest' },
  //   component: <ServiceRequest />,
  // },
  // {
  //   props: { exact: true, path: '/servicerequest/list' },
  //   component: <ServiceRequestList />,
  // },
  {
    props: { exact: true, path: '/servicerequest/detail' },
    component: <ServiceRequestDetail Module="ServiceRequest" />,
  },
  {
    props: { exact: true, path: '/servicerequest/edit' },
    component: <ServiceRequestForm mode="Edit" Module="ServiceRequest" />,
  },
  {
    props: { exact: true, path: '/servicerequest/add' },
    component: <ServiceRequestForm mode="Add" Module="ServiceRequest" />,
  },
  // //Home page notfication
  // {
  //   props: { exact: true, path: '/home/notification/servicerequest/detail' },
  //   component: <ServiceRequestDetail />,
  // },
  // {
  //   props: { exact: true, path: '/home/notification/servicerequest/edit' },
  //   component: <ServiceRequestForm mode="Edit" />,
  // },
  // Front Desk
  {
    props: { exact: true, path: serviceReqUrl },
    component: <ServiceRequests Module="FrontDesk" />,
  },
  // {
  //   props: { exact: true, path: serviceReqListUrl },
  //   component: <ServiceRequestList origin="Front Desk" />,
  // },
  {
    props: { exact: true, path: serviceReqDetailUrl },
    component: <ServiceRequestDetail Module="FrontDesk" />,
  },
  {
    props: { exact: true, path: serviceReqEditUrl },
    component: <ServiceRequestForm mode="Edit" Module="FrontDesk" />,
  },
  {
    props: { exact: true, path: serviceReqAddUrl },
    component: <ServiceRequestForm mode="Add" Module="FrontDesk" />,
  },
]

export default ServiceRequestRoutes
