import React, { lazy } from 'react'

const RoomTypeListing = lazy(() =>
  import('./RoomTypeList').then(module => ({
    default: module.RoomTypeList,
  }))
)

const RoomListing = lazy(() =>
  import('./RoomListing').then(module => ({
    default: module.RoomListing,
  }))
)

const RoomForm = lazy(() =>
  import('./RoomForm').then(module => ({
    default: module.RoomForm,
  }))
)

const roomRoutes = [
  {
    props: { exact: true, path: '/settings/roomsetup' },
    component: <RoomTypeListing />,
  },
  {
    props: { exact: true, path: '/settings/roomsetup/roomlisting' },
    component: <RoomListing />,
  },
  {
    props: {
      exact: true,
      path: '/settings/roomsetup/roomlisting/add',
    },
    component: <RoomForm mode="New" />,
  },
  {
    props: {
      exact: true,
      path: '/settings/roomsetup/roomlisting/edit',
    },
    component: <RoomForm mode="Edit" />,
  },
]

export default roomRoutes
