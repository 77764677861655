import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'

const ServiceRequest = lazy(() =>
  import('./ServiceRequest').then(module => ({
    default: module.ServiceRequest,
  }))
)
const ServiceRequestForm = lazy(() =>
  import('./ServiceRequestForm').then(module => ({
    default: module.ServiceRequestForm,
  }))
)
const ServiceRequestDetail = lazy(() =>
  import('./ServiceRequestDetail').then(module => ({
    default: module.ServiceRequestDetail,
  }))
)
export const inHouseServiceReqUrl = `/frontdesk/inhouse/servicerequest`
export const inHouseServiceReqAddUrl = `/frontdesk/inhouse/servicerequest/add`
export const inHouseServiceReqEditUrl = `/frontdesk/inhouse/servicerequest/edit`
export const inHouseServiceReqDetailUrl = `/frontdesk/inhouse/servicerequest/detail`

export const walkInServiceReqUrl = `/frontdesk/walkin/servicerequest`
export const walkInServiceReqAddUrl = `/frontdesk/walkin/servicerequest/add`
export const walkInServiceReqEditUrl = `/frontdesk/walkIn/servicerequest/edit`
export const walkInServiceReqDetailUrl = `/frontdesk/walkIn/servicerequest/detail`
export const DueInServiceRequestRoutes = [
  {
    props: { exact: true, path: inHouseServiceReqUrl },
    component: <ServiceRequest mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: inHouseServiceReqAddUrl },
    component: (
      <ServiceRequestForm mode={FrontDeskType.InHouse} formMode="Add" />
    ),
  },
  {
    props: { exact: true, path: inHouseServiceReqEditUrl },
    component: (
      <ServiceRequestForm mode={FrontDeskType.InHouse} formMode="Edit" />
    ),
  },
  {
    props: { exact: true, path: inHouseServiceReqDetailUrl },
    component: <ServiceRequestDetail mode={FrontDeskType.InHouse} />,
  },

  {
    props: { exact: true, path: walkInServiceReqUrl },
    component: <ServiceRequest mode={FrontDeskType.WalkIn} />,
  },
  {
    props: { exact: true, path: walkInServiceReqAddUrl },
    component: (
      <ServiceRequestForm mode={FrontDeskType.WalkIn} formMode="Add" />
    ),
  },
  {
    props: { exact: true, path: walkInServiceReqEditUrl },
    component: (
      <ServiceRequestForm mode={FrontDeskType.WalkIn} formMode="Edit" />
    ),
  },
  {
    props: { exact: true, path: walkInServiceReqDetailUrl },
    component: <ServiceRequestDetail mode={FrontDeskType.WalkIn} />,
  },
]
