import React, { useState } from 'react'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from 'react-share'
import { b64toBlob } from 'containers/FrontDeskModule/Common'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { Email } from '@material-ui/icons'
import { Button, IconButton } from '@material-ui/core'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput'
interface ShareType {
  simple: boolean
  setSimple: any
  Title: string
  ShareTitle: string
  URL: string
  EmailShare?: boolean
  setSimpleEmail?: any
  isDirect?: boolean;
	phoneNumber?: string;
	setOpenPhoneNum?: any;
}
const dateFormat = require('dateformat')

export const htmlConverter = ({ FormContent, Name }) => {
  var blob = new Blob([FormContent], { type: 'text/html' })
  blob['name'] = Name + '.html'
  blob['lastModified'] = Date.UTC(
    dateFormat(new Date(), 'yyyy'),
    dateFormat(new Date(), 'mm'),
    dateFormat(new Date(), 'dd')
  )
  blob['lastModifiedDate'] = new Date()

  return blob
}

const ShareDialog = (props: ShareType) => {
  const {
    simple,
    setSimple,
    Title,
    URL,
    ShareTitle,
    EmailShare,
    setSimpleEmail,
    isDirect,
		phoneNumber,
		setOpenPhoneNum,
  } = props

  return (
    <StandardDialog
      fullWidth={true}
      open={simple}
      onClose={() => setSimple(false)}
      sections={{
        header: {
          title: `${Title}`,
        },
        body: () => (
          <>
            <div className="content-container">
              {isDirect === true ? (
                <>
                  <IconButton
										edge="end"
										aria-label="delete"
										onClick={() =>
											phoneNumber === undefined ||
											phoneNumber === null ||
											phoneNumber === ''
												? (setOpenPhoneNum(true), setSimple(false))
												: window.open(
														`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
															`${ShareTitle} ${URL}`,
														)}`,
												  )
										}
									>
										<WhatsappIcon
											style={{ color: '#4FCE5D', marginRight: '5px' }}
											size={40}
											round={true}
										/>
									</IconButton>
                </>
              ) : (
                <>
                  <WhatsappShareButton title={ShareTitle} url={URL}>
                    <WhatsappIcon
                      style={{ marginRight: '5px' }}
                      size={40}
                      round={true}
                    />
                  </WhatsappShareButton>
                </>
              )}
              <FacebookShareButton title={ShareTitle} url={URL}>
                <FacebookIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </FacebookShareButton>
              <TelegramShareButton title={ShareTitle} url={URL}>
                <TelegramIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </TelegramShareButton>
              <TwitterShareButton title={ShareTitle} url={URL}>
                <TwitterIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </TwitterShareButton>

              {EmailShare && (
                <Button onClick={() => setSimpleEmail(true)}>
                  <MailOutlineIcon
                    style={{ marginRight: '5px', fontSize: 40 }}

                    // href={`mailto:${EmailShare}?subject=&body=Booking Confirmation Letter`}
                  />
                </Button>
              )}
            </div>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setSimple(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}

export const PhoneNumDialog = props => {
  const { open, setOpen, shareTitle, URL } = props;

	const [phoneNum, setPhoneNum] = useState('');

  return (
    <>
      <CommonDialog
        fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Whatsapp Phone Number</div>
									</div>
								</div>
							</div>
            ),
          },
          body: () => (
            <>
              <PhoneNumInput
                name="phoneNumber"
                country={'my'}
                specialLabel="Phone Number"
                autoComplete="off"
                multiline
                fullWidth
                margin="normal"
                onChange={e => setPhoneNum(e)}
              ></PhoneNumInput>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setPhoneNum('')
                    setOpen(false)
                  },
                  variant: 'contained',
                  color: 'primary'
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    setOpen(false)
                    window.open(
                      `https://wa.me/${phoneNum}?text=${encodeURIComponent(
                        `${shareTitle} ${URL}`,
                      )}`,
                    );
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              }
            ]
          }
        }}
      />
    </>
  )
}

const ConvertFormToURL = ({ FormContent, Name }) => {
  var contentType = 'text/html'
  const blob = new Blob([FormContent], { type: contentType })
  // var encodedStringBtoA = btoa(FormContent)
  // const blob = b64toBlob(encodedStringBtoA, contentType, 512)
  blob['name'] = Name + '.html'
  blob['lastModified'] = Date.UTC(
    dateFormat(new Date(), 'yyyy'),
    dateFormat(new Date(), 'mm'),
    dateFormat(new Date(), 'dd')
  )
  blob['lastModifiedDate'] = new Date()

  return blob
}
export { ShareDialog as default, ConvertFormToURL }
