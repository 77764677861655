import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import React from 'react'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
//import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import { Filter } from 'containers/helper/SearchFilter'
import { useMenuOption } from 'containers/helper/hooks/useMenuOption'
import { SearchHeader } from 'components/Header/SearchHeader'
import ListSubheader from '@material-ui/core/ListSubheader'

interface BookingAuditLogListingProps {
  queryData: any
  queryLoading: any
  dropdownOptions: any[]
  dropdownValue?: {
    DisplayValue: string
    TableName: string
    FieldID: string
    SubDisplayValue: any
  }
  setDropdownValue?: any
  infiniteScroll: {
    next: any
  }
  filterVariables?: string[]
  hasSubheader?: boolean
}

const BookingAuditLogListing = (props: BookingAuditLogListingProps) => {
  const {
    queryData,
    queryLoading,
    setDropdownValue,
    dropdownOptions,
    dropdownValue,
    hasSubheader,
    infiniteScroll,
    filterVariables,
  } = props

  const dateFormat = require('dateformat')

  const [auditDialog, setAuditDialog] = useState(false)

  const [filterSearch, setFilterSearch] = useState<string>(
    ''
  )

  window.onbeforeunload = event => {
    localStorage.removeItem('filterValue')
  }

  const rename = (data: any) => {
    if (data === 'Modified') {
      return 'Modified'
    } else if (data === 'New') {
      return 'New'
    }
  }

  const newqueryData =  queryData?.filter(
    i => (i.OldValue !== null || i.NewValue !== null)
  )

  const { menu, handleClick }: any = useMenuOption()

  return (
    <>
      <List
        id="dropdown-subheader"
        className="dropdown-subheader"
        style={{
          borderRadius: '4px',
          // top:
          //   isDesktop && hasSubheader
          //     ? '118px'
          //     : hasSubheader
          //     ? '55px'
          //     : isDesktop
          //     ? '63px'
          //     : '0px',
        }}
      >
        <ListItem>
          <Grid container>
            <Grid item xs={12} component="span">
              <Autocomplete
                options={[...dropdownOptions]}
                fullWidth
                value={dropdownValue}
                groupBy={option => 
                  option?.RegistrationID 
                    ? option?.RegistrationID
                    : option?.GroupName || ''
                }
                getOptionLabel={option => option?.DisplayValue ?? ''}
                renderOption={option => {
                  return (
                    <div className="fs-14 display-flex flex-space m-t-4 m-b-4">
                      <span className="flex-space">{option.DisplayValue}</span>

                      <span className="">{option?.SubDisplayValue}</span>
                    </div>
                  )
                }}
                renderGroup={(params) => [
                  <>
                    <ListSubheader 
                      style={{ 
                        position: 'sticky',
                        top: '-10px',
                        backgroundColor: '#ffffff',
                      }}
                    >
                      {dropdownOptions?.find(x => x.RegistrationID === params.group)?.GroupName
                        ? dropdownOptions?.find(x => x.RegistrationID === params.group)?.GroupName
                        : params.group
                      }
                    </ListSubheader>
                    {params.children}
                  </>
                ]}
                onChange={(_, value: any) => {
                  setDropdownValue(value)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    required
                    label="Option"
                    margin="normal"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ListItem>
      </List>

      {/* Content */}
      {/* Content */}
      {/* Content */}

      <ContentWrapper auditLogSubheaderSearch={hasSubheader ? true : false}>
        <SearchHeader
          title="Search here"
          onChangeAction={e => {
            setFilterSearch(e.target.value)
          }}
          search
          auditLogSearchHeader
          onCloseAction={e => {
            setFilterSearch('')
            localStorage.removeItem('filterValue')
          }}
        />

        {newqueryData?.length === 0 ? (
          <EmptyList title="No Records Found" />
        ) : (
          <List className="core-list rm-padding-infinitescroll">
            <InfiniteScroll
              dataLength={newqueryData?.length}
              next={infiniteScroll?.next}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                queryLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {Filter(newqueryData, filterSearch, [...filterVariables])?.map(
                (el, index) => {
                  return (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <span className="xsTitle flex-space">
                              {el?.FieldName}
                            </span>
                            <span className="xsTitle right-info">
                              {dateFormat(el?.ActionDate, 'dd mmm yyyy hh:MM tt')}
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <span className="extra-row">
                              <span className="icon-text desc text-overflow flex-space">
                                {el?.ModifiedByUserName ||
                                  el?.CreatedByUserName}
                              </span>
                              <span className="icon-text desc text-overflow right-info">
                                {rename(el?.Action)}
                              </span>
                            </span>
                            <span className="extra-row">
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="desc"
                              >
                                {'Old value:'}
                              </span>
                              <span className="icon-text desc text-overflow">
                                &nbsp;
                                {el?.OldValue}
                              </span>
                            </span>
                            <span className="extra-row">
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="desc"
                              >
                                {'New value:'}
                              </span>
                              <span className="icon-text desc text-overflow">
                                &nbsp;
                                {el?.NewValue}
                              </span>
                            </span>
                          </>
                        }
                      />
                      <ListItemSecondaryAction style={{ top: '30px' }}>
                        <IconButton
                          edge="end"
                          onClick={e => {
                            setAuditDialog(true)
                            handleClick(e, el?.ID, index, el)
                          }}
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                }
              )}
            </InfiniteScroll>
          </List>
        )}
      </ContentWrapper>

      {/* Detail Dialog  */}
      {/* Detail Dialog  */}
      {/* Detail Dialog  */}

      <StandardDialog
        fullWidth={true}
        open={auditDialog}
        onClose={() => setAuditDialog(false)}
        sections={{
          header: {
            title: 'Audit Log Details',
          },
          body: () => (
            <>
              <div className="content-wrap left">
                <div className="desc">User Name</div>
                <div className="xxTitle">
                  {menu?.obj?.ModifiedByUserName ||
                    menu?.obj?.CreatedByUserName}
                </div>
              </div>
              <Grid container>
                <Grid item xs={6}>
                  <div className="content-wrap right">
                    <div className="desc">Change Date</div>
                    <div className="xxTitle">
                      {dateFormat(menu?.obj?.ActionDate, 'dd mmm yyyy hh:MM tt')}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="content-wrap left">
                    <div className="desc">Action</div>
                    <div className="xxTitle">{rename(menu?.obj?.Action)}</div>
                  </div>
                </Grid>
              </Grid>

              <div className="content-wrap right">
                <div className="desc">Field Name</div>
                <div className="xxTitle">{menu?.obj?.FieldName}</div>
              </div>
              <Grid container>
                <Grid item xs={6}>
                  <div className="content-wrap left">
                    <div className="desc">Old Value</div>
                    {/* check balik */}
                    <div className="xxTitle">{menu?.obj?.OldValue}</div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="content-wrap right">
                    <div className="desc">New Value</div>
                    {/* check balik */}
                    <div className="xxTitle">{menu?.obj?.NewValue}</div>
                  </div>
                </Grid>
              </Grid>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setAuditDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      ></StandardDialog>
    </>
  )
}

export default BookingAuditLogListing
