import React, { lazy } from 'react'

const CommonSettingsListing = lazy(() =>
  import('./CommonSettingsListing').then(module => ({
    default: module.CommonSettingsListing,
  }))
)

const TaxPolicy = lazy(() =>
  import('./TaxPolicy/TaxPolicy').then(module => ({
    default: module.TaxPolicy,
  }))
)
const TaxPolicyListing = lazy(() =>
  import('./TaxPolicy/TaxPolicyListing').then(module => ({
    default: module.TaxPolicyListing,
  }))
)
const TaxPolicyForm = lazy(() =>
  import('./TaxPolicy/TaxPolicyForm').then(module => ({
    default: module.TaxPolicyForm,
  }))
)
const TaxInformation = lazy(() =>
  import('./TaxPolicy/TaxInformation').then(module => ({
    default: module.TaxInformation,
  }))
)
const TaxSchemeListing = lazy(() =>
  import('./TaxScheme/TaxSchemeListing').then(module => ({
    default: module.TaxSchemeListing,
  }))
)
const TaxSchemeView = lazy(() =>
  import('./TaxScheme/TaxSchemeView').then(module => ({
    default: module.TaxSchemeView,
  }))
)
const TaxSchemeForm = lazy(() =>
  import('./TaxScheme/TaxSchemeForm').then(module => ({
    default: module.TaxSchemeForm,
  }))
)

const CommonSettingsRoutes = [
  {
    props: { exact: true, path: '/common-settings' },
    component: <CommonSettingsListing />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-policy',
    },
    component: <TaxPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-policy/add',
    },
    component: <TaxPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-policy/edit',
    },
    component: <TaxPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-policy/view',
    },
    component: <TaxInformation />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-scheme-policy',
    },
    component: <TaxSchemeListing />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-scheme-policy/view',
    },
    component: <TaxSchemeView />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-scheme-policy/add',
    },
    component: <TaxSchemeForm Mode={"Add"} />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/tax-scheme-policy/edit',
    },
    component: <TaxSchemeForm Mode={"Edit"} />,
  },
]

export default CommonSettingsRoutes