import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Button, TextField } from '@material-ui/core'
import Background from 'assets/images/HR-Background.jpg'
import Logo from 'assets/images/HotelX-Logo.png'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { encryptMessage } from 'containers/helper/Crypto/JsEncrypt'
import { useResetPasswordMutation } from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { CopyRight } from '@ifca-root/react-component/src/components/AuthLayout/CopyRight'
import jwtDecode from 'jwt-decode'

interface IResetPasswordForm {
  password: string
  confirmPassword: string
}

export const ResetPassword = props => {
  let history = useHistory()
  const { token }: any = useParams()
  const [openAlert, setOpenAlert] = useState(false)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState('')
  const [message, setMessage] = useState('')
  const tokenInfo:any = jwtDecode(token)
  // console.log(jwtDecode(token))
  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/Login')
  }
  const MESSAGE = 'Password has been reset Successfully. Please Login Again'

  const [
    resetPasswordMutation,
    { data, loading: mutationLoading, error: mutationError },
  ] = useResetPasswordMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    onCompleted: data => {
      console.log('data', data)
      reset()
      setOpen(true)

      // history.push('/')
    },

    variables: {
      password: '',
      token: '',
    },
  })

  const [values, setValues] = React.useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: false,
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  //useForm set DefaultValues, validationSchema
  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
    setError,
    getValues,
  } = useForm<IResetPasswordForm>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    // resolver: yupResolver(ResetPasswordSchema) as any,
  })
  console.log(errors)

  const onSubmit = (data: any) => {
    resetPasswordMutation({
      variables: {
        password: encryptMessage(data?.password),
        token: token,
      },
    })
    // .then(x=>{
    //   history.push("/Login")
    // })
  }

  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Change Password',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout logo={Logo} image={Background}>
        <span className="page-title">
          {tokenInfo?.userName?.charAt(0)?.toUpperCase()}
        </span>

        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            {mutationLoading && Loading}

            <Controller
              as={TextField}
              fullWidth
              type={'password'}
              helperText={errors?.password?.message}
              error={errors?.password ? true : false}
              autoComplete="off"
              name="password"
              label="New Password*"
              control={control}
              inputRef={register({})}
            />
            <Controller
              as={TextField}
              fullWidth
              type={'password'}
              helperText={errors?.confirmPassword?.message}
              error={errors?.confirmPassword ? true : false}
              autoComplete="off"
              name="confirmPassword"
              label="Repeat New Password*"
              control={control}
              inputRef={register({})}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
              // onClick={() => history.push('/')}
            >
              Save
            </Button>
          </div>
        </form>
        <CopyRight />
      </AuthLayout>
    </>
  )
}
