import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import Snackbar from '@material-ui/core/Snackbar';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import { SocketContext } from 'containers/Socket/SocketContext';

export const NACheckWrapper = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [snckcolor, setColor] = useState('');
  const [message, setMessage] = useState('');
  const [notification, setNotification] = useState('');
  const [hotelid, setHotel] = useState('');
  // const [socket, setSocket] = useState(null);
  const [snackbarClosed, setSnackbarClosed] = useState(false);
  const [messages, setMessages] = useState('');
  const [disable, setDisable] = useState(true);
  const [ConfirmationDialog, setConfirmationDialog] = useState(false);
    const [messageColor, setMessageColor] = useState('black');

  const Hotel = JSON.parse(localStorage.getItem('Hotel'))?.ID;

  const { socket}: any =
  useContext(SocketContext)

  useEffect(() => {
    if (socket) {
      socket.on('notification', (notification) => {
        console.log('Received notification:', notification);

        if (notification.status === 'COMPLETED' && notification.hotel === Hotel) {
          setConfirmationDialog(true)
          setMessages('Auto Night Audit is COMPLETED. Please click OK!');
          setMessageColor('#4cd038'); // Bright green color
          setDisable(false)
          } else if (notification.status === 'PENDING' && notification.hotel === Hotel) {
          setConfirmationDialog(true)
          setMessageColor('#FF0000'); // Bright red color
          setMessages('Auto Night Audit is IN PROGRESS!');
          setDisable(true)
        } else if (notification.status === 'START' && notification.hotel === Hotel) {
          setOpen(true);
          setColor('white');
          setSnackbarClosed(false);
          let remainingTime = 30 * 60;
          const countdownInterval = setInterval(() => {
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            setMessage(`Auto Night Audit process will start in ${formattedMinutes} minutes time`);
            remainingTime--;
          
            if (remainingTime < 0) {
              clearInterval(countdownInterval);
              setMessage('Auto Night Audit process is starting...');
              // Perform the actual process here
            }
          }, 1000);

        }
        setNotification(notification.status);
      });

      return () => {
        if (socket) {
          socket.off('notification');
        }
      };
    }
  }, [socket, Hotel]);

  // Close the snackbar after the specified time duration (in milliseconds)
  // useEffect(() => {
  //   if (open && !snackbarClosed) {
  //     const timeout = setTimeout(() => {
  //       handleSnackbarClose();
  //     }, 60000); // 60 seconds in milliseconds

  //     return () => clearTimeout(timeout);
  //   }
  // }, [open, snackbarClosed]);

  // Modify the onClose event handler to set the snackbarClosed state to true
  const handleSnackbarClose = () => {
    setSnackbarClosed(true);
    setOpen(false);
  };

  console.log(socket, 'socket');

  return (
    <>
      {children}
      {notification === 'START' ? (
        <Snackbar
          className=""
          style={{ top: '125px', right: '30px' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={60000} // Set the autoHideDuration to 60 seconds
          onClose={() => setOpen(true)}
          ContentProps={{
            'aria-describedby': 'message-id',
            style: { backgroundColor: 'white', color: 'red' },
          }}
          message={<span id="message-id">{message}</span>}
          action={
            // Show IconButton with Close icon if notification is START
            <IconButton key="close" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
              <CloseIcon />
            </IconButton>
          }
        />
      ) : (
        <StandardDialog
          fullWidth={true}
          open={ConfirmationDialog}
          onClose={() => {
            setConfirmationDialog(true);
          }}
          sections={{
            header: {
              title: 'Night Audit',
            },
            body: () => <div style={{ color: messageColor, fontWeight: 'bold' }}>{messages}</div>, // Add style here
            footer: {
              actions: [
                {
                  displayText: 'OK',
                  props: {
                    onClick: () => {
                      setConfirmationDialog(false);
                    },
                    variant: 'contained',
                    color: 'primary',
                    disabled: disable === true,
                    //notification === 'PENDING',
                  },
                },
              ],
            },
          }}
        />
      )}
    </>
  );
};
