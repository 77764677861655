import { max } from 'date-fns'
import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  Reducer,
} from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { useHistory, useLocation, useParams } from 'react-router'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import {
  TextField,
  Typography,
  IconButton,
  Box,
  Checkbox,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PhoneInput from 'react-phone-input-2'
import { Create, AddBox } from '@material-ui/icons'
import {
  Country,
  Nationality,
  State,
  useCheckInMutation,
  usePreRegBookingInfoLazyQuery,
  useHotelDetailsLazyQuery,
  useKeyCardInsertMutation,
  GuestType,
  useReasonListingLazyQuery,
  useDepartmentListingLazyQuery,
  useTaxPolicyListingLazyQuery,
} from 'generated/graphql'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import PatternFormat from 'react-number-format'
import SnackBarMsg from 'components/SnackBar/SnackBarMsg'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'
import { KeyCardOption } from '../FrontDeskModule/Common'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import moment from 'moment'
import { PreRegAssignRoom } from 'containers/FrontDeskModule/DueIn/PreRegAssignRoom'


interface Props {
  open: boolean
  close: () => void
  BookingDetails
  QrData
  sockets
}

interface FormProps {
  FullName: string
  MobileNo: string
  Email: string
  Nationality: any
  NRIC: string
  Address?: string
  Country?: string
  State?: string
  City?: string
  PostCode?: string
  blob?: null | any
  IcPhoto?: any
  IcPhotoFrontFile?: any
  IcPhotoFront?: any
  IcPhotoBackFile?: any
  IcPhotoBack?: any
  Encoder?: string | null
  KeyCardValue?: string
  IsSignature: boolean
  ArrivalDate: string
  DepartureDate: string
  Occupation: string
  BirthDate: Date
  GuestType: string
  IsTourismTax: boolean
  VehicleNo: string
  Designation: string
  TTxReasonID: string
  RoomType: string
  RoomNo: string
  roomAssignDialog: boolean
  AvailableRoomList: any
  countVC: any
  countVD: any
  countVR: any
  roomSelected: any
  isTouched: boolean
  Expanded?: boolean | number
}

let Encoder: any = Object.values(
  JSON.parse(localStorage.getItem('Hotel'))?.Encoders || ''
).map(i => i)

export const QRScannerDialog = ({
  open,
  close,
  BookingDetails,
  QrData,
  sockets,
}: // mode,
  Props) => {
  let NationalityEnum: string[] = Object.values(Nationality).map(i => {
    return i.replace(/_/g, '')
  })
  let CountryEnum: any = Object.values(Country).map(i => {
    return i.replace(/_/g, '')
  })
  let StateEnum: any = Object.values(State).map(i => {
    return i.replace(/_/g, '')
  })

  const separatedData = QrData.split('/')
  const HotelID = separatedData[0]
  const BookingID = separatedData[1]
  const RegistrationID = separatedData[2]
  const { pathname } = useLocation()
  const [KeyCardInsert] = useKeyCardInsertMutation()
  const dateFormat = require('dateformat')

  const [
    PreRegBooking,
    { data: { PreRegBookingInfo } = { PreRegBookingInfo: [] } },
  ] = usePreRegBookingInfoLazyQuery({ fetchPolicy: 'no-cache' })

  const [
    HotelDetailsInfo,
    { data: { HotelDetails } = { HotelDetails: {} as any } },
  ] = useHotelDetailsLazyQuery()

  useEffect(() => {
    {
      if (HotelID && BookingID) {
        PreRegBooking({
          variables: {
            HotelID: HotelID,
            BookingID: BookingID,
            RegistrationID: RegistrationID.replace(/\r/g, ''),
            QR: 'true',
          },
        })

        HotelDetailsInfo({
          variables: {
            HotelID: HotelID,
          },
        })
      }
    }
  }, [QrData])

  const [openTerm, setOpenTerm] = useState(false)

  const handleOpenTerm = () => {
    setOpenTerm(true)
  }

  const handleCloseTerm = () => {
    setOpenTerm(false)
  }

  const [openPdpa, setOpenPdpa] = useState(false)

  const handleOpenPdpa = () => {
    setOpenPdpa(true)
  }

  const handleClosePdpa = () => {
    setOpenPdpa(false)
  }

  const [Acknowledge, SetAcknowledge] = useState(true)
  const [ScannedInfo, SetScannedInfo] = useState<any>()
  const [Signature, setSignature] = useState<any>()

  const initialState: FormProps = {
    FullName: ScannedInfo?.Guest?.Contact?.FullName || '',
    MobileNo: ScannedInfo?.Guest?.Contact?.MobileNo || '',
    Email: ScannedInfo?.Guest?.Contact?.Email || '',
    Nationality: ScannedInfo?.Guest?.Contact?.Nationality || '',
    NRIC: ScannedInfo?.Guest?.Contact?.NRIC || '',
    Address: ScannedInfo?.Guest?.Contact?.Address?.address || '',
    State: ScannedInfo?.Guest?.Contact?.Address?.state || '',
    City: ScannedInfo?.Guest?.Contact?.Address?.city || '',
    Country: ScannedInfo?.Guest?.Contact?.Address?.country || '',
    PostCode: ScannedInfo?.Guest?.Contact?.Address?.postCode || '',
    Occupation: ScannedInfo?.Guest?.Contact?.Designation || '',
    BirthDate: ScannedInfo?.Guest?.Contact?.BirthDate || '',
    GuestType: ScannedInfo?.Guest?.GuestType || 'Normal',
    IsTourismTax: ScannedInfo?.Guest?.IsTTx || false,
    IcPhotoFront: null,
    IcPhotoBack: null,
    IcPhotoFrontFile: null,
    IcPhotoBackFile: null,
    IsSignature: false,
    blob: null,
    ArrivalDate: null,
    DepartureDate: null,
    VehicleNo: ScannedInfo?.Guest?.Contact?.VehicleNo || '',
    Designation: ScannedInfo?.Guest?.Contact?.Designation || '',
    TTxReasonID: ScannedInfo?.Guest?.TTxReasonID || '',
    RoomType: ScannedInfo?.RoomType?.Code || '',
    RoomNo: ScannedInfo?.Room?.RoomNo || '',
    roomAssignDialog: false,
    AvailableRoomList: [],
    countVC: '',
    countVD: '',
    countVR: '',
    roomSelected: [],
    isTouched: false,
    Expanded: false,
    // IcPhoto: ScannedInfo?.
  }

  let GuestEnum: string[] = Object.values(GuestType).map(i => {
    return i.replace(/_/g, '')
  })

  useEffect(() => {
    if (RegistrationID && PreRegBookingInfo?.result?.length > 0) {
      const currReg = PreRegBookingInfo?.result?.map(x =>
        x?.Registrations?.find(y => y?.ID === RegistrationID.replace(/\r/g, ''))
      )?.filter(z => z !== undefined)[0]

      // filter by reg id
      SetScannedInfo(currReg || null)
      setSignature(
        PreRegBookingInfo?.Gallery?.find(
          x =>
            x.ID === currReg?.Signature
        )?.ImageURL || null
      )
      const ArrivalDate = moment(
        new Date(currReg?.ArrivalDate)
      ).format('DD MMM YYYY')

      const DepartureDate = moment(
        new Date(currReg?.DepartureDate)
      ).format('DD MMM YYYY')
      dispatch({
        type: 'ArrivalDate',
        payload: ArrivalDate,
      })
      dispatch({
        type: 'DepartureDate',
        payload: DepartureDate,
      })

      let frontpicid = currReg?.Guest?.IdPicture
      let backpicid = currReg?.Guest?.IcBackPicture
      const test = async () => {
        await dispatch({
          type: 'IcPhotoFront',
          payload:
            PreRegBookingInfo?.Gallery?.find(x => x.ID === frontpicid)
              ?.ImageURL || null,
        })
        await dispatch({
          type: 'IcPhotoBack',
          payload:
            PreRegBookingInfo?.Gallery?.find(x => x.ID === backpicid)
              ?.ImageURL || null,
        })
        return
      }
      test()
    }
  }, [PreRegBookingInfo, RegistrationID])

  const reducer: Reducer<FormProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  interface IAction<TType = string, TPayload = any> {
    type: TType
    payload: TPayload
  }

  const [
    DepartmentQuery,
    { data: { DepartmentListing } = { DepartmentListing: [] } },
  ] = useDepartmentListingLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [ReasonQuery, { data: { ReasonListing } = { ReasonListing: [] } }] =
    useReasonListingLazyQuery({
      fetchPolicy: 'no-cache',
    })

  const [TaxQuery, { data: { TaxPolicyListing } = { TaxPolicyListing: [] } }] =
    useTaxPolicyListingLazyQuery({
      fetchPolicy: 'no-cache',
    })


  useEffect(() => {
    {
      if (HotelID && BookingID) {        
        DepartmentQuery({
          variables: {
            HotelID: HotelID || JSON.parse(localStorage.getItem('Hotel'))?.ID,
          },
        })

        TaxQuery({
          variables: {
            HotelID: HotelID || JSON.parse(localStorage.getItem('Hotel'))?.ID,
          },
        })
      }
    }
  }, [QrData])

  let FODepartment = DepartmentListing?.find(i => i.Code === 'FO')?.ID  

  useEffect(() => {
    {
      if (HotelID && BookingID) {
     
          ReasonQuery({
            variables: {
              DepartmentID: FODepartment,
            },
          })
        }
    }
  }, [DepartmentListing?.length > 0])

  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()
  const [CheckIn, { loading: loadingUpdate }] = useCheckInMutation({
    onError: (error: any) => {
      if (
        error.message === 'GraphQL error: Room is not available' ||
        error.message === 'GraphQL error: Room has been occupied'
      ) {
        snackFunc(
          'The room assigned still Occupied. Please select other ready room ',
          false
        )
      } else if (
        error.message === 'GraphQL error: Room is not assigned' ||
        error.message === 'Please assign room before Check In.'
      ) {
        snackFunc('Room is not assigned ', false)
      } else if (
        error.message === 'Please assign Rate Code first before Check In.'
      ) {
        snackFunc('Please assign Rate Code first before Check In.', false)
      } else {
        snackFunc(
          'The room assigned is not ready,please select other ready room ',
          false
        )
      }
    },
    onCompleted: async (data: any) => {
      if (data.CheckIn) {
        console.log(data.CheckIn, 'dataaa')
        await KeyCardInsert({
          variables: {
            HotelID: HotelID || JSON.parse(localStorage.getItem('Hotel'))?.ID,
            AccountID: JSON.parse(localStorage.getItem('Hotel'))?.AccountID,
            UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
            input: {
              Operation: ScannedInfo?.KeyCardValue,
              RoomNo: ScannedInfo?.Room?.RoomNo || ScannedInfo?.RoomNo,
              RoomID: ScannedInfo?.RoomID,
              GuestName: ScannedInfo?.Guest?.Contact?.FullName,
              CheckInDate: formatDate(
                ScannedInfo?.ArrivalDate,
                HotelDetails?.KeycardVendor
              ),
              CheckOutDate: formatDate(
                ScannedInfo?.DepartureDate || BookingDetails?.DepartureDate,
                HotelDetails?.KeycardVendor
              ),
              encoder: ScannedInfo?.Encoder,
              status_flag: ScannedInfo?.KeyCardValue,
              RegistrationID:
                RegistrationID.replace(/\r/g, '') ||
                ScannedInfo?.ID.replace(/\r/g, ''),
            },
          },
        })

        snackFunc('Issue Successfully', false)
        if (pathname === "/frontdesk/duein") {
          window.location.reload();
        }
        close()
      } else {
        snackFunc('Update Fail', false)
        close()
      }
    },
  })

  let signCanvas: any = useRef(null)
  const clear = () => {
    signCanvas.current?.clear()
    dispatch({ type: 'IsSignature', payload: false })
    dispatch({ type: 'SignatureUrl', payload: null })
  }

  //start handle img
  const [targetfile, setTargefile] = useState(null)

  const addWatermark = async (imageUrl, watermarkText) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0)

        // Add the watermark text
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)' // Set watermark text color to black with 50% opacity
        ctx.font = '24px Arial' // Set the font for the watermark text
        ctx.textAlign = 'center' // Center the text horizontally
        ctx.textBaseline = 'middle' // Center the text vertically

        // Calculate the position to place the text in the center of the image
        const textX = canvas.width / 2
        const textY = canvas.height / 2

        // Draw the watermark text on the canvas
        ctx.fillText(watermarkText, textX, textY)

        // Get the watermarked image as a data URL
        const watermarkedImageUrl = canvas.toDataURL('image/jpeg')

        resolve(watermarkedImageUrl)
      }

      image.onerror = error => {
        reject(error)
      }

      image.src = imageUrl
    })
  }

  const handleUploadImg = async (e: any, type) => {
    setTargefile(e.target.files[0])

    if (e.target.files[0] && type === 'Front') {
      const reader = new FileReader()
      reader.onload = () => {
        const imageUrl = reader.result

        // Save the original image URL to the Redux store
        dispatch({
          type: 'IcPhotoFrontOriginal',
          payload: imageUrl,
        })

        // Call the function to add watermark "HotelX" to the image
        const watermarkedImage = addWatermark(imageUrl, 'HotelX')

        // Save the watermarked image URL to the Redux store (asynchronously)
        watermarkedImage.then(watermarkedImageUrl => {
          dispatch({
            type: 'IcPhotoFront',
            payload: watermarkedImageUrl,
          })
        })
      }
      reader.readAsDataURL(e.target.files[0])

      dispatch({
        type: 'IcPhotoFrontFile',
        payload: e?.target?.files[0],
      })
    } else if (e.target.files[0] && type !== 'Front') {
      const reader = new FileReader()
      reader.onload = () => {
        const imageUrl = reader.result

        dispatch({
          type: 'IcPhotoBack',
          payload: imageUrl,
        })

        const watermarkedImage = addWatermark(imageUrl, 'HotelX')

        // Save the watermarked image URL to the Redux store (asynchronously)
        watermarkedImage.then(watermarkedImageUrl => {
          dispatch({
            type: 'IcPhotoBack',
            payload: watermarkedImageUrl,
          })
        })
      }
      reader.readAsDataURL(e.target.files[0])
      dispatch({
        type: 'IcPhotoBackFile',
        payload: e.target.files[0],
      })
    }
  }
  const inputfile = useRef(null)

  const onSubmit = () => {
    CheckIn({
      variables: {
        IsPDPA: true,
        RegistrationIDs: [
          RegistrationID.replace(/\r/g, '') ||
          ScannedInfo?.ID.replace(/\r/g, ''),
        ],
        BookingID: BookingID || ScannedInfo?.BookingID,
        HotelID: HotelID,
        AttachmentInput: null,
        IdPicture: state.IcPhotoFrontFile,
        IdPictureBack: state.IcPhotoBackFile,
        IsTTx: ScannedInfo?.Guest?.IsTTx,
        TTxReasonID: ScannedInfo?.Guest?.TTxReasonID,
        ContactInput: {
          ID: ScannedInfo?.Guest?.ContactID || null,
          GuestID: ScannedInfo?.Guest?.ID || null,
          FullName: ScannedInfo?.Guest?.Contact?.FullName,
          MobileNo: ScannedInfo?.Guest?.Contact?.MobileNo,
          Email: ScannedInfo?.Guest?.Contact?.Email || state?.Email,
          NRIC: ScannedInfo?.Guest?.Contact?.NRIC,
          Nationality:
            ScannedInfo?.Guest?.Contact?.Nationality?.replace(/ /g, '_') || '',
          VehicleNo: ScannedInfo?.Guest?.VehicleNo,
          Designation: ScannedInfo?.Guest?.Designation,
          BirthDate: ScannedInfo?.Guest?.Contact?.BirthDate,
          Address: {
            address: ScannedInfo?.Guest?.Contact?.Address?.address || '',
            country: ScannedInfo?.Guest?.Contact?.Address?.country || '',
            city: ScannedInfo?.Guest?.Contact?.Address?.city || '',
            state: ScannedInfo?.Guest?.Contact?.Address?.state || '',
            postCode: ScannedInfo?.Guest?.Contact?.Address?.postCode || '',
          },
        },
      },
    })
  }

  // Utility function to format date based on keycard vendor
  const formatDate = (date, vendor) => {
    const format =
      vendor === 'Salto' || vendor === 'FDL'
        ? 'yyyy/mm/dd HH:MM'
        : 'dd/mm/yyyy HH:MM'
    return dateFormat(date, format)
  }

  const [mode, setMode] = useState('display')
  const handleEditClick = () => {
    setMode('edit')
  }

  const [Display, setDisplay] = useState('')

  console.log( ReasonListing?.find(
    f => f.ID === ScannedInfo?.Guest?.TTxReasonID
  )?.Code, ReasonListing,"hello");
  

  return loadingUpdate ? (
    <Loading />
  ) : (
    <>
      {Display === 'IssueKey' ? (
        <>
          <CommonDialog
            fullWidth={true}
            open={open}
            onClose={close}
            sections={{
              header: {
                dynamic: (
                  <div className="">
                    <div className="dialog-dynamic-content">
                      <div
                        className="session"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div className="title flex-space">Key Card</div>
                      </div>
                    </div>
                  </div>
                ),
              },
              body: () => (
                <>
                  <TextField
                    fullWidth
                    select
                    label="Encoders"
                    value={ScannedInfo?.Encoder || '-'}
                    onChange={e => {
                      SetScannedInfo({
                        ...ScannedInfo,
                        Encoder: e.target.value,
                      })
                    }}
                  >
                    {Encoder?.map((el, index) => (
                      <MenuItem value={el} key={index}>
                        {el}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormControl component="fieldset">
                    <FormLabel component="legend">Options</FormLabel>
                    <RadioGroup
                      value={ScannedInfo?.KeyCardValue || '-'}
                      onChange={e => {
                        SetScannedInfo({
                          ...ScannedInfo,
                          KeyCardValue: e.target.value,
                        })
                      }}
                    >
                      {KeyCardOption.map(x => (
                        <FormControlLabel
                          value={x.value}
                          control={<Radio color="primary" />}
                          label={x.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Cancel',
                    props: {
                      onClick: () => {
                        setDisplay('display')
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText: 'Submit',
                    props: {
                      onClick: () => {
                        onSubmit()
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                ],
              },
            }}
          />
        </>
      ) : (
        <>
          <CommonDialog
            fullWidth={true}
            open={open}
            onClose={close}
            sections={{
              header: {
                dynamic: (
                  <div className="">
                    <div className="dialog-dynamic-content">
                      <div
                        className="session"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div className="title flex-space">
                          Registration Info
                        </div>
                        <br></br>
                        <div className="smTitle text-align-right">
                          {state?.ArrivalDate + ` - ` + state?.DepartureDate}
                        </div>
                        <span className="desc"></span>
                      </div>
                      <div></div>
                    </div>
                    <div className="infoline desc  display-flex">
                      <span className="p-4 desc flex icon-text">
                        {PreRegBookingInfo?.BookingNo}
                      </span>

                      <div>QR</div>
                    </div>
                  </div>
                ),
              },
              body: () => (
                <>
                  {mode === 'display' ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <span
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            <span style={{ display: 'flex', color: 'gray', paddingBottom: '3px' }}>
                              Room Type
                            </span>
                            <span style={{ display: 'flex' }}>
                              {ScannedInfo?.RoomType?.Code || ''}
                            </span>
                          </span>
                        </div>
                        {ScannedInfo?.RoomID !== null ? (
                          <span
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            <span style={{ display: 'flex', color: 'gray', paddingBottom: '3px' }}>
                              Room No
                            </span>
                            <span style={{ display: 'flex' }}>
                              {ScannedInfo?.RoomNo || ScannedInfo?.Room?.RoomNo || ''}
                            </span>
                          </span>

                        ) : (
                          <>
                            <span>
                              <Button
                                onClick={() => dispatch({ type: 'roomAssignDialog', payload: true })}
                              >
                                <Typography style={{
                                  textDecoration: 'underline',
                                  color: 'black',
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                }}>
                                  Assign Room
                                </Typography>
                              </Button>
                            </span>
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <TextField
                          // className='right'
                          label="Guest Name"
                          fullWidth
                          // style={{ width: '50%' }}
                          value={ScannedInfo?.Guest?.Contact?.FullName || ''}
                          margin="dense"
                          name="Fullname"
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          InputLabelProps={{
                            style: { color: 'grey' },
                          }}
                        />
                        <IconButton edge="end" onClick={handleEditClick}>
                          <Create />
                        </IconButton>
                      </div>

                      <TextField
                        label="Phone No. (Optional)"
                        name="MobileNo"
                        fullWidth
                        style={{ width: '100%' }}
                        value={
                          ScannedInfo?.Guest?.Contact?.MobileNo
                            ? ScannedInfo?.Guest?.Contact?.MobileNo
                            : ' '
                        }
                        inputProps={{
                          required: false,
                        }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                          style: { fontSize: '12px' },
                        }}
                        InputLabelProps={{
                          style: { color: 'grey' },
                        }}
                      />

                      <TextField
                        // className='left'
                        label="Email"
                        fullWidth
                        // style={{ width: '50%' }}
                        value={ScannedInfo?.Guest?.Contact?.Email || ''}
                        margin="dense"
                        name="Email"
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                          style: { fontSize: '12px' },
                        }}
                        InputLabelProps={{
                          style: { color: 'grey' },
                        }}
                      />
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          label="Nationality"
                          name="Nationality"
                          required
                          value={ScannedInfo?.Guest?.Contact?.Nationality || ''}
                          fullWidth
                          style={{ width: '50%' }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          InputLabelProps={{
                            style: { color: 'grey' },
                          }}
                        />

                        <TextField
                          label="NRIC"
                          name="NRIC"
                          fullWidth
                          style={{ width: '50%' }}
                          value={ScannedInfo?.Guest?.Contact?.NRIC || ''}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          InputLabelProps={{
                            style: { color: 'grey' },
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          label="Address"
                          fullWidth
                          margin="dense"
                          name="Address"
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.address
                              ? ScannedInfo?.Guest?.Contact?.Address?.address
                              : ' '
                          }
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          InputLabelProps={{
                            style: { color: 'grey' },
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          style={{ width: '50%' }}
                          autoComplete="off"
                          label="Country"
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.country || ''
                          }
                          required
                          fullWidth
                          margin="normal"
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'grey' },
                          }}
                        />
                        <TextField
                          style={{ width: '50%' }}
                          autoComplete="off"
                          label="State"
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.state || ''
                          }
                          required
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'grey' },
                          }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          label="City"
                          fullWidth
                          margin="normal"
                          style={{ width: '50%' }}
                          autoComplete="off"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'grey' },
                          }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.city || ''
                          }
                        />
                        <TextField
                          label="Postcode"
                          fullWidth
                          margin="normal"
                          style={{ width: '50%' }}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'grey' },
                          }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          name="postCode"
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.postCode || ''
                          }
                        />
                      </div>

                      <TextField
                        label="Vehicle No. / Vehicle Type"
                        fullWidth
                        name="vehicleNo"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'grey' },
                        }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                          style: { fontSize: '12px' },
                        }}
                        value={ScannedInfo?.Guest?.VehicleNo || ''}
                      />
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          value={ScannedInfo?.Guest?.Contact?.Designation || ''}
                          label="Occupation"
                          style={{ width: '50%' }}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'grey' },
                          }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                        />

                        <TextField //MUI V4 KeyboardDatePicker doesn't support typed dd MMM yyyy format. Using dd mm yyyy for now
                          style={{ width: '50%' }}
                          margin="normal"
                          label="Birth Date"
                          name="BirthDate"
                          value={
                            dateFormat(
                              ScannedInfo?.Guest?.Contact?.BirthDate,
                              'dd/mm/yyyy'
                            ) || ''
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'grey' },
                          }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                        />
                      </div>

                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          name="GuestType"
                          label="Guest Type"
                          style={{ width: '50%' }}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'grey' },
                          }}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: { fontSize: '12px' },
                          }}
                          value={ScannedInfo?.Guest?.GuestType || 'Normal'}
                          required
                        ></TextField>
                        <FormControlLabel
                          className="right"
                          label="Tourism Tax"
                          style={{ width: '50%' }}
                          control={
                            <Checkbox
                              checked={ScannedInfo?.Guest?.IsTTx || false}
                              disableRipple={true}
                              name="IsTTx"
                              color="primary"
                              size="small"
                            />
                          }
                        />
                      </div>
                      <TextField
                        fullWidth
                        label={'Tax Exempted Reason Code'}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'grey' },
                        }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                          style: { fontSize: '12px' },
                        }}
                        value={
                          ReasonListing?.find(
                            f => f.ID === ScannedInfo?.Guest?.TTxReasonID
                          )?.Code || ''
                        }
                        required
                      ></TextField>

                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ fontSize: '12px' }}
                      >
                        Attach IC/Passport
                      </Typography>

                      <br />
                      <br />

                      <Box width="100%" display="flex" mb="10px">
                        <Box width="50%" mb="16px">
                          <Box
                            display="flex"
                            width="100%"
                            height="100px"
                            style={{
                              borderRadius: '5px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            component="label"
                          >
                            {state?.IcPhotoFront ? (
                              <Box
                                boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0/ 15% ), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                                style={{
                                  borderRadius: '5px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={state?.IcPhotoFront}
                                  alt="empty"
                                  loading="lazy"
                                  height="95px"
                                  width="100%"
                                  style={{ borderRadius: '5px' }}
                                />
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                component="span"
                                width="130px"
                                height="100px"
                                style={{
                                  borderRadius: '5px',
                                  border: '1px dashed grey',
                                }}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <AddBox color="primary" />
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box width="50%">
                          <Box
                            display="flex"
                            width="100%"
                            height="100px"
                            // ml="13%"
                            style={{
                              borderRadius: '5px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            component="label"
                          >
                            {state?.IcPhotoBack ? (
                              <Box
                                boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                                style={{
                                  borderRadius: '5px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={state?.IcPhotoBack}
                                  alt="empty"
                                  loading="lazy"
                                  height="95px"
                                  width="100%"
                                  style={{ borderRadius: '5px' }}
                                />
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                component="span"
                                width="130px"
                                height="100px"
                                style={{
                                  borderRadius: '5px',
                                  border: '1px dashed grey',
                                }}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <AddBox color="primary" />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <br />
                      <br />
                      <Checkbox
                        checked={Acknowledge}
                        onChange={e => SetAcknowledge(e.target.checked)}
                        name="Complimentary"
                        color="primary"
                      />
                      <span style={{ fontSize: '12px' }}>
                        I acknowledge and accept the hotels
                      </span>
                      <span
                        style={{
                          color: '#FFA500',
                          textDecoration: 'underline',
                          fontSize: '12px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleOpenTerm()
                        }}
                      >
                        {''}
                        Terms and Conditions
                      </span>
                      <br />
                      <br />
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ fontSize: '12px' }}
                      >
                        Signature
                      </Typography>

                      <CardContents>
                        {''}
                        <div className="m-b-8 flex">
                          <div
                            style={{
                              position: 'absolute',
                              right: '24px',
                            }}
                            className="click-text color-primary-orange m-t-4"
                            onClick={clear}
                          >
                          </div>
                          <Box
                            display="flex"
                            component="span"
                            width="100%"
                            height="100px"
                            style={{
                              borderRadius: '3px',
                              border: '1px dashed grey',
                            }}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img
                              src={Signature}
                              alt="empty"
                              loading="lazy"
                              height="100px"
                              width="100%"
                              style={{ borderRadius: '5px' }}
                            />
                          </Box>
                        </div>
                      </CardContents>
                    </>
                  ) : mode === 'edit' ? (
                    <>
                      <TextField
                        label="Guest Name"
                        fullWidth
                        value={ScannedInfo?.Guest?.Contact?.FullName}
                        margin="dense"
                        name="Fullname"
                        onChange={e => {
                          SetScannedInfo({
                            ...ScannedInfo,
                            Guest: {
                              ...ScannedInfo?.Guest,
                              Contact: {
                                ...ScannedInfo?.Guest?.Contact,
                                FullName: e.target.value,
                              },
                            },
                          })
                        }}
                      />
                      <PhoneInput
                        containerClass="contact-input-new"
                        inputProps={{
                          name: 'MobileNo',
                          required: false,
                        }}
                        specialLabel="Phone No. (Optional)"
                        country={'my'} //Country prefix
                        value={ScannedInfo?.Guest?.Contact?.MobileNo}
                        onChange={e => {
                          SetScannedInfo({
                            ...ScannedInfo,
                            Guest: {
                              ...ScannedInfo?.Guest,
                              Contact: {
                                ...ScannedInfo?.Guest?.Contact,
                                MobileNo: e,
                              },
                            },
                          })
                        }}
                      />
                      <TextField
                        label="Email"
                        fullWidth
                        value={ScannedInfo?.Guest?.Contact?.Email || ''}
                        margin="dense"
                        name="Email"
                        onChange={e => {
                          SetScannedInfo({
                            ...ScannedInfo,
                            Guest: {
                              ...ScannedInfo?.Guest,
                              Contact: {
                                ...ScannedInfo?.Guest?.Contact,
                                Email: e.target.value,
                              },
                            },
                          })
                        }}
                      />
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <Autocomplete
                          fullWidth
                          id="nationality"
                          options={NationalityEnum}
                          value={ScannedInfo?.Guest?.Contact?.Nationality}
                          getOptionLabel={option => option || ''}
                          onChange={(_, data) =>
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                Contact: {
                                  ...ScannedInfo?.Guest?.Contact,
                                  Nationality: data,
                                },
                              },
                            })
                          }
                          renderInput={params => (
                            <TextField
                              required
                              {...params}
                              label="Nationality"
                              variant="standard"
                            />
                          )}
                        />
                        <>
                          {ScannedInfo?.Guest?.Contact?.Nationality ===
                            'Malaysian' ? (
                            <PatternFormat
                              required
                              label="IC"
                              displayType="input"
                              type="text"
                              customInput={TextField}
                              fullWidth
                              value={ScannedInfo?.Guest?.Contact?.NRIC}
                              name="NRIC"
                              onChange={e => {
                                SetScannedInfo({
                                  ...ScannedInfo,
                                  Guest: {
                                    ...ScannedInfo?.Guest,
                                    Contact: {
                                      ...ScannedInfo?.Guest?.Contact,
                                      NRIC: e,
                                    },
                                  },
                                })
                              }}
                              format="######-##-####"
                              mask="_"
                            />
                          ) : (
                            <TextField
                              required
                              label="Passport No"
                              fullWidth
                              InputProps={{}}
                              value={ScannedInfo?.Guest?.Contact?.NRIC}
                              name="NRIC"
                              onChange={e => {
                                SetScannedInfo({
                                  ...ScannedInfo,
                                  Guest: {
                                    ...ScannedInfo?.Guest,
                                    Contact: {
                                      ...ScannedInfo?.Guest?.Contact,
                                      NRIC: e,
                                    },
                                  },
                                })
                              }}
                            />
                          )}
                        </>
                      </div>
                      <div>
                        <TextField
                          required
                          label="Address"
                          fullWidth
                          type="text"
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.address || ''
                          }
                          name="Address"
                          onChange={e => {
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                Contact: {
                                  ...ScannedInfo?.Guest?.Contact,
                                  Address: {
                                    ...ScannedInfo?.Guest?.Contact?.Address,
                                    address: e.target.value,
                                  },
                                },
                              },
                            })
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <Autocomplete
                          style={{ width: '50%' }}
                          options={CountryEnum}
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.country || ''
                          }
                          getOptionLabel={(option: any) => option || ''}
                          autoComplete={false}
                          renderInput={params => (
                            <TextField
                              autoComplete="off"
                              label="Country"
                              required
                              style={{ width: '100%' }}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...params}
                            />
                          )}
                          onChange={(_, data) => {
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                Contact: {
                                  ...ScannedInfo?.Guest?.Contact,
                                  Address: {
                                    ...ScannedInfo?.Guest?.Contact?.Address,
                                    country: data,
                                  },
                                },
                              },
                            })
                          }}
                        />
                        <Autocomplete
                          style={{ width: '50%' }}
                          options={StateEnum}
                          value={
                            ScannedInfo?.Guest?.Contact?.Address?.state || ''
                          }
                          getOptionLabel={(option: any) => option || ''}
                          autoComplete={false}
                          onChange={(_, data) => {
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                Contact: {
                                  ...ScannedInfo?.Guest?.Contact,
                                  Address: {
                                    ...ScannedInfo?.Guest?.Contact?.Address,
                                    state: data,
                                  },
                                },
                              },
                            })
                          }}
                          renderInput={params => (
                            <TextField
                              autoComplete="off"
                              label="State"
                              required
                              style={{ width: '100%' }}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...params}
                            />
                          )}
                        />
                      </div>

                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          label="City"
                          fullWidth
                          className="left"
                          style={{ width: '50%' }}
                          margin="normal"
                          value={ScannedInfo?.Guest?.Contact?.Address?.city}
                          onChange={e => {
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                Contact: {
                                  ...ScannedInfo?.Guest?.Contact,
                                  Address: {
                                    ...ScannedInfo?.Guest?.Contact?.Address,
                                    city: e.target.value,
                                  },
                                },
                              },
                            })
                          }}
                        />
                        <TextField
                          label="Postcode"
                          fullWidth
                          style={{ width: '50%' }}
                          margin="normal"
                          name="postCode"
                          value={ScannedInfo?.Guest?.Contact?.Address?.postCode}
                          onChange={e => {
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                Contact: {
                                  ...ScannedInfo?.Guest?.Contact,
                                  Address: {
                                    ...ScannedInfo?.Guest?.Contact?.Address,
                                    postCode: e.target.value,
                                  },
                                },
                              },
                            })
                          }}
                        />
                      </div>

                      <TextField
                        label="Vehicle No. / Vehicle Type"
                        fullWidth
                        name="vehicleNo"
                        margin="normal"
                        value={ScannedInfo?.Guest?.VehicleNo}
                        onChange={e => {
                          SetScannedInfo({
                            ...ScannedInfo,
                            Guest: {
                              ...ScannedInfo?.Guest,
                              VehicleNo: e.target.value,
                            },
                          })
                        }}
                      />
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          value={ScannedInfo?.Guest?.Contact?.Designation}
                          onChange={e => {
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                Contact: {
                                  ...ScannedInfo?.Guest?.Contact,
                                  Designation: e.target.value,
                                },
                              },
                            })
                          }}
                          label="Occupation"
                          style={{ width: '50%' }}
                          margin="normal"
                        />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker //MUI V4 KeyboardDatePicker doesn't support typed dd MMM yyyy format. Using dd mm yyyy for now
                            className="right"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Birth Date"
                            name="BirthDate"
                            openTo={'year'}
                            views={['year', 'month', 'date']}
                            value={ScannedInfo?.Guest?.Contact?.BirthDate}
                            onChange={date => {
                              SetScannedInfo({
                                ...ScannedInfo,
                                Guest: {
                                  ...ScannedInfo?.Guest,
                                  Contact: {
                                    ...ScannedInfo?.Guest?.Contact,
                                    BirthDate: date,
                                  },
                                },
                              })
                            }}
                            style={{ width: '50%' }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            allowKeyboardControl
                            clearable
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          name="GuestType"
                          className="left"
                          label="Guest Type"
                          style={{ width: '50%' }}
                          margin="normal"
                          value={ScannedInfo?.Guest?.GuestType || 'Normal'}
                          select
                          required
                          onChange={e => {
                            SetScannedInfo({
                              ...ScannedInfo,
                              Guest: {
                                ...ScannedInfo?.Guest,
                                GuestType: e.target.value,
                              },
                            })
                          }}
                        >
                          {GuestEnum.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <FormControlLabel
                          className="right"
                          label="Tourism Tax"
                          style={{
                            marginLeft: '8px',
                          }}
                          control={
                            <Checkbox
                              checked={ScannedInfo?.Guest?.IsTTx}
                              disabled={
                                ScannedInfo?.RegistrationStatus === 'Booked' ||
                                  ScannedInfo?.RegistrationStatus === 'Inhouse' ||
                                  state?.Nationality?.toLowerCase() !==
                                  'malaysian'
                                  ? false
                                  : true
                              }
                              onChange={e => {
                                SetScannedInfo({
                                  ...ScannedInfo,
                                  Guest: {
                                    ...ScannedInfo?.Guest,
                                    IsTTx: e.target.checked,
                                  },
                                })
                              }}
                              name="IsTTx"
                              color="primary"
                              size="small"
                            />
                          }
                        />
                      </div>
                      <TextField
                        fullWidth
                        select
                        label={'Tax Exempted Reason Code'}
                        margin="normal"
                        value={ScannedInfo?.Guest?.TTxReasonID}
                        required
                        onChange={e => {
                          SetScannedInfo({
                            ...ScannedInfo,
                            Guest: {
                              ...ScannedInfo?.Guest,
                              TTxReasonID: e.target.value,
                            },
                          })
                        }}
                      >
                         { console.log(ReasonListing?.filter(x => x?.Code !== null),"zzu") }
                        {ReasonListing?.filter(x => x?.Code !== null)?.map(r => (                          
                          <MenuItem key={r.Code} value={r.ID}>
                            {r.Code}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ fontSize: '12px' }}
                      >
                        Attach IC/Passport
                      </Typography>
                      <br />
                      <br />

                      <Box width="100%" display="flex" mb="10px">
                        <Box width="50%" mb="16px">
                          <Box
                            display="flex"
                            width="100%"
                            height="100px"
                            // ml="13%"
                            style={{
                              borderRadius: '5px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            component="label"
                          >
                            {state?.IcPhotoFront ? (
                              <Box
                                boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                                style={{
                                  borderRadius: '5px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={state?.IcPhotoFront}
                                  alt="empty"
                                  loading="lazy"
                                  height="100px"
                                  width="100%"
                                  style={{ borderRadius: '5px' }}
                                />
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                component="span"
                                width="130px"
                                height="100px"
                                style={{
                                  borderRadius: '5px',
                                  border: '1px dashed grey',
                                }}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <AddBox color="primary" />
                              </Box>
                            )}
                            <input
                              hidden
                              accept="image/*"
                              id="contained-file"
                              ref={inputfile}
                              type="file"
                              onChange={e => {
                                handleUploadImg(e, 'Front')
                              }}
                            />
                          </Box>
                        </Box>
                        <Box width="50%" mb="16px">
                          <Box
                            display="flex"
                            width="100%"
                            height="100px"
                            // ml="15%"

                            style={{
                              borderRadius: '5px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            component="label"
                          >
                            {state?.IcPhotoBack ? (
                              <Box
                                boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                                style={{
                                  borderRadius: '5px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={state?.IcPhotoBack}
                                  alt="empty"
                                  loading="lazy"
                                  height="100px"
                                  width="100%"
                                  style={{ borderRadius: '5px' }}
                                />
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                width="130px"
                                height="100px"
                                style={{
                                  borderRadius: '5px',
                                  border: '1px dashed grey',
                                }}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <AddBox color="primary" />
                              </Box>
                            )}
                            <input
                              hidden
                              accept="image/*"
                              id="contained-file"
                              ref={inputfile}
                              type="file"
                              onChange={e => {
                                handleUploadImg(e, 'Back')
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <br />
                      <br />

                      <Checkbox
                        checked={Acknowledge}
                        onChange={e => SetAcknowledge(e.target.checked)}
                        name="Complimentary"
                        color="primary"
                      />
                      <span style={{ fontSize: '12px' }}>
                        I acknowledge and accept the hotels
                      </span>
                      <span
                        style={{
                          color: '#FFA500',
                          textDecoration: 'underline',
                          fontSize: '12px',
                        }}
                      >
                        {''}
                        Terms and Conditions
                      </span>
                      <br />
                      <br />
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ fontSize: '12px' }}
                      >
                        Signature
                      </Typography>
                      <CardContents>
                        {''}
                        <div className="m-b-8 flex">
                          <div
                            style={{
                              position: 'absolute',
                              right: '24px',
                            }}
                            className="click-text color-primary-orange m-t-4"
                            onClick={clear}
                          >
                          </div>
                          <Box
                            display="flex"
                            component="span"
                            width="100%"
                            height="100px"
                            style={{
                              borderRadius: '3px',
                              border: '1px dashed grey',
                            }}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <img
                              src={Signature}
                              alt="empty"
                              loading="lazy"
                              height="100px"
                              width="100%"
                              style={{ borderRadius: '5px' }}
                            />
                          </Box>
                        </div>
                      </CardContents>
                    </>
                  ) : null}
                </>
              ),
              footer: {
                actions: [
                  {
                    displayText: 'Close',
                    props: {
                      onClick: () => {
                        close()
                        if (pathname === "/frontdesk/duein" && ScannedInfo?.RoomNo) {
                          window.location.reload();
                        }
                      },
                      variant: 'contained',
                      color: 'primary',
                    },
                  },
                  {
                    displayText:
                      mode === 'edit'
                        ? 'Save'
                        : HotelDetails?.KeycardVendor !== null
                          ? 'Issue Key'
                          : 'Submit',
                    props: {
                      onClick: () => {
                        if (
                          HotelDetails?.KeycardVendor === null &&
                          mode === 'edit'
                        ) {
                          setMode('display')
                        } else if (
                          HotelDetails?.KeycardVendor === null &&
                          mode !== 'edit'
                        ) {
                          onSubmit()
                        } else if (
                          HotelDetails?.KeycardVendor !== null &&
                          mode === 'edit'
                        ) {
                          setMode('display')
                        } else {
                          setDisplay('IssueKey')
                        }
                      },
                      variant: 'contained',
                      color: 'primary',
                      disabled:
                        (ScannedInfo?.Room === undefined && mode !== 'edit') ||
                        (Acknowledge === false && mode !== 'edit') ||
                        ScannedInfo?.Guest?.Contact?.Address?.address === '' ||
                        !ScannedInfo?.Guest?.Contact?.Address?.address ||
                        !ScannedInfo?.Guest?.Contact?.Address?.state ||
                        ScannedInfo?.Guest?.Contact?.Address?.country ===
                        ('' || null || undefined) ||
                        ScannedInfo?.Guest?.Contact?.NRIC === '',
                    },
                  },
                ],
              },
            }}
          />
        </>
      )}
      <Dialog open={openTerm} onClose={handleCloseTerm}>
        <DialogTitle
          style={{
            fontWeight: 'bold',
            color: '#f5ab35',
            backgroundColor: '#fff5e7',
          }}
        >
          Terms and Condition
        </DialogTitle>
        <DialogContent>
          {HotelDetails?.RegCardTnC ? (
            <div
              dangerouslySetInnerHTML={{ __html: HotelDetails.RegCardTnC }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseTerm}
            style={{
              backgroundColor: '#f5ab35',
              '&:hover': {
                backgroundColor: '#f5ab35', // Keep the same background color on hover
              },
              '&:active': {
                backgroundColor: '#f5ab35', // Keep the same background color when clicked
              },
            }}
          >
            {' '}
            <Typography style={{ fontWeight: 'bold', color: '#ffffff' }}>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPdpa} onClose={handleClosePdpa}>
        <DialogTitle
          style={{
            fontWeight: 'bold',
            color: '#f5ab35',
            backgroundColor: '#fff5e7',
          }}
        >
          PDPA
        </DialogTitle>
        <DialogContent>
          {HotelDetails?.PDPA ? (
            <div dangerouslySetInnerHTML={{ __html: HotelDetails.PDPA }} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePdpa}
            style={{
              backgroundColor: '#f5ab35',
              '&:hover': {
                backgroundColor: '#f5ab35', // Keep the same background color on hover
              },
              '&:active': {
                backgroundColor: '#f5ab35', // Keep the same background color when clicked
              },
            }}
          >
            <Typography style={{ fontWeight: 'bold', color: '#ffffff' }}>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
      <PreRegAssignRoom
        state={state}
        dispatch={dispatch}
        ScannedInfo={ScannedInfo}
        setScannedInfo={SetScannedInfo}
        HotelDetails={HotelDetails}
        qrUrlData={QrData}
      />
    </>
  )
}
