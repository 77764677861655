import { AttachmentsListing } from 'containers/AttachmentsModule/AttachmentsListing'
import React, { lazy } from 'react'
import { FrontDeskType } from 'containers/FrontDeskModule/Common'
import { RateAdjustmentAuditLog } from './BillSchedule/RateAdjustmentAuditLog'
const Booking = lazy(() =>
  import('./Booking').then(module => ({
    default: module.Booking,
  }))
)
const BookingSubmenu = lazy(() =>
  import('./BookingSubmenu').then(module => ({
    default: module.BookingSubmenu,
  }))
)
const RoomTypeList = lazy(() =>
  import('./RoomAssignment/RoomTypeList').then(module => ({
    default: module.RoomTypeList,
  }))
)
const RoomUpgradeList = lazy(() =>
  import('./RoomAssignment/RoomUpgradeList').then(module => ({
    default: module.RoomUpgradeList,
  }))
)
const RoomAssignment = lazy(() =>
  import('./RoomAssignment/RoomAssignment').then(module => ({
    default: module.RoomAssignment,
  }))
)
const RoomingList = lazy(() =>
  import('./RoomingList/RoomingList').then(module => ({
    default: module.RoomingList,
  }))
)
const IncidentalChargesBooking = lazy(() =>
  import('./IncidentalCharges/IncidentalCharges').then(module => ({
    default: module.IncidentalChargesBooking,
  }))
)
const IncidentalChargesRoutes = lazy(() =>
  import('./IncidentalCharges/IncidentalChargesList').then(module => ({
    default: module.IncidentalChargesRoutes,
  }))
)
const IncidentalCharges = lazy(() =>
  import('./IncidentalCharges/IncidentalChargesBooking').then(module => ({
    default: module.IncidentalCharges,
  }))
)

const GuestList = lazy(() =>
  import('./RoomingList/GuestList').then(module => ({
    default: module.GuestList,
  }))
)
const GuestForm = lazy(() =>
  import('./RoomingList/GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)
const BillingSchedule = lazy(() =>
  import('./BillSchedule/BillingSchedule').then(module => ({
    default: module.BillingSchedule,
  }))
)
const HouseLimit = lazy(() =>
  import('./HouseLimit').then(module => ({
    default: module.HouseLimit,
  }))
)
const BookingForm = lazy(() =>
  import('./BookingForm').then(module => ({
    default: module.BookingForm,
  }))
)
const EditBooking = lazy(() =>
  import('./EditBooking').then(module => ({
    default: module.EditBooking,
  }))
)
const EditRoomType = lazy(() =>
  import('./EditRoomType').then(module => ({
    default: module.EditRoomType,
  }))
)
// const GuestList = lazy(() =>
//   import('./GuestList').then(module => ({
//     default: module.GuestList,
//   }))
// )
const BookingConfirmation = lazy(() =>
  import('./BookingConfirmation').then(module => ({
    default: module.BookingConfirmation,
  }))
)
const AdvancePaymentList = lazy(() =>
  import('./AdvancePaymentList').then(module => ({
    default: module.AdvancePaymentList,
  }))
)
const AdvancePaymentDoc = lazy(() =>
  import('./AdvancePaymentDoc').then(module => ({
    default: module.AdvancePaymentDoc,
  }))
)
const AdvancePaymentDocument = lazy(() =>
  import('./AdvancePaymentDocument').then(module => ({
    default: module.AdvancePaymentDocument,
  }))
)
const RoomCancelReinstate = lazy(() =>
  import('./RoomCancelReinstate').then(module => ({
    default: module.RoomCancelReinstate,
  }))
)
const BookingAuditLog = lazy(() =>
  import('./BookingAuditLog').then(module => ({
    default: module.BookingAuditLog,
  }))
)
export const editPath = '/booking/edit'
export const advancePaymentPath = '/booking/submenu/advancepayment'
export const confirmationPath = '/booking/confirmation'
export const attachmentsPath = '/booking/attachments'
export const advancePaymentDocPath = '/booking/advancepayment/folio'
export const bookingSubmenuPath = '/booking/submenu'
export const bookingEditInfoPath = '/booking/submenu/edit'
export const cloneBookingPath = '/booking/clone/add'
export const roomAssignmentPath = '/booking/submenu/roomassignment'
export const roomAssignmentFormPath = '/booking/submenu/roomassignment/assign'
export const roomingListPath = '/booking/submenu/roominglist'
export const bookingICListPath = '/booking/submenu/incidentalcharges'
export const incidentalcharges = '/booking/incidentalcharges'
export const bookingIChargesUrl = `/booking/submenu/bookingincidentalcharges`
export const roomingGuestListPath = '/booking/submenu/roominglist/guest'
export const roomingGuestAddPath = '/booking/submenu/roominglist/guest/add'
export const roomingGuestEditPath = '/booking/submenu/roominglist/guest/edit'
export const billingSchedulePath = '/booking/submenu/billingschedule'
export const rateAdjustmentAuditLogPath = '/booking/submenu/billingschedule/auditlog'
export const houseLimitPath = '/booking/submenu/houselimit'
export const roomCancelReinstatePath = '/booking/submenu/roomcancelreinstate'
export const roomUpgradelistPath = '/booking/submenu/roomupgrade'
export const bookingAuditLogPath = '/booking/submenu/auditlog'

const bookingRoutes = [
  {
    props: { exact: true, path: '/booking' },
    component: <Booking />,
  },
  {
    props: { exact: true, path: bookingSubmenuPath },
    component: <BookingSubmenu />,
  },
  {
    props: { exact: true, path: roomingListPath },
    component: <RoomingList />,
  },
  {
    props: { exact: true, path: bookingICListPath },
    component: <IncidentalChargesBooking />,
  },
  {
    props: { exact: true, path: incidentalcharges },
    component: <IncidentalChargesRoutes />,
  },
  {
    props: { exact: true, path: bookingIChargesUrl },
    component: <IncidentalCharges mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: roomingGuestListPath },
    component: <GuestList />,
  },
  {
    props: { exact: true, path: roomingGuestAddPath },
    component: <GuestForm mode={'Add'} />,
  },
  {
    props: { exact: true, path: roomingGuestEditPath },
    component: <GuestForm mode={'Edit'} />,
  },
  {
    props: { exact: true, path: roomAssignmentPath },
    component: <RoomTypeList />,
  },
  {
    props: { exact: true, path: roomUpgradelistPath },
    component: <RoomUpgradeList />,
  },
  {
    props: { exact: true, path: roomAssignmentFormPath },
    component: <RoomAssignment />,
  },
  {
    props: { exact: true, path: billingSchedulePath },
    component: <BillingSchedule />,
  },
  {
    props: { exact: true, path: rateAdjustmentAuditLogPath },
    component: <RateAdjustmentAuditLog />,
  },
  {
    props: { exact: true, path: houseLimitPath },
    component: <HouseLimit />,
  },
  {
    props: { exact: true, path: '/booking/add' },
    component: <BookingForm mode={'Add'} />,
  },
  {
    props: { exact: true, path: cloneBookingPath },
    component: <BookingForm mode={'Clone'} />,
  },
  {
    props: { exact: true, path: cloneBookingPath },
    component: <BookingForm mode={'Clone'} />,
  },

  {
    props: { exact: true, path: bookingEditInfoPath },
    component: <EditBooking />,
  },
  {
    props: { exact: true, path: '/booking/editroomtype' },
    component: <EditRoomType />,
  },
  {
    props: { exact: true, path: '/booking/editroomtype/guestlist' },
    component: <GuestList />,
  },
  {
    props: { exact: true, path: advancePaymentPath },
    component: <AdvancePaymentList />,
  },
  {
    props: { exact: true, path: confirmationPath },
    component: <BookingConfirmation />,
  },
  {
    props: { exact: true, path: attachmentsPath , mode: 'Booking'},
    component: <AttachmentsListing type={'Booking'}/>,
  },
  {
    props: { exact: true, path: advancePaymentDocPath },
    component: <AdvancePaymentDoc mode={'Booking'} type={'Receipt'} />,
  },

  {
    props: { exact: true, path: '/booking/advancepayment/redirect/:status' },
    component: <AdvancePaymentDocument />,
  },
  {
    props: { exact: true, path: roomCancelReinstatePath },
    component: <RoomCancelReinstate />,
  },
  {
    props: { exact: true, path: bookingAuditLogPath },
    component: <BookingAuditLog />,
  },
]

export default bookingRoutes
