import React, { lazy } from 'react'
const RoomType = lazy(() =>
  import('./RoomType').then(module => ({
    default: module.RoomType,
  }))
)

const RoomTypeForm = lazy(() =>
  import('./RoomTypeForm').then(module => ({
    default: module.RoomTypeForm,
  }))
)

const roomTypeRoutes = [
  {
    props: { exact: true, path: '/settings/roomtypesetup' },
    component: <RoomType />,
  },
  {
    props: { exact: true, path: '/settings/roomtypesetup/add' },
    component: <RoomTypeForm mode="New" />,
  },
  {
    props: { exact: true, path: '/settings/roomtypesetup/edit' },
    component: <RoomTypeForm mode="Edit" />,
  },
]

export default roomTypeRoutes
