import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { Button, TextField } from '@material-ui/core'
import Logo from 'assets/images/HotelX-Logo.png'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { useForgotPasswordMutation } from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { CopyRight } from '@ifca-root/react-component/src/components/AuthLayout/CopyRight'
import PhoneInput from 'react-phone-input-2'
import { usePhoneValidation } from 'containers/helper/hooks/usePhoneValidation'

interface IForgotPasswordForm {
  email: string
  contactNo: string
}

export const ForgotPassword = props => {
  let history = useHistory()

  const [openAlert, setOpenAlert] = useState(false)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState('')
  const [message, setMessage] = useState('')
  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/Login')
  }
  const [IsMobileNo, setIsMobileNo] = useState(false)

  const MESSAGE = `We have sent you ${
    IsMobileNo ? 'a message' : 'an email'
  }. Please click on the link to reset your password`
  const [
    forgotPasswordMutation,
    { data, loading, error },
  ] = useForgotPasswordMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    onCompleted: data => {
      console.log('data', data)
      setOpen(true)

      setTimeout(() => {
        history.push(`/login`)
      }, 1500)
    },
  })
  const { isValidPoneNumber, validatePhonePrefix } = usePhoneValidation()

  //useForm set DefaultValues, validationSchema
  const {
    handleSubmit,
    register,
    errors,
    control,
  } = useForm<IForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
    // resolver: yupResolver(ForgotPasswordSchema) as any,
  })
  console.log(errors)

  const onSubmit = (data: any) => {
    forgotPasswordMutation({
      variables: {
        //  softwareCode: '',
        email:
          IsMobileNo && validatePhonePrefix(data.contactNo)
            ? validatePhonePrefix(data.contactNo)
            : data.email,
      },
    })
    // .then(x=>{
    //  setOpen(true)
    //        history.push("/Login")
    // })
  }
  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Forgot Password',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout logo={Logo} image={Background}>
        {/* <span className="logo-title">Sales Online Platform</span> */}
        <span className="page-title">Forgot Password</span>
        <span className="page-subtitle desc">
          Don't Worry! Resetting your password is easy. Just type in the email /
          Mobile No you registered to HotelX.
        </span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            {IsMobileNo ? (
              <Controller
                as={
                  <PhoneInput
                    containerClass="contact-input-auth"
                    inputProps={{
                      name: 'Mobile No.',
                      required: true,
                    }}
                    specialLabel="Mobile No. *"
                    country={'my'} //Country prefix
                  />
                }
                name="contactNo"
                label="Mobile No."
                type="tel"
                fullWidth
                helperText={errors?.contactNo?.message}
                error={errors?.contactNo ? true : false}
                margin="normal"
                control={control}
              />
            ) : (
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.email?.message}
                error={errors?.email ? true : false}
                autoComplete="off"
                name="email"
                label="Email*"
                placeholder="Enter Email address"
                control={control}
                inputRef={register({})}
              />
            )}

            {/* <span
              className="forget-link"
              onClick={() => history.push('/login')}
            >
              Sign In Here
            </span> */}
            <div
              className=" color-orange click-text"
              style={{
                textAlign: 'center',
                // color: '#ffffff8a',
              }}
              onClick={() => setIsMobileNo(!IsMobileNo)}
            >
              <span className="desc m-r-4">
                Send via {IsMobileNo ? 'email' : 'sms'}
              </span>
            </div>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="login-btn"
          >
            Send
          </Button>
          <div className="other-auth">
            <span
              className="m-t-10 forget-link flex"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Do you remember your password?
              <span
                className="desc color-orange m-l-4"
                style={{ textDecoration: 'underline' }}
                onClick={() => history.push('/login')}
              >
                Try logging in
              </span>{' '}
            </span>
          </div>
        </form>
        <CopyRight />
      </AuthLayout>
    </>
  )
}
