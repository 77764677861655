import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Filter } from 'containers/helper/SearchFilter'


export const DocSelectionDialog = ({
  state,
  dispatch,
  docSelectionPrint,
  docSelectionShare,
}) => {
  const handleSubmit = () => {
    if (state.ButtonType === 'Print') {
      docSelectionPrint(selectedarr)
    } else if (state.ButtonType === 'Share') {
      docSelectionShare(selectedarr)
    }

    dispatch({
      type: 'DocSelectionDialog',
      payload: false,
    })
  }


const [status, setStatus] = useState(false)
  const [row, setRows] = useState([])
  const [selectedarr, setSelectearr] = useState([] as any)
  const [filterSearch, setFilterSearch] = useState<string>(
    localStorage.getItem('filterValue') || ''
  )

  console.log(state, 'state')
  console.log(selectedarr, 'selectedarr')
  console.log(row, 'row')


  useEffect(() => {
    if (state?.DocList && Array.isArray(state.DocList)) {
      const temp = state?.DocList?.filter((y) => y?.DocumentNo !== null)?.map((x, i) => ({
        ID: x?.DocumentNo,
        DocumentType: x?.DocumentType,
        IsSelected: true,
      }));
      
      setRows(temp);
      setSelectearr(temp);
      setCheckedState(new Array(temp.length).fill(true));
    }
  }, [state.DocList]);
  
  const [checkedState, setCheckedState] = useState(
    new Array(row.length).fill(true)
  )

  const handleOnChange = (position, k) => {
    const updatedCheckedState = checkedState.map((item, index) =>
    
      index === position ? !item : item
    )
    setCheckedState(updatedCheckedState)

    const selectedarr = Filter(row, filterSearch).map((z, c) => {
      return { ...z, IsSelected: updatedCheckedState[c] }
    })
    setStatus(true)
    setSelectearr(selectedarr.filter(x => x.IsSelected === true))
  }
  

  return (
    <CommonDialog
      fullWidth={true}
      open={state.DocSelectionDialog}
      onClose={() =>
        dispatch({
          type: 'DocSelectionDialog',
          payload: false,
        })
      }
      sections={{
        header: {
          dynamic: (
            <div className="">
              <div className="dialog-dynamic-content">
                <div className="session">
                  <span className="xsTitle">{state.ButtonType} </span>
                </div>
              </div>
            </div>
          ),
        },
        body: () => (
          <CardContents>
            <FormControl className="m-t-10" component="fieldset">
              <FormLabel component="legend">
                Select document to {state.ButtonType}
              </FormLabel>
              <FormGroup>
                {row?.map((x, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                      size="small"
                      color="primary"
                      value={x}
                      checked={checkedState[i]}
                      onChange={() => handleOnChange(i, x)}
                      />
                    }
                    label={x?.ID}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </CardContents>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => {
                  dispatch({
                    type: 'DocSelectionDialog',
                    payload: false,
                  })
                },
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: () => handleSubmit(),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
