import { ApolloProvider } from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import '@ifca-root/react-component/src/assets/styles/app.scss'
import theme from '@ifca-root/react-component/src/assets/theme'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/'
import { ApolloLink } from 'apollo-boost'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import 'assets/style/app.scss'
import {
  cache,
  hotelxNodeUrl,
  hotelXRefreshUrl,
  requestLink,
  uploadLink,
  WSclient,
  Uploadclient,
} from 'client'
import { InstallBanner } from 'components/Footer/InstallBanner'
import Layout from 'components/Layout/Layout'
import SnackBarMsg from 'components/SnackBar/SnackBarMsg'
import { useInstallPrompt } from 'containers/helper/hooks/useInstallPrompt'
import { createBrowserHistory } from 'history'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useReducer, useState } from 'react'
import { Router, useHistory } from 'react-router-dom'
import { getAccessToken, setAccessToken } from '../../AccessToken'
import { PermissionWrapper } from './PermissionWrapper'
import { NACheckWrapper } from './NACheckWrapper'
import { QRCheckWrapper } from './QRCheckWrapper'
import Routes from './Router/Routes'
import AppContext from './Store/AppContext'
import { GlobalInitialState, RootReducer } from './Store/RootReducer'
import { SocketContextProvider } from 'containers/Socket/SocketContext'


export const browserHistory = createBrowserHistory()

let MainApp = () => {
  const [messages, setMessages] = useState<string[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState)
  const [loading, setLoading] = useState(true)
  const historyLocate = useHistory()
  useEffect(() => {
    fetch(hotelXRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x.json()
      setAccessToken(accessToken)
      // console.log(x)
      setLoading(false)
    })
  }, [])
  const { promptable, promptToInstall, isInstalled } = useInstallPrompt()
  const [isVisible, setVisible] = useState(false)

  const hide = () => setVisible(false)
 
  const client = new ApolloClient({
    link: ApolloLink.from([
      new TokenRefreshLink({
        accessTokenField: 'accessToken',
        isTokenValidOrUndefined: () => {
          const token = getAccessToken()

          if (!token) {
            return true
          }

          try {
            const { exp } = jwtDecode(token)
            if (Date.now() >= exp * 1000) {
              return false
            } else {
              return true
            }
          } catch (err) {
            console.log(err)
            return false
          }
        },
        fetchAccessToken: () => {
          return fetch(hotelXRefreshUrl, {
            method: 'POST',
            credentials: 'include',
          })
        },
        handleFetch: accessToken => {
          setAccessToken(accessToken)
        },
        handleError: err => {
          console.log(err, 'heeeeeeeeeeere1 ')
          dispatch({
            type: 'Errors',
            payload: {
              Error: true,
              Message: err.toString(),
            },
          })
          window.location.href = '/login'
        },
      }),
      onError(({ graphQLErrors }) => {
        console.log(graphQLErrors, 'heeeeeeeeeeere2 ')
        if (
          graphQLErrors[0]?.message
            .toLowerCase()
            .includes('Session'.toLowerCase()) ||
          graphQLErrors[0]?.message
            .toLowerCase()
            .includes('not authenticated'.toLowerCase()) ||
          graphQLErrors[0]?.message
            .toLowerCase()
            .includes(
              'Token is not valid, please try login again'.toLowerCase()
            ) ||
          graphQLErrors[0]?.message
            .toLowerCase()
            .includes('Token is blacklisted, please login again'.toLowerCase())
        ) {
          dispatch({
            type: 'Errors',
            payload: {
              Error: true,
              Message: graphQLErrors[0]?.message,
            },
          })
          // console.log(location?.pathname);
          if (browserHistory?.location?.pathname !== '/login') {
            window.location.reload()
          }
        }
        console.log(graphQLErrors[0]?.message, 'heree')
      }),
      requestLink,
      uploadLink,
      new HttpLink({
        uri: hotelxNodeUrl,
        credentials: 'include',
      }), //new HttpLink ends here
    ]),
    cache,
  })

  console.log(client,"client")

  useEffect(() => {
    if (prompt) {
      setVisible(true)
    }
  }, [prompt])
  if (loading) {
    return <Loading />
  }
  if (process.env.NODE_ENV !== 'development') console.log = () => {}
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch({
      type: 'Errors',
      payload: {
        Error: false,
        Message: '',
      },
    })
  }




  return (
    
    <AppContext.Provider value={{ globalState, dispatch }}>
    {/* <div>
      <h1>My Socket.IO App</h1>
      <SocketComponent />
    </div> */}
      <ThemeProvider theme={theme}>
        <ApolloProvider client={WSclient}>
          <ApolloProvider client={client}>
            {/* <ApolloProvider client={Uploadclient}> */}
            {/* <ApolloProvider client={Uploadclient}> */}
            {/* <ClearCacheProvider auto={true} filename="build.json"> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <SocketContextProvider>
              <Router history={browserHistory}>
               
                <NACheckWrapper>
                  <PermissionWrapper>
                    <Layout>
                      <Routes />
                    </Layout>
                    <SnackBarMsg
                      open={globalState?.Errors?.Error}
                      onClose={handleClose}
                      message={globalState?.Errors?.Message}
                    />
                    {promptable && !isInstalled ? (
                      <InstallBanner
                        ButtonOption={{
                          section: {
                            props: {
                              style: { display: !isVisible ? 'none' : null },
                            },
                          },
                          option1: {
                            props: {
                              onClick: () => hide(),
                            },
                          },
                          option2: {
                            props: {
                              onClick: () => promptToInstall(),
                            },
                          },
                        }}
                      />
                    ) : null}
                    </PermissionWrapper>
                  </NACheckWrapper>
               
              </Router>
              </SocketContextProvider>
            </MuiPickersUtilsProvider>
            {/* </ClearCacheProvider> */}
            {/* </ApolloProvider> */}
          </ApolloProvider>
        </ApolloProvider>
        {/* </ApolloProvider> */}
      </ThemeProvider>
    </AppContext.Provider>
  )
}
const App = () => {
  return <MainApp />
}
export default App
