import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'
const Deposit = lazy(() =>
  import('./Deposit').then(module => ({
    default: module.Deposit,
  }))
)
const DepositRefundDetail = lazy(() =>
  import('./DepositRefundDetail').then(module => ({
    default: module.DepositRefundDetail,
  }))
)
const DepositForm = lazy(() =>
  import('./DepositForm').then(module => ({
    default: module.DepositForm,
  }))
)
const DepositDetail = lazy(() =>
  import('./DepositDetail').then(module => ({
    default: module.DepositDetail,
  }))
)
//New check in flow due in detail

export const dueInDepositUrl = `/frontdesk/duein/detail/deposit`
export const dueInDepositDetailUrl = `/frontdesk/duein/deposit/depositdetail`
export const dueInDepositRefundUrl = `/frontdesk/duein/deposit/detail`
export const walkInDepositUrl = `/frontdesk/walkin/deposit`
export const walkInDepositDetailUrl = `/frontdesk/walkin/deposit/depositdetail`
export const inhouseDepositUrl = `/frontdesk/inhouse/deposit`
export const inhouseDepositDetailUrl = `/frontdesk/inhouse/deposit/depositdetail`
// Dueout
export const dueOutDepositUrl = `/frontdesk/dueout/deposit`
export const dueOutDepositRefundUrl = `/frontdesk/dueout/deposit/detail`
//DEPOSIT
export const depositUrl = `/frontdesk/deposit`
export const depositAddUrl = `/frontdesk/deposit/add`
export const depositViewUrl = `/frontdesk/deposit/detail`
export const depositRefundUrl = `/frontdesk/deposit/refunddetail`

export const DepositRoutes = [
  {
    props: { exact: true, path: dueInDepositUrl },
    component: <Deposit mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: dueInDepositDetailUrl },
    component: <DepositDetail mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: dueInDepositRefundUrl },
    component: <DepositRefundDetail mode={FrontDeskType.Deposit} />,
  },
  {
    props: { exact: true, path: walkInDepositUrl },
    component: <Deposit mode={FrontDeskType.WalkIn} />,
  },
  {
    props: { exact: true, path: walkInDepositDetailUrl },
    component: <DepositDetail mode={FrontDeskType.WalkIn} />,
  },
  //
  {
    props: { exact: true, path: inhouseDepositUrl },
    component: <Deposit mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: inhouseDepositDetailUrl },
    component: <DepositDetail mode={FrontDeskType.InHouse} />,
  },
  // DUE OUT
  {
    props: { exact: true, path: dueOutDepositUrl },
    component: <Deposit mode={FrontDeskType.DueOut} />,
  },
  {
    props: { exact: true, path: dueOutDepositRefundUrl },
    component: <DepositDetail mode={FrontDeskType.DueOut} />,
  },
  //DEPOSIT
  {
    props: { exact: true, path: depositUrl },
    component: <Deposit mode={FrontDeskType.Deposit} />,
  },
  {
    props: { exact: true, path: depositAddUrl },
    component: <DepositForm mode={FrontDeskType.Deposit} />,
  },
  {
    props: { exact: true, path: depositViewUrl },
    component: <DepositDetail mode={FrontDeskType.Deposit} />,
  },
  {
    props: { exact: true, path: depositRefundUrl },
    component: <DepositRefundDetail mode={FrontDeskType.Deposit} />,
  },
]
