import React, { lazy } from 'react'
const AccountJournal = lazy(() =>
  import('./AccountJournal').then(module => ({
    default: module.AccountJournal,
  }))
)
const AccountMappingList = lazy(() =>
  import('./AccountMapping/AccountMappingList').then(module => ({
    default: module.AccountMappingList,
  }))
)
const BankAccountList = lazy(() =>
  import('./AccountMapping/BankAccount/BankAccountList').then(module => ({
    default: module.BankAccountList,
  }))
)
const BankAccountDetail = lazy(() =>
  import('./AccountMapping/BankAccount/BankAccountDetail').then(module => ({
    default: module.BankAccountDetail,
  }))
)
const BankAccountForm = lazy(() =>
  import('./AccountMapping/BankAccount/BankAccountForm').then(module => ({
    default: module.BankAccountForm,
  }))
)
const HotelMapping = lazy(() =>
  import('./AccountMapping/HotelMapping/HotelMapping').then(module => ({
    default: module.HotelMapping,
  }))
)
const TaxMapping = lazy(() =>
  import('./AccountMapping/TaxMapping/TaxMapping').then(module => ({
    default: module.TaxMapping,
  }))
)
const CityLedgerTaxMapping = lazy(() =>
  import('./AccountMapping/CityLedgerTaxMapping/CityLedgerTaxMapping').then(module => ({
    default: module.CityLedgerTaxMapping,
  }))
)
const CityLedgerMapping = lazy(() =>
  import('./AccountMapping/CityLedgerMapping/CityLedgerMapping').then(
    module => ({
      default: module.CityLedgerMapping,
    })
  )
)
const GLExport = lazy(() =>
  import('./GLJournal/GLExport').then(module => ({
    default: module.GLExport,
  }))
)
const GLExportForm = lazy(() =>
  import('./GLJournal/GLExportForm').then(module => ({
    default: module.GLExportForm,
  }))
)
const GLExportDetail = lazy(() =>
  import('./GLJournal/GLExportDetail').then(module => ({
    default: module.GLExportDetail,
  }))
)
const GLJournalIndividualDetail = lazy(() =>
  import('./GLJournal/GLJournalIndividualDetail').then(module => ({
    default: module.GLJournalIndividualDetail,
  }))
)
const CBTransaction = lazy(() =>
  import('./CBTransaction/CBTransaction').then(module => ({
    default: module.CBTransaction,
  }))
)
const CBTransactionForm = lazy(() =>
  import('./CBTransaction/CBTransactionForm').then(module => ({
    default: module.CBTransactionForm,
  }))
)
const CBTransactionDetail = lazy(() =>
  import('./CBTransaction/CBTransactionDetail').then(module => ({
    default: module.CBTransactionDetail,
  }))
)
const CBTransactionIndividualDetail = lazy(() =>
  import('./CBTransaction/CBTransactionIndividualDetail').then(module => ({
    default: module.CBTransactionIndividualDetail,
  }))
)

const ConsolidatedEInvoiceForm = lazy(() =>
  import('../ConsolidateEInvoiceModule/ConsolidatedEInvoiceForm').then(module => ({
    default: module.ConsolidatedEInvoiceForm,
  }))
)
const GLExportRoutes = [
  {
    props: { exact: true, path: '/account-journals' },
    component: <AccountJournal />,
  },
  {
    props: { exact: true, path: '/account-journals/account-mapping' },
    component: <AccountMappingList />,
  },
  {
    props: { exact: true, path: '/account-journals/account-mapping/hotel' },
    component: <HotelMapping />,
  },
  {
    props: { exact: true, path: '/account-journals/account-mapping/tax' },
    component: <TaxMapping />,
  },
  {
    props: { exact: true, path: '/account-journals/account-mapping/city-ledger-tax' },
    component: <CityLedgerTaxMapping />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/account-mapping/cityledger',
    },
    component: <CityLedgerMapping />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/account-mapping/bank-account',
    },
    component: <BankAccountList />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/account-mapping/bank-account/detail',
    },
    component: <BankAccountDetail />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/account-mapping/bank-account/add',
    },
    component: <BankAccountForm FormMode={'Add'} />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/account-mapping/bank-account/edit',
    },
    component: <BankAccountForm FormMode={'Edit'} />,
  },
  {
    props: { exact: true, path: '/account-journals/gl-journal' },
    component: <GLExport />,
  },
  {
    props: { exact: true, path: '/account-journals/gl-journal/new' },
    component: <GLExportForm />,
  },
  {
    props: { exact: true, path: '/account-journals/gl-journal/new/detail' },
    component: <GLJournalIndividualDetail />,
  },
  {
    props: { exact: true, path: '/account-journals/gl-journal/detail' },
    component: <GLExportDetail />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/gl-journal/detail/individual',
    },
    component: <GLJournalIndividualDetail />,
  },
  //cb
  {
    props: { exact: true, path: '/account-journals/cb-transaction' },
    component: <CBTransaction />,
  },
  {
    props: { exact: true, path: '/account-journals/cb-transaction/new' },
    component: <CBTransactionForm />,
  },
  {
    props: { exact: true, path: '/account-journals/cb-transaction/new/detail' },
    component: <CBTransactionIndividualDetail />,
  },
  {
    props: { exact: true, path: '/account-journals/cb-transaction/detail' },
    component: <CBTransactionDetail />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/cb-transaction/detail/individual',
    },
    component: <CBTransactionIndividualDetail />,
  },
  {
    props: {
      exact: true,
      path: '/account-journals/consolidated/add',
    },
    component: <ConsolidatedEInvoiceForm />,
  },
]

export default GLExportRoutes
