import React, { lazy } from 'react'

const EDocument = lazy(() =>
  import('./EDocumentLisings').then(module => ({
    default: module.EDocumentList,
  }))
)

const EDocumentForm = lazy(() =>
  import('./EDocumentForm').then(module => ({
    default: module.EDocumentForm,
  }))
)

const eDocumentRoutes = [
  {
    props: { exact: true, path: '/settings/edocumentsetup' },
    component: <EDocument />,
  },
  {
    props: { exact: true, path: '/settings/edocumentsetup/registrationcard' },
    component: <EDocumentForm mode={"RegCard"} />,
  },
  {
    props: { exact: true, path: '/settings/edocumentsetup/confirmationletter' },
    component: <EDocumentForm mode={"Confirmation"} />,
  },
  
]

export default eDocumentRoutes