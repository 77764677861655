import React, { lazy } from 'react'

const RoomSalesOptimization = lazy(() =>
  import('./RoomSalesOptimization').then(module => ({
    default: module.RoomSalesOptimization,
  }))
)

const roomSalesOptimizationRoutes = [
  {
    props: { exact: true, path: '/settings/roomsalesoptimization' },
    component: <RoomSalesOptimization />,
  },
]

export default roomSalesOptimizationRoutes
