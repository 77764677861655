import React from 'react'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import {
    Box
} from '@material-ui/core'
import { useBookingAttachmentDeleteMutation } from 'generated/graphql'

interface Props {
    open: boolean
    close: () => void
    refetch: () => void;
    state: any
}
export const AttachmentsDeleteDialog = ({
    open,
    close,
    refetch,
    state
}: Props) => {
    const [
        BookingAttachmentDelete,
        { loading: LoadingBookingAttachmentDelete },
    ] = useBookingAttachmentDeleteMutation()
    const handleConfirm = async () => {
        try {
            const response = await BookingAttachmentDelete({
                variables: {
                    BookingAttachmentID: state?.ID
                }
            });

            if (response.data.BookingAttachmentDelete) {
                refetch();
                close();
            }
        } catch (error) {
            console.error("Error deleting attachment:", error);
        }
    }
    return (
        <>
            <CommonDialog
                fullWidth={true}
                open={open}
                onClose={close}
                sections={{
                    header: {
                        dynamic: (
                            <div className="">
                                <div className="dialog-dynamic-content">
                                    <div className="session">
                                        <div className="flex session">
                                            <div className="xsTitle color-primary-orange flex-space">
                                                Delete Attachments
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="infoline-content">
                                    <div className="infoline desc  display-flex"></div>
                                </div>
                            </div>
                        ),
                    },
                    body: () => (
                        <>
                        <span className="xsTitle">Confirm to proceed?</span>
                        </>
                    ),
                    footer: {
                        actions: [
                            {
                                displayText: 'Cancel',
                                props: {
                                    onClick: () => {
                                        close()
                                    },
                                    variant: 'contained',
                                    color: 'primary',
                                },
                            },
                            {
                                displayText: 'Confirm',
                                props: {
                                    onClick: handleConfirm, // Call handleConfirm here
                                    variant: 'contained',
                                    color: 'primary',
                                },
                            },
                        ],
                    },
                }}
            />
        </>
    )
}
