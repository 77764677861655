import React, { lazy } from 'react'
import corporateRecordsListRoutes from './CorporateList/CorporateListRoutes'
import ratePolicyRoutes from './RatePolicySetup/RatePolicySetupRoutes'

const Corporate = lazy(() =>
  import('./Corporate').then(module => ({
    default: module.Corporate,
  }))
)

const CorporateList = lazy(() =>
  import('./CorporateList/CorporateList').then(module => ({
    default: module.CorporateList,
  }))
)

const RatePolicySetup = lazy(() =>
  import('./RatePolicySetup/RatePolicySetup').then(module => ({
    default: module.RatePolicySetup,
  }))
)

const corporateRoutes = [
  {
    props: { exact: true, path: '/corporate' },
    component: <Corporate />,
  },

  {
    props: { exact: true, path: '/corporate/corporatelist' },
    component: <CorporateList />,
  },

  {
    props: { exact: true, path: '/corporate/ratepolicysetup' },
    component: <RatePolicySetup />,
  },
]

corporateRecordsListRoutes.forEach(route => {
  corporateRoutes.push(route)
})

ratePolicyRoutes.forEach(route => {
  corporateRoutes.push(route)
})

export default corporateRoutes
