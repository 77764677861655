import React, { useEffect, useState } from 'react'
import {
  TextField,
  InputAdornment,
  IconButton,
  StandardTextFieldProps,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import { GetApp, Close } from '@material-ui/icons'
import IconItem from 'components/Avatar/IconItem'

interface FileUploadProps extends StandardTextFieldProps {
  onHandleUploadChange?: any
  multiple?: any
  previewFiles?: any
  files?: any
  imagePreview: any
  accept?: string
  mapData?: string
}

export const FileUploadInput = ({
  onHandleUploadChange,
  multiple,
  previewFiles,
  files,
  imagePreview,
  accept,
  mapData,
  ...InputProps
}: FileUploadProps) => {
  const onClickHiddenInput = (mapData?: any) => {
    if (mapData) {
      document.getElementById('icon-button-file-' + mapData).click()
    } else {
      document.getElementById('icon-button-file').click()
    }
  }
  const handleDisplayFileInput = (files, mapData?) => {
    return files?.length === 0 || files === undefined
      ? ''
      : files?.length === 1
      ? files[0]?.name
      : files?.length > 1
      ? `${files.length} files`
      : null
  }

  return (
    <>
      <TextField
        {...InputProps}
        fullWidth
        value={handleDisplayFileInput(files, mapData)}
        onClick={() => {
          onClickHiddenInput(mapData)
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton className="transform-180" aria-label="">
                  <GetApp />
                </IconButton>
              </InputAdornment>
              <input
                accept={accept} // sorry chia if uncomment this cause error :(
                hidden
                multiple={multiple}
                // multiple
                onChange={e => {
                  onHandleUploadChange(e)
                  e.target.value = null
                }}
                id={
                  mapData ? `icon-button-file-${mapData}` : 'icon-button-file'
                }
                type="file"
              />
            </>
          ),
        }}
      />
      <div className="preview-wrapper">{imagePreview}</div>
    </>
  )
}
