import { HotelPermission } from 'generated/graphql'

export const LedgerType = {
  Invoices: 'Invoices',
  DebitNotes: 'Debit Notes',
  CreditNotes: 'Credit Notes',
  OfficialReceipt: 'Official Receipt',
  Refund: 'Refund',
  GroupInvoices: 'Group Invoices',
}
export const LedgerTypeArr = [
  {
    Invoices: 'Invoices',
    DebitNotes: 'Debit Notes',
    CreditNotes: 'Credit Notes',
    OfficialReceipt: 'Official Receipt',
    Refund: 'Refund',
    allforone: 'all',
  },
]

export const LedgerTypePath = {
  Invoices: 'invoices',
  DebitNotes: 'debit-notes',
  CreditNotes: 'credit-notes',
  OfficialReceipt: 'official-receipts',
  Refund: 'refunds',
}
export const PageType = {
  CityLedger: 'CityLedger',
  Others: 'Others',
  CL_trx: 'CL_trx',
}
export const createGLPerm = {
  Invoices: HotelPermission.CityLedgerAccountTransactionInvoiceCreate,
  'Debit Notes': HotelPermission.CityLedgerAccountTransactionDebitNoteCreate,
  'Credit Notes': HotelPermission.CityLedgerAccountTransactionCreditNoteCreate,
  'Official Receipt':
    HotelPermission.CityLedgerAccountTransactionOfficialReceiptCreate,
  Refund: HotelPermission.CityLedgerAccountTransactionRefundCreate,
}
export const editGLPerm = {
  Invoices: HotelPermission.CityLedgerAccountTransactionInvoiceEdit,
  'Debit Notes': HotelPermission.CityLedgerAccountTransactionDebitNoteEdit,
  'Credit Notes': HotelPermission.CityLedgerAccountTransactionCreditNoteEdit,
  'Official Receipt':
    HotelPermission.CityLedgerAccountTransactionOfficialReceiptEdit,
  Refund: HotelPermission.CityLedgerAccountTransactionRefundEdit,
}
export const allocateGLPerm = {
  Invoices: HotelPermission.CityLedgerAccountTransactionInvoiceAllocate,
  'Debit Notes': HotelPermission.CityLedgerAccountTransactionDebitNoteAllocate,
  'Credit Notes':
    HotelPermission.CityLedgerAccountTransactionCreditNoteAllocate,
  'Official Receipt':
    HotelPermission.CityLedgerAccountTransactionOfficialReceiptAllocate,
  Refund: HotelPermission.CityLedgerAccountTransactionRefundAllocate,
}
export const cancelGLPerm = {
  Invoices: HotelPermission.CityLedgerAccountTransactionInvoiceCancel,
  'Debit Notes': HotelPermission.CityLedgerAccountTransactionDebitNoteCancel,
  'Credit Notes': HotelPermission.CityLedgerAccountTransactionCreditNoteCancel,
  'Official Receipt':
    HotelPermission.CityLedgerAccountTransactionOfficialReceiptCancel,
  Refund: HotelPermission.CityLedgerAccountTransactionRefundCancel,
}
export const printGLPerm = {
  Invoices: HotelPermission.CityLedgerAccountTransactionInvoicePrint,
  'Debit Notes': HotelPermission.CityLedgerAccountTransactionDebitNotePrint,
  'Credit Notes': HotelPermission.CityLedgerAccountTransactionCreditNotePrint,
  'Official Receipt':
    HotelPermission.CityLedgerAccountTransactionOfficialReceiptPrint,
  Refund: HotelPermission.CityLedgerAccountTransactionRefundPrint,
}
