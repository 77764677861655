import { BIDocument } from 'containers/BusinessInsightModule/BIDigitalDocument/BIDocument'
import React, { lazy } from 'react'
import { SuspendDocument } from '../SuspendFolio/SuspendDocument'

const NonGuestFolioList = lazy(() =>
  import('./NonGuestFolioList').then(module => ({
    default: module.NonGuestFolioList,
  }))
)
const NonGuestFolioForm = lazy(() =>
  import('./NonGuestFolioForm').then(module => ({
    default: module.NonGuestFolioForm,
  }))
)
const NonGuestFolio = lazy(() =>
  import('./NonGuestFolio').then(module => ({
    default: module.NonGuestFolio,
  }))
)
export const nonGuestFolioUrl = '/'
export const NonGuestFolioRoutes = [
  {
    props: { exact: true, path: '/frontdesk/nonguestfoliolist' },
    component: <NonGuestFolioList />,
  },
  {
    props: { exact: true, path: '/frontdesk/nonguestfoliolist/add' },
    component: <NonGuestFolioForm mode={'Add'} />,
  },
  {
    props: { exact: true, path: '/frontdesk/nonguestfoliolist/edit' },
    component: <NonGuestFolioForm mode={'Edit'} />,
  },
  {
    props: { exact: true, path: '/frontdesk/nonguestfoliolist/folio' },
    component: <NonGuestFolio />,
  },
  {
    props: {
      exact: true,
      path: '/frontdesk/nonguestfoliolist/folio/redirect/:status',
    },
    component: <NonGuestFolio />,
  },
  {
    props: { exact: true, path: '/frontdesk/nonguestfoliolist/folio/print' },
    component: <SuspendDocument mode={''} type="NoGuest" />,
  },
]
