import React, { lazy } from 'react'
import { RateCategory } from 'generated/graphql'
import {
  TravelAgentRateEffectiveAddPath,
  TravelAgentRateEffectiveEditPath,
  TravelAgentRateEffectivePath,
  TravelAgentRateSetup,
} from 'containers/SettingsModule/RatePolicyV2/RateSetup/RateHelper'

const RatePolicySetup = lazy(() =>
  import('./RatePolicySetup').then(module => ({
    default: module.RatePolicySetup,
  }))
)

const RatePolicySetupForm = lazy(() =>
  import('./RatePolicySetupForm').then(module => ({
    default: module.RatePolicySetupForm,
  }))
)
const RateSetup = lazy(() =>
  import('../../SettingsModule/RatePolicyV2/RateSetup/RateSetup').then(
    module => ({
      default: module.RateSetup,
    })
  )
)
const RateEffective = lazy(() =>
  import('../../SettingsModule/RatePolicyV2/RateSetup/RateEffective').then(
    module => ({
      default: module.RateEffective,
    })
  )
)
const RateEffectiveForm = lazy(() =>
  import('../../SettingsModule/RatePolicyV2/RateSetup/RateEffectiveForm').then(
    module => ({
      default: module.RateEffectiveForm,
    })
  )
)
const ratePolicyRoutes = [
  {
    props: { exact: true, path: '/travelagent/ratepolicysetup' },
    component: <RatePolicySetup />,
  },

  {
    props: { exact: true, path: '/travelagent/ratepolicysetup/add' },
    component: <RatePolicySetupForm mode="Add" />,
  },

  {
    props: { exact: true, path: '/travelagent/ratepolicysetup/edit' },
    component: <RatePolicySetupForm mode="Edit" />,
  },
  //! New Rate Stup
  {
    props: { exact: true, path: TravelAgentRateSetup },
    component: <RateSetup rateCategoryMode={RateCategory['TravelAgent']} />,
  },

  {
    props: { exact: true, path: TravelAgentRateEffectivePath },
    component: <RateEffective rateCategoryMode={RateCategory['TravelAgent']} />,
  },
  {
    props: { exact: true, path: TravelAgentRateEffectiveAddPath },
    component: (
      <RateEffectiveForm
        mode="Add"
        rateCategoryMode={RateCategory['TravelAgent']}
      />
    ),
  },
  {
    props: { exact: true, path: TravelAgentRateEffectiveEditPath },
    component: (
      <RateEffectiveForm
        mode="Edit"
        rateCategoryMode={RateCategory['TravelAgent']}
      />
    ),
  },
]

export default ratePolicyRoutes
