import React, { useEffect } from 'react'

export const usePreventRefresh = () => {
  const handleKeyDown = event => {
    switch (event.keyCode) {
      case 116:
        event.preventDefault()
        window.status = 'F5 Disabled'
        break
      case 82:
        if (event.ctrlKey) {
          event.preventDefault()
          window.status = 'CTRL Disabled'
        }
        break
    }
  }
  const handleRightClick = event => {
    event.preventDefault()
  }
  //   useEffect(() => {
  //     document.addEventListener('keydown', handleKeyDown)
  //     document.addEventListener('contextmenu', handleRightClick)
  //     return () => {
  //       document.removeEventListener('keydown', handleKeyDown)
  //       document.removeEventListener('contextmenu', handleRightClick)
  //     }
  //   }, [])
  return { handleKeyDown, handleRightClick }
}
