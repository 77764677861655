import React from 'react'
import pdf from '../../assets/icons/pdf.svg'
import doc from '../../assets/icons/file.svg'
import { Close } from '@material-ui/icons'
import { ListItemAvatar, Avatar } from '@material-ui/core'
interface Props {
  src?: string
  onClick: any
  remove?: any
  mediaType?: any
  diffClass?: boolean
}
export const UploadPreview = ({
  src,
  onClick,
  remove,
  mediaType,
  diffClass,
}: Props) => {
  return (
    <div className="preview-wrapper">
      {remove && (
        <ListItemAvatar
          style={{
            position: 'absolute',
            padding: 0,
          }}
        >
          <Avatar
            className={diffClass ? 'close-btn-alternate' : 'close-btn'}
            onClick={onClick}
          >
            <Close />
          </Avatar>
        </ListItemAvatar>
      )}
      <a href={src} target="_blank">
        {mediaType ? (
          <>
            {mediaType?.includes('image') ? (
              <>
                <img
                  src={src}
                  className={
                    diffClass ? 'img-preview-alternate' : 'img-preview'
                  }
                  loading="lazy"
                  alt="img"
                />
              </>
            ) : mediaType?.includes('pdf') ? (
              <>
                <img
                  src={pdf}
                  className="img-preview"
                  loading="lazy"
                  alt="img"
                />
              </>
            ) : mediaType?.includes('video') ? (
              <video className="vid_preview" controls>
                <source id="videoSource" src={src} />
              </video>
            ) : mediaType?.includes('blob') ? (
              <div>
                <audio id="videoSource" src={src} controls />
              </div>
            ) : (
              <>
                <img
                  src={doc}
                  className="img-preview"
                  loading="lazy"
                  width="50"
                  height="50"
                  alt="img"
                />
              </>
            )}{' '}
          </>
        ) : (
          <img src={src} className="img-preview" loading="lazy" alt="img" />
        )}
      </a>
    </div>
  )
}
