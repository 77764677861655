import React, { lazy } from 'react'

const DigitalMarketing = lazy(() =>
  import('./DigitalMarketing').then(module => ({
    default: module.DigitalMarketing,
  }))
)
const EmailMarketing = lazy(() =>
  import('./EmailMarketing').then(module => ({
    default: module.EmailMarketing,
  }))
)
const EmailMarketingForm = lazy(() =>
  import('./EmailMarketingForm').then(module => ({
    default: module.EmailMarketingForm,
  }))
)
const MarketingAdsForm = lazy(() =>
  import('./MarketingAdsForm').then(module => ({
    default: module.MarketingAdsForm,
  }))
)

const EmailMarketingPreview = lazy(() =>
  import('./EmailMarketingPreview').then(module => ({
    default: module.EmailMarketingPreview,
  }))
)

const Filters = lazy(() =>
  import('./Filters').then(module => ({
    default: module.Filters,
  }))
)

const Promotion = lazy(() =>
  import('./Promotion').then(module => ({
    default: module.Promotion,
  }))
)

const EmailMarketingList = lazy(() =>
  import('./EmailMarketingList').then(module => ({
    default: module.EmailMarketingList,
  }))
)

const PromotionForm = lazy(() =>
  import('./PromotionForm').then(module => ({
    default: module.PromotionForm,
  }))
)

const GuestReviewListing = lazy(() =>
  import('./GuestRatingReviewListing').then(module => ({
    default: module.GuestReviewListing,
  }))
)

const GuestReviewDetails = lazy(() =>
  import('./GuestRatingReviewDetails').then(module => ({
    default: module.GuestRatingReviewDetails,
  }))
)

const DigitalMarketingRoutes = [
  {
    props: { exact: true, path: '/digitalmarketing' },
    component: <DigitalMarketing />,
  },
  {
    props: { exact: true, path: '/digitalmarketing/emailmarketing' },
    component: <EmailMarketing />,
  },
  {
    props: { exact: true, path: '/digitalmarketing/emailmarketing/add' },
    component: <MarketingAdsForm FormMode="Add" />,
  },
  {
    props: { exact: true, path: '/digitalmarketing/emailmarketing/edit' },
    component: <MarketingAdsForm FormMode="Edit" />,
  },

  {
    props: { exact: true, path: '/digitalmarketing/emailmarketing/lists' },
    component: <EmailMarketingList />,
  },
  {
    props: {
      exact: true,
      path: '/digitalmarketing/emailmarketing/lists/filters',
    },
    component: <Filters />,
  },
  {
    props: { exact: true, path: '/digitalmarketing/emailmarketing/preview' },
    component: <EmailMarketingPreview />,
  },

  {
    props: { exact: true, path: '/digitalmarketing/promotion' },
    component: <Promotion />,
  },

  {
    props: { exact: true, path: '/digitalmarketing/promotion/add' },
    component: <PromotionForm mode="Add" />,
  },
  {
    props: { exact: true, path: '/digitalmarketing/promotion/edit' },
    component: <PromotionForm mode="Edit" />,
  },
  {
    props: { exact: true, path: '/digitalmarketing/guestratingreview' },
    component: <GuestReviewListing />,
  },
  {
    props: { exact: true, path: '/digitalmarketing/guestratingreview/details' },
    component: <GuestReviewDetails />,
  },
]

export default DigitalMarketingRoutes
