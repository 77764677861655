import { BIDocument } from 'containers/BusinessInsightModule/BIDigitalDocument/BIDocument'
import React, { lazy } from 'react'
// import ratePolicyRoutes from './RatePolicySetup/RatePolicySetupRoutes'

const CheckoutAdjustmentList = lazy(() =>
  import('./CheckoutAdjustmentList').then(module => ({
    default: module.CheckoutAdjustmentList,
  }))
)
// const CheckoutAdjustmentAdd = lazy(() =>
//   import('./CheckoutAdjustmentAdd').then(module => ({
//     default: module.CheckoutAdjustmentAdd,
//   }))
// )
// const CheckoutAdjustmentView = lazy(() =>
//   import('./CheckoutAdjustmentView').then(module => ({
//     default: module.CheckoutAdjustmentView,
//   }))
// )
// const CheckoutAdjustmentDocument = lazy(() =>
//   import('./CheckoutAdjustmentDocument').then(module => ({
//     default: module.CheckoutAdjustmentDocument,
//   }))
// )
// const CheckoutAdjustmentFolio = lazy(() =>
//   import('./CheckoutAdjustmentFolio').then(module => ({
//     default: module.CheckoutAdjustmentFolio,
//   }))
// )
const CheckoutAdjustment = lazy(() =>
  import('./CheckoutAdjustment').then(module => ({
    default: module.CheckoutAdjustment,
  }))
)

const IncidentalChargeForm = lazy(() =>
  import('./IncidentalChargeForm').then(module => ({
    default: module.IncidentalChargeForm,
  }))
)
const CheckoutAdjustmentRoutes = [
  {
    props: { exact: true, path: '/checkoutadjustment' },
    component: <CheckoutAdjustmentList />,
  },
  {
    props: { exact: true, path: '/checkoutadjustment/adjustment' },
    component: <CheckoutAdjustment />,
  },
  {
    props: {
      exact: true,
      path: '/checkoutadjustment/adjustment/redirect/:status',
    },
    component: <CheckoutAdjustment />,
  },
  {
    props: { exact: true, path: '/checkoutadjustment/adjustment/share' },
    component: <BIDocument mode={''} type="Checkout Adjustment" />,
  },

  {
    props: {
      exact: true,
      path: '/checkoutadjustment/debit/add',
    },
    component: <IncidentalChargeForm ledgerType={'Debit Notes'} formMode="add" />,
  },
  // {
  //   props: { exact: true, path: '/checkoutadjustment/add' },
  //   component: <CheckoutAdjustmentAdd />,
  // },
  // {
  //   props: { exact: true, path: '/checkoutadjustment/view' },
  //   component: <CheckoutAdjustmentView />,
  // },
  // {
  //   props: { exact: true, path: '/checkoutadjustment/document' },
  //   component: <CheckoutAdjustmentDocument />,
  // },
  // {
  //   props: { exact: true, path: '/checkoutadjustment/folio' },
  //   component: <CheckoutAdjustmentFolio />,
  // },
]

export default CheckoutAdjustmentRoutes
