import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import {useBookingDetailsLazyQuery } from 'generated/graphql';
import { QRScannerDialog } from 'containers/QRScanner/QRScannerDialog';
import { SocketContext } from 'containers/Socket/SocketContext';

export const QRCheckWrapper = ({ children }) => {
  const [QrData, setQrData] = useState('')
  const [status, setStatus] = useState('')
  // const [socket, setSocket] = useState(null);
  const [openDueInScanDialog, setDueInScanDialog] = useState(false)

  const { socket}: any =
  useContext(SocketContext)


// //socket
//   useEffect(() => {
//     const newSocket = io(hotelxSocketUrl);
//     setSocket(newSocket);

//     // Cleanup function
//     return () => {
//       newSocket.disconnect();
//     };
//   }, []);
  useEffect(() => {
    if (socket) {
      socket.on('QR', async (QR) => { 
        console.log(QR, "adakah")
        setQrData(QR.QrData);
        setStatus(QR.Status);
        const parsedDeviceData = JSON.parse(QR.DeviceData);
      const macAddress = parsedDeviceData.MACAddress;


      fetch('http://localhost:7002/getdeviceData')
      .then(response => response.json())
      .then(data => {
        if (data?.MACAddress === macAddress && QR?.RegistrationStatus === 'Booked' && QR.HotelID === JSON.parse(localStorage.getItem('Hotel'))?.ID ) { // Compare with the correct variable
          setDueInScanDialog(true);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  });
  
      return () => {
        if (socket) {
          socket.off('QR');
        }
      };
    }
  }, [socket]);

 // separate QrData
  const separatedData =QrData.split('/');
  const HotelID = separatedData[0]
  const BookingID = separatedData[1]
  const RegistrationID = separatedData[2]

  const triggerDueInScanDialogClose = () => {
    setDueInScanDialog(false)
  }


  
const [
  BookingDetailsInfo,
  {
      data:
      { BookingDetails } = { BookingDetails: [] }
  }] = useBookingDetailsLazyQuery({fetchPolicy:'no-cache'})

  useEffect(() => {
    {
        if(HotelID && BookingID ){
          BookingDetailsInfo({
            variables: {
                ID: BookingID,
                HotelID: HotelID,
            },
         
        })
    }
    }
}, [socket])

  return (
    <>
  {children}
  <QRScannerDialog
  open={openDueInScanDialog}
  QrData = {QrData}
  close={triggerDueInScanDialogClose}
  BookingDetails = {BookingDetails}
sockets ={socket}
/>

</>
);
};