import React, { lazy } from 'react'

const ConsolidatedEInvoiceListing = lazy(() =>
  import('./ConsolidatedEInvoiceListing').then(module => ({
    default: module.ConsolidatedEInvoiceListing,
  }))
)

const ConsolidatedEInvoiceView = lazy(() =>
  import('./ConsolidatedEInvoiceView').then(module => ({
    default: module.ConsolidatedEInvoiceView,
  }))
)

const ConsolidatedEInvoiceForm = lazy(() =>
  import('./ConsolidatedEInvoiceForm').then(module => ({
    default: module.ConsolidatedEInvoiceForm,
  }))
)

const ConsolidateRoutes = [
  {
    props: { exact: true, path: '/consolidated'},
    component: <ConsolidatedEInvoiceListing />,
  },
  {
    props: { exact: true, path: '/consolidated/view' },
    component: <ConsolidatedEInvoiceView />,
  },
  {
    props: { exact: true, path: '/consolidated/add' },
    component: <ConsolidatedEInvoiceForm />,
  },
]

export default ConsolidateRoutes