import { InputAdornment, TextField } from '@material-ui/core'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import './phoneInput.scss'
// interface PhoneNumProps {
//   fullWidth?: string
//   props: any
//   inputProps: any
// }

export const PhoneNumInput = props => {
  const { fullWidth, helperText, error, currency, ...rest } = props

  const [isInvalid, setIsInvalid] = useState(false)

  return (
    <>
      <div
        style={{ padding: '4px 0px 10px 0px', width: '100%' }}
        className={`${
          isInvalid || error ? 'special-label-error' : null
        } phonefield__input`}
      >
        <PhoneInput
          i
          {...rest}
          containerClass="test"
          inputStyle={{
            border: 'none',
            borderBottom: '1px solid darkgrey',
            outline: 'none',
            borderRadius: 0,
          }}
          isValid={(value, country: any) => {
            let countryCode = country.iso2
            let isPhoneNumber = isValidPhoneNumber(
              value,
              countryCode?.toUpperCase()
            )

            if (value?.slice(country.dialCode.length)) {
              if (!isPhoneNumber) {
                setIsInvalid(true)
              } else {
                setIsInvalid(false)
              }
            } else {
              setIsInvalid(false)
              return true
            }
          }}
          style={{ width: fullWidth ? '100%' : null, zIndex: 2 }}
          //   inputStyle={{
          //     width: '50%',
          //   }}
          //   inputProps={
          //     {
          //       // style: { width: fullWidth ? '100%' : null },
          //       // className: 'phone-no-input',
          //     }
          //   }
        />
        {currency && (
          <div className="phonefield__input-adornment">
            <span className="phonefield__adornment-text">{currency}</span>
          </div>
        )}
      </div>
      {(error || isInvalid) && (
        <TextField
          InputProps={{ disableUnderline: true }}
          disabled
          variant="standard"
          helperText={isInvalid ? 'Invalid Phone Number' : helperText}
          error={isInvalid || error}
          style={{ marginTop: '-42px' }}
        />
      )}
    </>
  )
}
