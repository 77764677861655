import { ApolloLink, Observable } from 'apollo-boost'
import { ApolloClient } from 'apollo-client'
import { ApolloClient as UploadApolloClient } from 'apollo-client'
import { InMemoryCache as UploadInMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { getAccessToken, setAccessToken } from 'AccessToken'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwtDecode from 'jwt-decode'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { split } from 'apollo-link'
const apolloCache = new UploadInMemoryCache({
  addTypename: false,
})
export const cache = new InMemoryCache({
  addTypename: false,
})
/////////////////////////////////////////////////////////////////////////////////////////
// @account-node

//http://localhost:7000/hrx or https://hrx-dev-api.ifca.io/hrx
// export const hotelxNodeUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:8000/hotelx'
//     : 'https://hotelx-dev-api.ifca.io/hotelx'

// export const accountNodeRefreshUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:8000/refresh_token_hotel'
//     : 'https://hotelx-dev-api.ifca.io/hotelx'

export let hotelxNodeUrl
switch (process.env.REACT_APP_API_URL) {
  case 'imperial-build':
    hotelxNodeUrl = 'https://hotelxapi-imperial.hotelx.asia/hotelx'
    break
  case 'indo-build':
    hotelxNodeUrl = 'https://hotelxapi-id.hotelx.asia/hotelx'
    break
  case 'dev-build':
    hotelxNodeUrl = 'https://hotelx-dev-api.ifca.io/hotelx'
    break
  case 'prod-build':
    hotelxNodeUrl = 'https://hotelxapi.hotelx.asia/hotelx'
    break
  case 'hms1-prod-build':
    hotelxNodeUrl = 'https://hotelx1api.hotelx.asia/hotelx'
    break
  case 'hms2-prod-build':
    hotelxNodeUrl = 'https://hotelx2api.hotelx.asia/hotelx'
    break
  case 'hms3-prod-build':
    hotelxNodeUrl = 'https://hotelx3api.hotelx.asia/hotelx'
    break
  case 'promenade-build':
    hotelxNodeUrl = 'https://hotelxapi-promenade.hotelx.asia/hotelx'
    break
  case 'promenade-uat-build':
    hotelxNodeUrl = 'https://hotelxapi-promenade-uat.hotelx.asia/hotelx'
    break
  case 'uat-build':
    hotelxNodeUrl = 'https://hotelx-uat-api.ifca.io/hotelx'
    break
  case 'swan-uat-build':
    hotelxNodeUrl = 'https://hotelxswanapi-uat.hotelx.asia/hotelx'
    break
  default:
    hotelxNodeUrl = 'http://localhost:8000/hotelx'
    break
}

export let hotelxSocketUrl
switch (process.env.REACT_APP_API_URL) {
  case 'imperial-build':
    hotelxSocketUrl = 'https://hotelxapi-imperial.hotelx.asia'
    break
  case 'indo-build':
    hotelxSocketUrl = 'https://hotelxapi-id.hotelx.asia'
    break
  case 'dev-build':
    hotelxSocketUrl = 'https://hotelx-dev-api.ifca.io'
    break
  case 'prod-build':
    hotelxSocketUrl = 'https://hotelxapi.hotelx.asia'
    break
  case 'hms1-prod-build':
    hotelxSocketUrl = 'https://hotelx1api.hotelx.asia'
    break
  case 'hms2-prod-build':
    hotelxSocketUrl = 'https://hotelx2api.hotelx.asia'
    break
  case 'hms3-prod-build':
    hotelxSocketUrl = 'https://hotelx3api.hotelx.asia'
    break
  case 'promenade-build':
    hotelxSocketUrl = 'https://hotelxapi-promenade.hotelx.asia'
    break
  case 'promenade-uat-build':
    hotelxSocketUrl = 'https://hotelxapi-promenade-uat.hotelx.asia'
    break
  case 'uat-build':
    hotelxSocketUrl = 'https://hotelx-uat-api.ifca.io'
    break
  case 'swan-uat-build':
    hotelxSocketUrl = 'https://hotelxswanapi-uat.hotelx.asia'
    break
  default:
    hotelxSocketUrl = 'http://localhost:8000'
    break
}

let wsNodeUrl
switch (process.env.REACT_APP_API_URL) {
  case 'imperial-build':
    wsNodeUrl = 'wss://hotelxapi-imperial.hotelx.asia/hotelx'
    break
  case 'indo-build':
    wsNodeUrl = 'wss://hotelxapi-id.hotelx.asia/hotelx'
    break
  case 'dev-build':
    wsNodeUrl = 'wss://hotelx-dev-api.ifca.io/hotelx'
    break
  case 'prod-build':
    wsNodeUrl = 'wss://hotelxapi.hotelx.asia/hotelx'
    break
  case 'hms1-prod-build':
    wsNodeUrl = 'wss://hotelx1api.hotelx.asia/hotelx'
    break
  case 'hms2-prod-build':
    wsNodeUrl = 'wss://hotelx2api.hotelx.asia/hotelx'
    break
  case 'hms3-prod-build':
    wsNodeUrl = 'wss://hotelx3api.hotelx.asia/hotelx'
    break
  case 'promenade-build':
    wsNodeUrl = 'wss://hotelxapi-promenade.hotelx.asia'
    break
  case 'promenade-uat-build':
    wsNodeUrl = 'wss://hotelxapi-promenade-uat.hotelx.asia'
    break
  case 'uat-build':
    wsNodeUrl = 'wss://hotelx-uat-api.ifca.io/hotelx'
    break
  case 'swan-uat-build':
    wsNodeUrl = 'wss://hotelxswanapi-uat.hotelx.asia/hotelx'
    break
  default:
    wsNodeUrl = 'ws://localhost:8000/hotelx'
    break
}

export let GoogleAPIKey =
  process.env.REACT_APP_API_URL === 'prod-build' ||
  process.env.REACT_APP_API_URL === 'hms1-prod-build' ||
  process.env.REACT_APP_API_URL === 'hms2-prod-build' ||
  process.env.REACT_APP_API_URL === 'hms3-prod-build' ||
  process.env.REACT_APP_API_URL === 'promenade-build' ||
  process.env.REACT_APP_API_URL === 'indo-build' || 
  process.env.REACT_APP_API_URL === 'imperial-build'  
    ? 'AIzaSyDYu0jmtUpyzSOFRQ9R5xW_2g_djZtUh_E'
    : 'AIzaSyDYu0jmtUpyzSOFRQ9R5xW_2g_djZtUh_E'
export let PaymentUrl = ''
switch (process.env.REACT_APP_API_URL) {
  case 'imperial-build':
    PaymentUrl = 'https://imperial.hotelx.asia'
    break
  case 'dev-build':
    PaymentUrl = 'https://hotelx-dev.ifca.io'
    break
  case 'prod-build':
    PaymentUrl = 'https://hms.hotelx.asia'
    break
  case 'hms1-prod-build':
    PaymentUrl = 'https://hms1.hotelx.asia'
    break
  case 'hms2-prod-build':
    PaymentUrl = 'https://hms2.hotelx.asia'
    break
  case 'hms3-prod-build':
    PaymentUrl = 'https://hms3.hotelx.asia'
    break
  case 'promenade-build':
    PaymentUrl = 'https://promenade.hotelx.asia'
    break
  case 'promenade-uat-build':
    PaymentUrl = 'https://promenade-uat.hotelx.asia'
    break
  case 'uat-build':
    PaymentUrl = 'https://hotelx-uat.ifca.io'
    break
  case 'swan-uat-build':
    PaymentUrl = 'https://swan-uat.hotelx.asia'
    break
  default:
    PaymentUrl = 'http://localhost:3000'
    break
}
export const hotelXRefreshUrl =
process.env.REACT_APP_API_URL === 'imperial-build'
    ? 'https://hotelxapi-imperial.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'indo-build'
    ? 'https://hotelxapi-id.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hotelx-dev-api.ifca.io/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'prod-build'
    ? 'https://hotelxapi.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'hms1-prod-build'
    ? 'https://hotelx1api.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'hms2-prod-build'
    ? 'https://hotelx2api.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'hms3-prod-build'
    ? 'https://hotelx3api.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'promenade-build'
    ? 'https://hotelxapi-promenade.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'promenade-uat-build'
    ? 'https://hotelxapi-promenade-uat.hotelx.asia/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hotelx-uat-api.ifca.io/refresh_token_hotel'
    : process.env.REACT_APP_API_URL === 'swan-uat-build'
    ? 'https://hotelxswanapi-uat.hotelx.asia/refresh_token_hotel'
    : 'http://localhost:8000/refresh_token_hotel'

export const ReportURL = // 'http://localhost:61094'
process.env.REACT_APP_API_URL === 'imperial-build'
    ? 'https://hotelxreporting-imperial.hotelx.asia/'
    : process.env.REACT_APP_API_URL === 'indo-build'
    ? 'https://hotelxreporting-id.hotelx.asia/'
    : process.env.REACT_APP_API_URL === 'promenade-build'
    ? 'https://hotelxreporting-promenade.hotelx.asia/'
    : process.env.REACT_APP_API_URL === 'promenade-uat-build'
    ? 'https://hotelxreporting-promenade-uat.hotelx.asia/'
    : process.env.REACT_APP_API_URL === 'dev-build'
    ? 'https://hotelxdevreporting.ifca.asia/'
    : process.env.REACT_APP_API_URL === 'uat-build'
    ? 'https://hotelxuatreporting.ifca.asia/'
    : process.env.REACT_APP_API_URL === 'swan-uat-build'
    ? 'https://hotelxuatreporting.ifca.asia/'
    : process.env.REACT_APP_API_URL === 'prod-build' ||
      process.env.REACT_APP_API_URL === 'hms1-prod-build' ||
      process.env.REACT_APP_API_URL === 'hms2-prod-build' ||
      process.env.REACT_APP_API_URL === 'hms3-prod-build'
    ? 'https://hotelxreporting.hotelx.asia/'
    : 'http://localhost:61094'

export const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

export const uploadLink = createUploadLink({
  uri: hotelxNodeUrl,
  credentials: 'include',
})
const wsLink = new WebSocketLink({
  uri: wsNodeUrl,
  options: {
    reconnect: true,
  },
})
const httpLink = new HttpLink({
  uri: hotelxNodeUrl,
  credentials: 'include',
})
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)
export const WSclient = new ApolloClient({
  cache: cache,
  link: splitLink,
})

export const Uploadclient = new UploadApolloClient({
  cache: apolloCache,
  link: ApolloLink.from([requestLink, uploadLink]),
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken()

        if (!token) {
          return true
        }

        try {
          const { exp } = jwtDecode(token)
          if (Date.now() >= exp * 1000) {
            return false
          } else {
            return true
          }
        } catch (err) {
          console.log(err)
          return false
        }
      },
      fetchAccessToken: () => {
        return fetch(hotelXRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        })
      },
      handleFetch: accessToken => {
        setAccessToken(accessToken)
      },
      handleError: err => {
        console.warn('Your refresh token is invalid. Try to relogin')
        console.error(err)
      },
    }),
    onError(() => {}),
    requestLink,
    splitLink,
    // new HttpLink({
    //   uri: hotelxNodeUrl,
    //   credentials: 'include',
    // }), //new HttpLink ends here
  ]),
  cache,
})

// export const client = new ApolloClient({
//   cache: new InMemoryCache({
//     addTypename: false,
//   }),
//   uri: hotelxNodeUrl,
//   request: operation => {
//     // const token = localStorage.getItem('token')
//     const accessToken = getAccessToken()
//     operation.setContext({
//       headers: {
//         'keep-alive': 'true',
//         authorization: `bearer ${accessToken}`,
//       },
//     })
//   },
//   onError: error => {
//     error?.graphQLErrors?.map(({ message }) => {
//       console.log('message', message)
//       if (
//         message ===
//         'Context creation failed: Authentication token is invalid, please log in'
//       ) {
//         // browserHistory.push('/login', {})
//       }
//     })
//   },
// })
