import React from 'react'
import empty from '../../assets/icons/empty.svg'
interface IEmptyListProps {
  title?: string
  subtitle?: string
}
const EmptyList = (props: IEmptyListProps) => {
  const { title, subtitle } = props
  return (
    <div className="empty-listing">
      {/* <img src={empty} alt="empty-listing" width={300} /> */}

      <div className="smTitle">{title}</div>

      <div className="desc">{subtitle}</div>
    </div>
  )
}
export default EmptyList
