import React, { lazy } from 'react'
import { RateCategory } from 'generated/graphql'
import {
  CorpRateEffectiveAddPath,
  CorpRateEffectiveEditPath,
  CorpRateEffectivePath,
  CorpRateSetup,
} from 'containers/SettingsModule/RatePolicyV2/RateSetup/RateHelper'
const RatePolicySetup = lazy(() =>
  import('./RatePolicySetup').then(module => ({
    default: module.RatePolicySetup,
  }))
)

const RatePolicySetupForm = lazy(() =>
  import('./RatePolicySetupForm').then(module => ({
    default: module.RatePolicySetupForm,
  }))
)
const RateSetup = lazy(() =>
  import('../../SettingsModule/RatePolicyV2/RateSetup/RateSetup').then(
    module => ({
      default: module.RateSetup,
    })
  )
)
const RateEffective = lazy(() =>
  import('../../SettingsModule/RatePolicyV2/RateSetup/RateEffective').then(
    module => ({
      default: module.RateEffective,
    })
  )
)
const RateEffectiveForm = lazy(() =>
  import('../../SettingsModule/RatePolicyV2/RateSetup/RateEffectiveForm').then(
    module => ({
      default: module.RateEffectiveForm,
    })
  )
)
const ratePolicyRoutes = [
  {
    props: { exact: true, path: '/corporate/ratepolicysetup' },
    component: <RatePolicySetup />,
  },

  {
    props: { exact: true, path: '/corporate/ratepolicysetup/add' },
    component: <RatePolicySetupForm mode="Add" />,
  },

  {
    props: { exact: true, path: '/corporate/ratepolicysetup/edit' },
    component: <RatePolicySetupForm mode="Edit" />,
  },
  //! New Rate Stup
  {
    props: { exact: true, path: CorpRateSetup },
    component: <RateSetup rateCategoryMode={RateCategory['Corporate']} />,
  },

  {
    props: { exact: true, path: CorpRateEffectivePath },
    component: <RateEffective rateCategoryMode={RateCategory['Corporate']} />,
  },
  {
    props: { exact: true, path: CorpRateEffectiveAddPath },
    component: (
      <RateEffectiveForm
        mode="Add"
        rateCategoryMode={RateCategory['Corporate']}
      />
    ),
  },
  {
    props: { exact: true, path: CorpRateEffectiveEditPath },
    component: (
      <RateEffectiveForm
        mode="Edit"
        rateCategoryMode={RateCategory['Corporate']}
      />
    ),
  },
]

export default ratePolicyRoutes
