import React, { lazy } from 'react'

const ChangeRoom = lazy(() =>
  import('./ChangeRoom').then(module => ({
    default: module.ChangeRoom,
  }))
)

export const GCChangeRoomURL = `/frontdesk/groupcheckin/checkin/changeroom`

export const ChangeRoomRoutes = [
  {
    props: { exact: true, path: GCChangeRoomURL },
    component: <ChangeRoom />,
  },
]
