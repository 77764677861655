// Rate setup page
export const PublicRateSetup = '/settings/ratepolicy/ratesetup'
export const TravelAgentRateSetup = '/travelagent/ratepolicy/ratesetup'
export const CorpRateSetup = '/corporate/ratepolicy/ratesetup'

//Back path for rate setup
export const RateSetupPreviousPath = {
  Public: '/settings/ratepolicy',
  TravelAgent: '/travelagent',
  Corporate: '/corporate',
}
export const handleRateSetupBackButton = (rateCategory: string) =>
  RateSetupPreviousPath[rateCategory] || ''

//Rate Effective page
export const PublicRateEffectivePath = '/settings/ratepolicy/ratesetup/code'
export const TravelAgentRateEffectivePath =
  '/travelagent/ratepolicy/ratesetup/code'
export const CorpRateEffectivePath = '/corporate/ratepolicy/ratesetup/code'

export const RateEffectivePath = {
  Public: PublicRateEffectivePath,
  TravelAgent: TravelAgentRateEffectivePath,
  Corporate: CorpRateEffectivePath,
}
export const handleRateEffectiveRedirect = (rateCategory: string) =>
  RateEffectivePath[rateCategory] || ''

//Back Path for rateEffect

export const RateEffectivePreviousPath = {
  Public: PublicRateSetup,
  TravelAgent: TravelAgentRateSetup,
  Corporate: CorpRateSetup,
}

export const handleRateEffectiveBackButton = (rateCategory: string) =>
  RateEffectivePreviousPath[rateCategory] || ''

//! add and edit path for rate effective

export const PublicRateEffectiveAddPath =
  '/settings/ratepolicy/ratesetup/code/add'
export const TravelAgentRateEffectiveAddPath =
  '/travelagent/ratepolicy/ratesetup/code/add'
export const CorpRateEffectiveAddPath =
  '/corporate/ratepolicy/ratesetup/code/add'
// edit
export const PublicRateEffectiveEditPath =
  '/settings/ratepolicy/ratesetup/code/edit'
export const TravelAgentRateEffectiveEditPath =
  '/travelagent/ratepolicy/ratesetup/code/edit'
export const CorpRateEffectiveEditPath =
  '/corporate/ratepolicy/ratesetup/code/edit'

export const RateEffectiveAddPath = {
  Public: PublicRateEffectiveAddPath,
  TravelAgent: TravelAgentRateEffectiveAddPath,
  Corporate: CorpRateEffectiveAddPath,
}

export const RateEffectiveEditPath = {
  Public: PublicRateEffectiveEditPath,
  TravelAgent: TravelAgentRateEffectiveEditPath,
  Corporate: CorpRateEffectiveEditPath,
}

export const handleRateEffectiveAddButton = (rateCategory: string) =>
  RateEffectiveAddPath[rateCategory] || ''

export const handleRateEffectiveEditButton = (rateCategory: string) =>
  RateEffectiveEditPath[rateCategory] || ''

export const RateEffectiveFormPreviousPath = {
  Public: PublicRateEffectivePath,
  TravelAgent: TravelAgentRateEffectivePath,
  Corporate: CorpRateEffectivePath,
}

export const handleRateEffectiveFormButton = (rateCategory: string) =>
  RateEffectiveFormPreviousPath[rateCategory] || ''
