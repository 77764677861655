import React, { lazy } from 'react'

const Q3FInterface = lazy(() => 
  import('./Q3FInterface').then(module => ({
    default: module.Q3FInterface,
  }))
)

const Q3FMapping = lazy(() => 
  import('./Q3FMapping/Q3FMapping').then(module => ({
    default: module.Q3FMapping,
  }))
)

const Q3FExportListing = lazy(() => 
  import('./Q3FDocumentExport/Q3FExportListing').then(module => ({
    default: module.Q3FExportListing,
  }))
)

const Q3FExportForm= lazy(() => 
  import('./Q3FDocumentExport/Q3FExportForm').then(module => ({
    default: module.Q3FExportForm,
  }))
)

const Q3FExportDetail= lazy(() => 
  import('./Q3FDocumentExport/Q3FExportDetail').then(module => ({
    default: module.Q3FExportDetail,
  }))
)

const Q3FInterfaceRoutes = [
  {
    props: { exact: true, path: '/q3f-interface' },
    component: <Q3FInterface />, 
  },
  {
    props: { exact: true, path: '/q3f-interface/revenue' },
    component: <Q3FExportListing mode={'Revenue'}/>, 
  },
  {
    props: { exact: true, path: '/q3f-interface/market-segment' },
    component: <Q3FExportListing mode={'Market Segment'}/>, 
  },
  {
    props: { exact: true, path: '/q3f-interface/statistic' },
    component: <Q3FExportListing mode={'Statistic'}/>, 
  },
  {
    props: { exact: true, path: '/q3f-interface/mapping/revenue' },
    component: <Q3FMapping mode={'Revenue'}/>, 
  },
  {
    props: { exact: true, path: '/q3f-interface/mapping/market-segment' },
    component: <Q3FMapping mode={'Market Segment'}/>, 
  },
  {
    props: { exact: true, path: '/q3f-interface/mapping/statistic' },
    component: <Q3FMapping mode={'Statistic'}/>, 
  },
  {
    props: { exact: true, path: '/q3f-interface/revenue/new' },
    component: <Q3FExportForm mode={'Revenue'} />, 
  },
  {
    props: { exact: true, path: '/q3f-interface/market-segment/new' },
    component: <Q3FExportForm mode={'Market Segment'} />, 
  },
  {
    props: { exact: true, path: '/q3f-interface/statistic/new' },
    component: <Q3FExportForm mode={'Statistic'} />, 
  },
  {
    props: { exact: true, path: '/q3f-interface/revenue/detail' },
    component: <Q3FExportDetail mode={'Revenue'} />, 
  },
  {
    props: { exact: true, path: '/q3f-interface/market-segment/detail' },
    component: <Q3FExportDetail mode={'Market Segment'} />, 
  },
  {
    props: { exact: true, path: '/q3f-interface/statistic/detail' },
    component: <Q3FExportDetail mode={'Statistic'} />, 
  },
]

export default Q3FInterfaceRoutes