import React, { lazy } from 'react'

const LateCheckOutCharges = lazy(() =>
  import('./LateCheckOutCharges').then(module => ({
    default: module.LateCheckOutCharges,
  }))
)

const lateCheckOutChargesRoutes = [
  {
    props: { exact: true, path: '/settings/latecheckoutcharges' },
    component: <LateCheckOutCharges />,
  },
]

export default lateCheckOutChargesRoutes
