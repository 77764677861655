import React, { lazy } from 'react'

const BlockRoomForm = lazy(() =>
  import('./BlockRoomForm').then(module => ({
    default: module.BlockRoomForm,
  }))
)

const MiscChargesForm = lazy(() =>
  import('./MiscChargesForm').then(module => ({
    default: module.MiscChargesForm,
  }))
)


const RoomStatusForm = lazy(() =>
  import('./RoomStatusForm').then(module => ({
    default: module.RoomStatusForm,
  }))
)

const ReleaseRoomForm = lazy(() =>
  import('./ReleaseRoomForm').then(module => ({
    default: module.ReleaseRoomForm,
  }))
)

const ServiceRequest = lazy(() =>
  import('./ServiceRequest').then(module => ({
    default: module.ServiceRequest,
  }))
)

const ServiceRequestForm = lazy(() =>
  import('./ServiceRequestForm').then(module => ({
    default: module.ServiceRequestForm,
  }))
)

const ServiceRequestDetail = lazy(() =>
  import('./ServiceRequestDetail').then(module => ({
    default: module.ServiceRequestDetail,
  }))
)


const HouseKeepingDetailRoutes = [
  {
    props: { exact: true, path: '/housekeeping/room/blockform' },
    component: <BlockRoomForm />,
  },

  {
    props: { exact: true, path: '/housekeeping/room/miscchargesform' },
    component: <MiscChargesForm />,
  },

  {
    props: { exact: true, path: '/housekeeping/room/releaseform' },
    component: <ReleaseRoomForm />,
  },
  {
    props: { exact: true, path: '/housekeeping/room/roomstatusform' },
    component: <RoomStatusForm />,
  },
  {
    props: { exact: true, path: '/housekeeping/servicerequest' },
    component: <ServiceRequest />,
  },

  {
    props: { exact: true, path: '/housekeeping/room/servicerequest/add' },
    component: <ServiceRequestForm mode="Add" />,
  },

  {
    props: { exact: true, path: '/housekeeping/room/servicerequest/edit' },
    component: <ServiceRequestForm mode="Edit" />,
  },
  {
    props: { exact: true, path: '/housekeeping/servicerequest/view' },
    component: <ServiceRequestDetail />,
  },
]

export default HouseKeepingDetailRoutes
