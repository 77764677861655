import { useState, useEffect } from 'react'
import localForage from 'localforage'
import {
  HotelPermission,
  useLoggedInUserProfileQuery,
  useGetHotelPermissionQuery,
  useGetRolePermissionQuery,
} from 'generated/graphql'
/**
 * @param handlePermDisabled : {hotelID: '' | hotelID , permEnum: HotelPermission.DashboardView}
 * @param handlePermHidden : {hotelID: '' | hotelID , permEnum: HotelPermission.DashboardView}
 * @param permission : general permission list
 * @param hotelPermission : hotel permission list
 */
interface PermProps {
  hotelID?: string
  permEnum: HotelPermission
}
export const usePermissionChecker = () => {
  const [permission, setPermMatch] = useState({})
  const [hotelPermission, setHotelPerm] = useState({})
  // const user = JSON.parse(localStorage.getItem('LoggedInUser'))
  const {
    data: { loggedInUserProfile } = { loggedInUserProfile: null },
  } = useLoggedInUserProfileQuery({})
  useEffect(() => {
    localForage?.getItem('permission', function (err, value) {
      setPermMatch(value)
      // console.log(value);
    })
    localForage?.getItem('HotelPermission', function (err, value) {
      setHotelPerm(value)
      // console.log(value);
    })
  }, [])

  // it will return true or false
  const handlePermDisabled = ({ hotelID, permEnum }: PermProps) => {
    return hotelID || null
      ? hotelPermission[hotelID]?.hasOwnProperty(permEnum) === false &&
          !loggedInUserProfile?.superUser
      : permission?.hasOwnProperty(permEnum) === false &&
          !loggedInUserProfile?.superUser
  }

  const handlePermHidden = ({ hotelID, permEnum }: PermProps) => {
    return hotelPermission && hotelID
      ? hotelPermission[hotelID || null]?.hasOwnProperty(permEnum) ||
          loggedInUserProfile?.superUser
      : permission?.hasOwnProperty(permEnum) || loggedInUserProfile?.superUser
    // return hotelID || ''
    //   ? hotelPermission[hotelID]?.hasOwnProperty(permEnum) || user?.superUser
    //   : permission?.hasOwnProperty(permEnum) || user?.superUser
  }
  return {
    permission,
    hotelPermission,
    handlePermDisabled,
    handlePermHidden,
  }
}
