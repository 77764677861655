import { RateCategory } from 'generated/graphql'
import React, { lazy } from 'react'
import {
  PublicRateEffectiveAddPath,
  PublicRateEffectiveEditPath,
  PublicRateEffectivePath,
  PublicRateSetup,
} from './RateSetup/RateHelper'

const RatePolicy = lazy(() =>
  import('./RatePolicy').then(module => ({
    default: module.RatePolicy,
  }))
)
const SeasonSetup = lazy(() =>
  import('./SeasonSetup').then(module => ({
    default: module.SeasonSetup,
  }))
)
const SeasonCalendar = lazy(() =>
  import('./SeasonCalendar').then(module => ({
    default: module.SeasonCalendar,
  }))
)
const RateElement = lazy(() =>
  import('./RateElement').then(module => ({
    default: module.RateElement,
  }))
)
const RateType = lazy(() =>
  import('./RateType').then(module => ({
    default: module.RateType,
  }))
)
const AddOnSetup = lazy(() =>
  import('./AddOnSetup').then(module => ({
    default: module.AddOnSetup,
  }))
)

const RateSetup = lazy(() =>
  import('./RateSetup/RateSetup').then(module => ({
    default: module.RateSetup,
  }))
)
const RateEffective = lazy(() =>
  import('./RateSetup/RateEffective').then(module => ({
    default: module.RateEffective,
  }))
)
const RateEffectiveForm = lazy(() =>
  import('./RateSetup/RateEffectiveForm').then(module => ({
    default: module.RateEffectiveForm,
  }))
)
const ratePolicyV2Routes = [
  {
    props: { exact: true, path: '/settings/ratepolicy' },
    component: <RatePolicy />,
  },
  {
    props: { exact: true, path: '/settings/ratepolicy/season' },
    component: <SeasonSetup />,
  },
  {
    props: { exact: true, path: '/settings/ratepolicy/seasoncalendar' },
    component: <SeasonCalendar />,
  },
  {
    props: { exact: true, path: '/settings/ratepolicy/rateelement' },
    component: <RateElement />,
  },
  {
    props: { exact: true, path: '/settings/ratepolicy/addon' },
    component: <AddOnSetup />,
  },
  {
    props: { exact: true, path: '/settings/ratepolicy/ratetype' },
    component: <RateType />,
  },
  {
    props: { exact: true, path: PublicRateSetup },
    component: <RateSetup rateCategoryMode={RateCategory['Public']} />,
  },
  {
    props: { exact: true, path: PublicRateEffectivePath },
    component: <RateEffective rateCategoryMode={RateCategory['Public']} />,
  },
  {
    props: { exact: true, path: PublicRateEffectiveAddPath },
    component: (
      <RateEffectiveForm mode="Add" rateCategoryMode={RateCategory['Public']} />
    ),
  },
  {
    props: { exact: true, path: PublicRateEffectiveEditPath },
    component: (
      <RateEffectiveForm
        mode="Edit"
        rateCategoryMode={RateCategory['Public']}
      />
    ),
  },
]

export default ratePolicyV2Routes
