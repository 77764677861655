import React, { lazy } from 'react'

const RoomService = lazy(() =>
  import('./RoomService').then(module => ({
    default: module.RoomService,
  }))
)
const RoomServiceRoom = lazy(() =>
  import('./RoomServiceRoom').then(module => ({
    default: module.RoomServiceRoom,
  }))
)
const DeliveredRoomServiceRoom = lazy(() =>
  import('./DeliveredRoomServiceRoom').then(module => ({
    default: module.DeliveredRoomServiceRoom,
  }))
)
const RoomServiceForm = lazy(() =>
  import('./RoomServiceForm').then(module => ({
    default: module.RoomServicesForm,
  }))
)
const ConfirmationOrder = lazy(() =>
  import('./ConfirmOrder').then(module => ({
    default: module.ConfirmOrder,
  }))
)

const RoomServicePaymentDocument = lazy(() =>
  import('./RoomServicePaymentDocument').then(module => ({
    default: module.RoomServicePaymentDocument,
  }))
)

const RoomServiceRoutes = [
  {
    props: { exact: true, path: '/roomservice' },
    component: <RoomService />,
  },
  {
    props: { exact: true, path: '/roomservice/room' },
    component: <RoomServiceRoom />,
  },
  {
    props: { exact: true, path: '/roomservice/deliveredroom' },
    component: <DeliveredRoomServiceRoom />,
  },
  {
    props: { exact: true, path: '/roomservice/add' },
    component: <RoomServiceForm />,
  },
  {
    props: { exact: true, path: '/roomservice/confirmorder' },
    component: <ConfirmationOrder />,
  },
  {
    props: {
      exact: true,
      path: '/roomservice/confirmorder/payment/redirect/:status',
    },
    component: <RoomServicePaymentDocument />,
  },
]

export default RoomServiceRoutes
