import React, { lazy } from 'react'

const ScheduleBillingListing = lazy(() =>
  import('./ScheduleBillingListing').then(module => ({
    default: module.ScheduleBillingListing,
  }))
)

const ScheduleBillingEdit = lazy(() =>
  import('./ScheduleBillingEdit').then(module => ({
    default: module.ScheduleBillingEdit,
  }))
)

const ScheduleListingRoutes = [
  {
    props: { exact: true, path: '/schedule-billing' },
    component: <ScheduleBillingListing />,
  },
  {
    props: { exact: true, path: '/schedule-billing/edit' },
    component: <ScheduleBillingEdit />,
  },
]

export default ScheduleListingRoutes
