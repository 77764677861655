import React, { lazy } from 'react'
// import ratePolicyRoutes from './RatePolicySetup/RatePolicySetupRoutes'

const PackageRedemptionList = lazy(() =>
  import('./PackageRedemptionList').then(module => ({
    default: module.PackageRedemptionList,
  }))
)
const PackageRedeem = lazy(() =>
  import('./PackageRedeem').then(module => ({
    default: module.PackageRedeem,
  }))
)
const PackageRedemptionRoutes = [
  {
    props: { exact: true, path: '/packageredemption' },
    component: <PackageRedemptionList />,
  },
  {
    props: { exact: true, path: '/packageredemption/redeem' },
    component: <PackageRedeem />,
  },
]

export default PackageRedemptionRoutes
