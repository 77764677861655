import { CopyRight } from '@ifca-root/react-component/src/components/AuthLayout/CopyRight'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { setAccessToken } from 'AccessToken'
import Background from 'assets/images/HR-Background.jpg'
import Logo from 'assets/images/HotelX-Logo.png'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import { encryptMessage } from 'containers/helper/Crypto/JsEncrypt'
import {
  LoggedInUserProfileDocument,
  LoggedInUserProfileQuery,
  useLoginMutation,
  useHotelListingLazyQuery,
  useUpdateLastestAccessHotelLazyQuery,
  useIsQ3FInterfaceLazyQuery,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import PhoneInput from 'react-phone-input-2'
import { usePhoneValidation } from 'containers/helper/hooks/usePhoneValidation'
const dateFormat = require('dateformat')

interface ILoginForm {
  email: string
  password: string
  contactNo: string
  showPassword: boolean
  product: string
}
export const Login = props => {
  // const [socket, setSocket] = useState(null);
  // localStorage.clear()
  let history = useHistory()
  
  const [
    IsQ3FInterfaceQuery,
    {
      data: { IsQ3FInterface } = { IsQ3FInterface: [] },
    }
  ]
   = useIsQ3FInterfaceLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      localStorage.setItem('IsQ3FInterface', JSON.stringify(data?.IsQ3FInterface))
    }
  })
  
  const [
    HotelData,
    { called, data: { HotelListing } = { HotelListing: [] } },
  ] = useHotelListingLazyQuery({
    onCompleted(hdata) {
      localStorage.setItem('LoggedInUser', JSON.stringify(data.login.user))
      // handle user without no hotel/firstime 
      if(hdata.HotelListing.length === 0){

        history.push('/homeselecthotel')

      }
      else{
     // handle user has hotel with accessed before
        if (data.login.user.lastestAccessedEntity !== null) {
          history.push('/home')
        } 
        // handle user has hotel without accessed before /firstime 
        else {
          history.push('/homeselecthotel')
        }

      }
     
    },
    onError: error => {
      // handle user without hotel and non superuser
      error.graphQLErrors.map(({ message }) => {
        localStorage.setItem('LoggedInUser', JSON.stringify(data.login.user))
        history.push('/homeselecthotel')
      })
    },
  })
  const [
    UpdateLastest,
    {
      loading: updateloading,
      called: calledLastest,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data: { updateLastestAccessHotel } = { updateLastestAccessHotel: null },
    },
  ] = useUpdateLastestAccessHotelLazyQuery()

  const [login, { data, loading, error }] = useLoginMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    update: (store, { data }) => {
      if (!data) {
        return null
      }

      store.writeQuery<LoggedInUserProfileQuery>({
        query: LoggedInUserProfileDocument,
        data: {
          __typename: 'Query',
          loggedInUserProfile: data.login.user as any,
        },
      })
    },
    onCompleted: data => {
      if (data && data.login.accessToken) {
        setAccessToken(data.login.accessToken)
        HotelData({
          variables: {
            AccountID: data.login.user.accountID,
          }
        })
        IsQ3FInterfaceQuery({
          variables: {
            HotelID: data.login.user.lastestAccessedEntity,
            UserID: data.login.user.ID,
          }
        })
      }
    

    },
  })
useEffect(()=>{

  if (updateLastestAccessHotel !== null) {
    localStorage.setItem('LoggedInUser', JSON.stringify(updateLastestAccessHotel))
    history.push('/home')
  }

},[updateLastestAccessHotel !== null])

useEffect(()=>{

  if(HotelListing.length > 0){
    UpdateLastest({
    
      variables: {
        userIDs: data.login.user?.ID,
        hotelID: data.login.user.lastestAccessedEntity !== null ? data.login.user.lastestAccessedEntity : HotelListing.find(x => x.AccountID === data.login.user.accountID).ID,
      }
    })
    
         
    
        }

},[HotelListing])
  //const [values, setValues] = React.useState(false)
  const [values, setValues] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [checkBox, setCheck] = useState(false)
  const [message, setMessage] = useState('')
  const [IsMobileNo, setIsMobileNo] = useState(false)

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/profile')
  }
  const MESSAGE = 'Password Changed Successfully'
  const password = document.querySelector('#Password')

  const handleClickShowPassword = () => {
    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password'
    password.setAttribute('type', type)
  }

  // const handleMouseDownPassword = event => {
  //   event.preventDefault()
  // }
  // const hash = encryptMessage('abc123')
  // console.log(hash, 'Encrption')

  useEffect(() => {
    localStorage.getItem('email') !== undefined &&
      login({
        variables: {
          loginId: localStorage.getItem('email'),
          //softwareCode: SoftwareCode.Hr,
          password: localStorage.getItem('password'),
          // email: data?.email,
          // softwareID: data?.product,
        },
      })
  }, [])

  useEffect(() => {
    localStorage.clear()
  }, [])
  // console.log(decryptMessage(hash), 'Decrpt')
  // const handleClickShowPassword = () => {
  //   setValues(!values)
  // }

  // const handleMouseDownPassword = event => {
  //   event.preventDefault()
  // }

  //useForm set DefaultValues, validationSchema
  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    reset,
  } = useForm<ILoginForm>({
    // defaultValues: {
    //   email: '',
    //   password: '',
    // },
    // resolver: yupResolver(LoginSchema) as any,
  })
  console.log(errors)
  const { isValidPoneNumber, validatePhonePrefix } = usePhoneValidation()

  const onSubmit = data => {
    if (checkBox === true) {
      localStorage.setItem('email', data?.email)
      localStorage.setItem('password', encryptMessage(data?.password) || '')
    }
    login({
      variables: {
        loginId: IsMobileNo ? validatePhonePrefix(data.contactNo) : data?.email,
        //softwareCode: SoftwareCode.Hr,
        password: encryptMessage(data?.password) || '',
        // email: data?.email,
        // softwareID: data?.product,
      },
    })

    // console.log(data.product, 'dddd')
  }

  const checked = () => {
    setCheck(!checkBox)
  }
  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Login Error',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout logo={Logo} image={Background}>
        <span className="page-title">Login</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            {IsMobileNo ? (
              <Controller
                as={
                  <PhoneInput
                    containerClass="contact-input-auth"
                    inputProps={{
                      name: 'Mobile No.',
                      required: true,
                    }}
                    specialLabel="Mobile No. *"
                    country={'my'} //Country prefix
                  />
                }
                name="contactNo"
                label="Mobile No."
                type="tel"
                fullWidth
                helperText={errors?.contactNo?.message}
                error={errors?.contactNo ? true : false}
                margin="normal"
                control={control}
              />
            ) : (
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.email?.message}
                error={errors?.email ? true : false}
                autoComplete="off"
                name="email"
                label="Login ID / Email"
                control={control}
                ref={register}
              />
            )}
            <Grid container justify="flex-start">
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.password?.message}
                error={errors?.password ? true : false}
                autoComplete="off"
                name="password"
                id="Password"
                label="Password "
                type={'password'}
                value={getValues('password')}
                // onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {values ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                control={control}
                ref={register}
              />
            </Grid>
            <div
              className=" color-orange click-text"
              style={{
                textAlign: 'center',
                // color: '#ffffff8a',
              }}
              onClick={() => setIsMobileNo(!IsMobileNo)}
            >
              <span className="desc m-r-4">
                Login with {IsMobileNo ? 'Login ID / Email' : 'Mobile No'}
              </span>
            </div>
            <div className="other-auth">
              <FormControlLabel
                onChange={checked}
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    checked={checkBox}
                  />
                }
                label="Remember Me"
              />
              <span
                className="forget-link"
                onClick={() => history.push('/forgot-password')}
              >
                Forget Password?
              </span>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
              // onClick={() => history.push('/')}
            >
              Login
            </Button>
          </div>
        </form>

        <CopyRight />
      </AuthLayout>
    </>
  )
}
