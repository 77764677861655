import React, { lazy } from 'react'

const DebtorType = lazy(() =>
  import('./DebtorType').then(module => ({
    default: module.DebtorType,
  }))
)

const debtorTypeRoutes = [
  {
    props: { exact: true, path: '/cityledger/setup/debtortype' },
    component: <DebtorType />,
  },
]

export default debtorTypeRoutes
