import React, { lazy } from 'react'
const Segment = lazy(() =>
  import('./Segment').then(module => ({
    default: module.Segment,
  }))
)

const segmentRoutes = [
  {
    props: { exact: true, path: '/settings/segment' },
    component: <Segment />,
  },
]

export default segmentRoutes
