import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'
const WalkIn = lazy(() =>
  import('./WalkIn').then(module => ({
    default: module.WalkIn,
  }))
)
const WalkInForm = lazy(() =>
  import('./WalkInForm').then(module => ({
    default: module.WalkInForm,
  }))
)
const CheckInForm = lazy(() =>
  import('../DueIn/CheckInForm').then(module => ({
    default: module.CheckInForm,
  }))
)
const EditWalkIn = lazy(() =>
  import('./EditWalkIn').then(module => ({
    default: module.EditWalkIn,
  }))
)

const Checkin = lazy(() =>
  import('./CheckIn').then(module => ({
    default: module.CheckIn,
  }))
)

const CheckInFormregcard = lazy(() =>
  import('./CheckInForm').then(module => ({
    default: module.CheckInForm,
  }))
)
export const walkInUrl = `/frontdesk/walkin`
export const walkInFormUrl = `/frontdesk/walkin/add`
export const walkinEditGuestUrl = `/frontdesk/walkin/edit`
export const walkInCheckInUrl = `/frontdesk/walkin/add/assign/checkin`
export const walkInCheckIn = `/frontdesk/walkin/checkin`
export const walkInERegcard = `/frontdesk/walkin/eregcard`

export const WalkInRoutes = [
  {
    props: { exact: true, path: walkInUrl },
    component: <WalkIn />,
  },
  {
    props: { exact: true, path: walkInFormUrl },
    component: <WalkInForm />,
  },
  {
    props: { exact: true, path: walkInFormUrl },
    component: <WalkInForm />,
  },
  {
    props: { exact: true, path: walkinEditGuestUrl },
    component: <EditWalkIn />,
  },
  {
    props: { exact: true, path: walkInERegcard },
    component: <CheckInFormregcard  mode={FrontDeskType.WalkIn}/>,
  },
  {
    props: { exact: true, path: walkInCheckInUrl },
    component: <CheckInForm mode={FrontDeskType.WalkIn} />,
  },
  {
    props: { exact: true, path: walkInCheckIn },
    component: <Checkin mode={FrontDeskType.WalkIn}/>,
  },
]
