import React, { useEffect, useState } from 'react'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { ListItem, ListItemText, TextField } from '@material-ui/core'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import {
  useBookingAttachmentInsertMutation,
  useBookingAttachmentUpdateMutation,
} from 'generated/graphql'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'

interface Props {
  open: boolean
  dispatch: any
  state: any
  bookingInfo?: any
  mode: any
  menu?: any
  refetch: () => void
}

export const AttachmentsDialog = ({
  open,
  dispatch,
  state,
  mode,
  bookingInfo,
  refetch,
}: Props) => {
  const [existFile, setExistFile] = useState([])
  const { snackFunc, close, snackBarMessage, openSnackBar } = useSnackBar()
  const [BookingAttachmentInsert, { loading: insertLoading }] =
    useBookingAttachmentInsertMutation({
      onError: error => {
        error.graphQLErrors.map(({ message }) => {
          snackFunc(message, false)
          dispatch({ type: 'addDialog', payload: false })
          dispatch({ type: 'reset', payload: {} })
          setPreviewFiles([])
          setFiles([])
        })
      },
      onCompleted: data => {
        if (data.BookingAttachmentInsert === true) {
          snackFunc('Successfully insert attachments', false)
        }
        refetch()
        dispatch({ type: 'addDialog', payload: false })
        dispatch({ type: 'reset', payload: {} })
        setPreviewFiles([])
        setFiles([])
      },
    })

  const [BookingAttachmentUpdate, { loading: updateLoading }] =
    useBookingAttachmentUpdateMutation({
      onError: error => {
        error.graphQLErrors.map(({ message }) => {
          snackFunc(message, false)
          dispatch({ type: 'addDialog', payload: false })
          dispatch({ type: 'reset', payload: {} })
          setPreviewFiles([])
          setFiles([])
        })
      },
      onCompleted: data => {
        if (data.BookingAttachmentUpdate === true) {
          snackFunc('Successfully update attachments', false)
        }
        refetch()
        dispatch({ type: 'addDialog', payload: false })
        dispatch({ type: 'reset', payload: {} })
        setPreviewFiles([])
        setFiles([])
      },
    })

  console?.log(bookingInfo?.Booking?.ID,state, 'booking')

  const {
    files,
    handleUploadChange,
    previewFiles,
    remove,
    setPreviewFiles,
    setFiles,
  } = useUploadAttachment()

  const allowedFileTypes = ['image', 'video', 'pdf', 'doc', 'excel'];

const getFileType = (fileName) => {
  const fileExtension = fileName.split('.').pop().toLowerCase();
  if (fileExtension.includes('jpg') || fileExtension.includes('jpeg') || fileExtension.includes('png')) {
    return 'image';
  } else if (fileExtension.includes('mp4') || fileExtension.includes('avi') || fileExtension.includes('mov')) {
    return 'video';
  } else if (fileExtension.includes('pdf')) {
    return 'pdf';
  } else if (fileExtension.includes('doc') || fileExtension.includes('docx')) {
    return 'doc';
  } else if (fileExtension.includes('xls') || fileExtension.includes('xlsx') || fileExtension.includes('csv')) {
    return 'excel';
  } else {
    return 'invalid';
  }
};

  const onSubmit = () => {

    const invalidFiles = files.filter((file) => !allowedFileTypes.includes(getFileType(file.name)));
    if (invalidFiles.length > 0) {
      // Display Snackbar for invalid file types
      snackFunc('Invalid file type', false);
      return;
    }

    if (mode === "add") {
      BookingAttachmentInsert({
        variables: {
          HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
          BookingID: bookingInfo?.Booking?.ID || bookingInfo?.guest?.BookingID,
          RegistrationID: state.RegistrationID,
          Remark: state.Remark,
          AttachmentInput: state.Attachment,
        },
      })
    } else if (mode === 'edit') {
      BookingAttachmentUpdate({
        variables: {
          HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
          BookingID: bookingInfo?.Booking?.ID || bookingInfo?.guest?.BookingID,
          Remark: state.Remark || state.menu.Remark,
          AttachmentInput: state.Attachment,
          BookingAttachmentID: state.menu.ID,
          AttachmentDetailsID: state.attachmentDetail,
        },
      })
    }
  }

  useEffect(() => {
    dispatch({ type: 'Attachment', payload: files })
  }, [files])

  useEffect(() => {
    if (state.menu?.ID) {
      let files = state.menu?.AttachmentDetail?.map(x => {
        return x.Gallery
      })

      setExistFile(files)
    }
  }, [state.menu])

  useEffect(() => {
    if (state?.mode === 'edit' && existFile) {
      const obj = []
      state.menu?.AttachmentDetail?.forEach(x => {
        existFile?.forEach(y => {
          if (x.Gallery?.ID === y.ID) {
            obj.push(x.ID)
          }
        })
      })
      dispatch({ type: 'attachmentDetail', payload: obj })
    }
  }, [existFile.length, state?.mode])

  const handleClose = () => {
    setFiles([])
    setPreviewFiles([])
    dispatch({ type: 'addDialog', payload: false })
    dispatch({ type: 'reset', payload: {} })
  }

  return insertLoading || updateLoading ? (
    <Loading />
  ) : (
    <>
      <CommonDialog
        fullWidth={true}
        open={open}
        onClose={() => dispatch({ type: 'addDialog', payload: false })}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <span className="xsTitle color-primary-orange flex-space">
                      Attachments
                    </span>
                  }
                />
              </ListItem>
            ),
          },

          body: () => (
            <CardContents>
              <FileUploadInput
                label="Upload Attachment(s)"
                files={state.Attachment}
                margin="normal"
                onHandleUploadChange={e => {
                  handleUploadChange(e)
                }}
                multiple
                imagePreview={
                  <>
                    {previewFiles?.map((v, index) => (
                      <UploadPreview
                        key={v}
                        src={v}
                        onClick={() => {
                          previewFiles.splice(index, 1)
                          remove(v)
                        }}
                        remove
                      />
                    ))}
                  </>
                }
              />
              {mode === 'edit' && (
                <>
                  {existFile?.map((v, index) => (
                    <UploadPreview
                      key={v?.ImageURL}
                      src={v?.ImageURL}
                      onClick={() => {
                        existFile.splice(index, 1)
                        setExistFile(existFile?.filter(e => e.ID !== v.ID))
                      }}
                      remove
                    />
                  ))}
                </>
              )}
              <TextField
                label="Remarks"
                fullWidth
                required
                value={state?.Remark || state?.menu?.Remark}
                onChange={e => {
                  dispatch({ type: 'Remark', payload: e.target.value })
                }}
                margin="normal"
              />
            </CardContents>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    handleClose()
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Save',
                props: {
                  onClick: () => {
                    onSubmit()
                  },
                  variant: 'contained',
                  color: 'primary',
                  disabled: mode === 'add' 
                    ? mode === 'add' && (files?.length === 0 || state.Remark === "" || state?.menu?.Remark === '')  
                    : mode === 'edit' && state?.attachmentDetail?.length === 0 && state?.Attachment?.length === 0
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </>
  )
}
