export const TotalRoomCount = (Registrations: any) => {
  let Count = 0
  Registrations?.map(i => {
    i.IsPrincipal && Count++
  })
  return Count
}
export const TotalCheckedInRoomCount = (Registrations: any) => {
  let Count = 0
  Registrations?.map(i => {
      i.RegistrationStatus === 'Inhouse' && i.IsPrincipal && Count++
  })
  return Count
}
export const TotalCheckedInGuest = (Registrations: any) => {
  let Count = 0
  Registrations?.map(i => {
    i.RegistrationStatus === 'Inhouse' && Count++
  })
  return Count
}

export const TotalCheckedInPax = (Registrations: any) => {
  const Count = Registrations?.filter(i => i.RegistrationStatus === 'Inhouse' && i.IsPrincipal)?.map(x => x?.NoOfAdult)?.reduce((a, b) => a + b, 0)
  return Count
}
const countOverbooking = (reg:any) => 
{
  let overbookingCount = 0
  const countOB = reg?.map(x => overbookingCount = x.RoomType.Overbooking + overbookingCount)
  return overbookingCount;
}
export const Price = Registrations => {
  let Price = 0
  Registrations?.map(i => {
    i?.IsPrincipal && (Price += i?.TotalRoomPrice || 0)
  })
  // Price -= Booking?.TotalAdvPayment || 0
  return Number(Price)

}
// Assign room count
export  const TotalAssignedRoom = (Registrations: any) => {
  let Count = 0
  Registrations?.map(i => {
    i.RoomID && i.IsPrincipal && Count++
  })
  return Count
}
//total rate code
export const TotalRateCode = (Registrations: any) => {
  let Count = 0
  Registrations?.map(i => {
   i.RatePolicyID && Count++
  })
  return Count
}