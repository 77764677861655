import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'
const RoomAssignment = lazy(() =>
  import('./RoomAssignment').then(module => ({
    default: module.RoomAssignment,
  }))
)
const CompanyDetail = lazy(() =>
  import('./CompanyDetail').then(module => ({
    default: module.CompanyDetail,
  }))
)

const AssignRoominhouse = lazy(() =>
  import('./RoomTransfer').then(module => ({
    default: module.RoomTransfer,
  }))
)
  const AssignRoomForm = lazy(() =>
  import('./AssignRoomForm').then(module => ({
    default: module.AssignRoomForm,
  }))

  )

  const AssignRoomFormWalkin = lazy(() =>
  import('./AssignRoomFormWalkin').then(module => ({
    default: module.AssignRoomFormWalkin,
  }))

 
)
export const roomAssignmentUrl = `/frontdesk/roomassignment`
export const assignUrl = `/frontdesk/roomassignment/assign`
export const dueInAssignUrl = '/frontdesk/duein/assign'
export const walkInAssignUrl = `/frontdesk/walkin/add/assign`
export const inhouseRoomTransferUrl = `/frontdesk/inhouse/roomtransfer`
//Room Upgrade
export const roomAssignmentCompanyUrl =
  '/frontdesk/roomassignment/corporatedetail'
export const inhouseRoomUpgradeUrl = '/frontdesk/inhouse/roomupgrade/assign'

export const RoomAssignmentRoutes = [
  {
    props: { exact: true, path: roomAssignmentUrl },
    component: <RoomAssignment />,
  },
  {
    props: { exact: true, path: assignUrl },
    component: (
      <AssignRoomForm mode={FrontDeskType.RoomAssignment} type="RoomAssign" />
    ),
  },
  {
    props: { exact: true, path: dueInAssignUrl },
    component: <AssignRoomForm mode={FrontDeskType.DueIn} type="RoomAssign" />,
  },
  {
    props: { exact: true, path: roomAssignmentCompanyUrl },
    component: <CompanyDetail />,
  },
  {
    props: { exact: true, path: walkInAssignUrl },
    component: <AssignRoomFormWalkin mode={FrontDeskType.WalkIn} type="RoomAssign" />,
  },
  {
    props: { exact: true, path: inhouseRoomTransferUrl },
    component: (
      <AssignRoominhouse mode={FrontDeskType.InHouse} type="Room Transfer" />
    ),
  },

  {
    props: { exact: true, path: inhouseRoomUpgradeUrl },
    component: (
      <AssignRoomForm mode={FrontDeskType.InHouse} type="Room Upgrade" />
    ),
  },
]
