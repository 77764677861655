import { useEffect, useState } from 'react'
import { amtStr } from 'containers/helper/numFormatter';

interface useGetTaxSchemeProps {
  TaxOutput;
  Type?: string;
}

export const useGetTaxScheme = ({ TaxOutput, Type}: useGetTaxSchemeProps) => {

  const [data, setData] = useState(null)
  useEffect(() => {
    if (TaxOutput?.length > 0) {
      setData(docTax())
    }
  }, [TaxOutput?.length])

  console.log(TaxOutput,"TaxOutput");

  const isNegativeAmt = (amount) => {
    return Number(amount) < 0
      ? `(${amtStr((amount)*-1)})`
      : `${amtStr((amount))}`
  }
  

  const docTax = () => {
    const taxItem = (
      TaxOutput?.map((x) => {
        if (Type === 'Payment') {
          return `
            <tr
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 28px;"
            >
              <td
                style="font-weight: 300; padding-left: 6px;"
              >
                ${x?.TaxInfo?.code || x?.taxCode || x?.TaxCode} (${x?.TaxRate || x?.taxRate || x?.TaxRate}%)
              </td>
              <td
                style="font-weight: 300; item-align:right; text-align: center;"
              >
                ${isNegativeAmt(Number(x?.TaxAmount || x?.taxAmount || x?.Value || 0.00))}
              </td>
            </tr>
          `
          
        } else if (Type === 'Receipt') {
          return `
            <tr
              class="" 
              style="font-family: 'Poppins';
              -webkit-print-color-adjust: exact;
              -webkit-text-size-adjust:none;
              background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td
                style="font-weight: 300; padding-left: 6px;"
              >
                ${x?.TaxInfo?.code ||x?.TaxCode|| x?.taxCode} (${x?.TaxRate || x?.taxRate}%)
              </td>
              <td
                style="font-weight: 300; item-align:right; text-align: right;"
              >
                ${isNegativeAmt(Number( 0.00))}
              </td>
            </tr>
          `
        } else if (Type === 'NonGuest') {
          return `
            <tr
              class="" 
              style="font-family: 'Poppins';
              -webkit-print-color-adjust: exact;
              -webkit-text-size-adjust:none;
              background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 15px;"
            >
              <td
                style="font-weight: 300; padding-left: 6px;"
              >
                ${x?.TaxInfo?.code || x?.taxCode || x?.TaxCode} (${x?.TaxRate || x?.taxRate || 0}%)
              </td>
              <td
                style="font-weight: 300; item-align:right; text-align: right;"
              >
                ${isNegativeAmt(Number(x?.TaxAmount || x?.taxAmount || x?.Value || 0.00))} 
              </td>
            </tr>
          `
        } else if (Type === 'Invoices') {
          return `
          <tr 
          style="font-size: 10px; line-height: 28px; text-align: center;"
        >
        <td style="text-align: left; padding-left: 6px; width: 30%">
                ${x?.tax?.code} (${x?.TaxRate}%)
              </td>
              <td style="text-align: right; padding-right: 10px; width: 30%">
                ${amtStr(Number(x?.TaxAmount))}
              </td>
            </tr>
          `
        } else if (Type === 'Debit Notes') {
          return `
          <tr 
          style="font-size: 10px; line-height: 28px; text-align: center;"
        >
        <td style="text-align: left; padding-left: 6px; width: 30%">
                ${x?.TaxInfo?.code} (${x?.TaxRate}%)
              </td>
              <td style="text-align: right; padding-right: 10px; width: 30%">
                ${amtStr(Number(x?.TaxAmount))}
              </td>
            </tr>
          `
        } else if (Type === 'Credit Notes') {
          return `
          <tr 
          style="font-size: 10px; line-height: 28px; text-align: center;"
        >
        <td style="text-align: left; padding-left: 6px; width: 30%">
                ${x?.tax?.code} (${x?.TaxRate}%)
              </td>
              <td style="text-align: right; padding-right: 10px; width: 30%">
                ${amtStr(Number(x?.TaxAmount))}
              </td>
            </tr>
          `
        } else if (Type === 'HotelMayaFolio') {
          return `
            <tr
              class="" 
              style="background-color: #fff;
              color: #2c2c2c;
              font-size: 10px;
              line-height: 28px;"
            >
              <td
                style="font-weight: 300; padding-left: 0px;"
              >
                ${x?.TaxInfo?.code || x?.taxCode || x?.TaxCode} (${x?.TaxRate || x?.taxRate || x?.TaxRate}%)
              </td>
              <td
                style="font-weight: 300; item-align:right; text-align: center;"
              >
                ${isNegativeAmt(Number(x?.TaxAmount || x?.taxAmount || x?.Value || 0.00))}
              </td>
            </tr>
          `
        }
        
      })
    )

    var docItem = Object.assign({}, taxItem)
    //Object to string
    var EachTaxItem = ''
    for (const [key, value] of Object.entries(docItem)) {
      EachTaxItem = EachTaxItem + value
    }

    return EachTaxItem
  }

  return { data }
}

