import React, { lazy } from 'react'
import { BookingDetails } from './BookingDetails'

const CorporateRecords = lazy(() =>
  import('./CorporateRecords/CorporateRecords').then(module => ({
    default: module.CorporateRecords,
  }))
)

const CorporateForm = lazy(() =>
  import('./CorporateRecords/CorporateForm').then(module => ({
    default: module.CorporateForm,
  }))
)

const CorporateContractList = lazy(() =>
  import('./CorporateRecords/CorporateContractList').then(module => ({
    default: module.CorporateContractList,
  }))
)

const CorporateContractForm = lazy(() =>
  import('./CorporateRecords/CorporateContractForm').then(module => ({
    default: module.CorporateContractForm,
  }))
)

const CorporateBookingList = lazy(() =>
  import('./CorporateRecords/CorporateBookingList').then(module => ({
    default: module.CorporateBookingList,
  }))
)

const CorporateAnalysis = lazy(() =>
  import('./CorporateRecords/CorporateAnalysis').then(module => ({
    default: module.CorporateAnalysis,
  }))
)

const corporateRecordsListRoutes = [
  {
    props: { exact: true, path: '/corporate/corporatelist/add' },
    component: <CorporateForm mode="Add" />,
  },

  {
    props: { exact: true, path: '/corporate/corporatelist/edit' },
    component: <CorporateForm mode="Edit" />,
  },

  {
    props: { exact: true, path: '/corporate/corporatelist/corporaterecords' },
    component: <CorporateRecords />,
  },

  {
    props: {
      exact: true,
      path: '/corporate/corporatelist/corporaterecords/corporatecontractlist',
    },
    component: <CorporateContractList />,
  },

  {
    props: {
      exact: true,
      path:
        '/corporate/corporatelist/corporaterecords/corporatecontractlist/add',
    },
    component: <CorporateContractForm mode="Add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/corporate/corporatelist/corporaterecords/corporatecontractlist/edit',
    },
    component: <CorporateContractForm mode="Edit" />,
  },

  {
    props: {
      exact: true,
      path: '/corporate/corporatelist/corporaterecords/corporatebookinglist',
    },
    component: <CorporateBookingList />,
  },

  {
    props: {
      exact: true,
      path:
        '/corporate/corporatelist/corporaterecords/corporatebookinglist/bookingdetails',
    },
    component: <BookingDetails />,
  },

  {
    props: {
      exact: true,
      path: '/corporate/corporatelist/corporaterecords/corporateanalysis',
    },
    component: <CorporateAnalysis />,
  },
]

export default corporateRecordsListRoutes
