import React, { lazy } from 'react'

const Department = lazy(() =>
  import('./Department').then(module => ({
    default: module.Department,
  }))
)
const Reasons = lazy(() =>
  import('./Reasons').then(module => ({
    default: module.Reasons,
  }))
)
const IncidentalCharges = lazy(() =>
  import('./IncidentalCharges').then(module => ({
    default: module.IncidentalCharges,
  }))
)
const IncidentalChargesForm = lazy(() =>
  import('./IncidentalChargesForm').then(module => ({
    default: module.IncidentalChargesForm,
  }))
)
const MarketingPersonnel = lazy(() =>
  import('./SalesChannel').then(module => ({
    default: module.MarketingPersonnel,
  }))
)

const departmentRoutes = [
  {
    props: { exact: true, path: '/settings/departmentsetup' },
    component: <Department />,
  },
  {
    props: {
      exact: true,
      path: '/settings/departmentsetup/reasons',
    },
    component: <Reasons />,
  },
  {
    props: {
      exact: true,
      path: '/settings/departmentsetup/incidentalcharges',
    },
    component: <IncidentalCharges />,
  },
  {
    props: {
      exact: true,
      path: '/settings/departmentsetup/incidentalchargesform',
    },
    component: <IncidentalChargesForm />,
  },

  {
    props: {
      exact: true,
      path: '/settings/departmentsetup/incidentalchargesform/add',
    },
    component: <IncidentalChargesForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/settings/departmentsetup/incidentalchargesform/edit',
    },
    component: <IncidentalChargesForm mode="Edit" />,
  },

  {
    props: {
      exact: true,
      path: '/settings/departmentsetup/marketingpersonnel',
    },
    component: <MarketingPersonnel />,
  },
]

export default departmentRoutes
