import React, { lazy } from 'react'
import agentRecordsListRoutes from './AgentList/AgentListRoutes'
import ratePolicyRoutes from './RatePolicySetup/RatePolicySetupRoutes'

const TravelAgent = lazy(() =>
  import('./TravelAgent').then(module => ({
    default: module.TravelAgent,
  }))
)

const AgentList = lazy(() =>
  import('./AgentList/AgentList').then(module => ({
    default: module.AgentList,
  }))
)

const RatePolicySetup = lazy(() =>
  import('./RatePolicySetup/RatePolicySetup').then(module => ({
    default: module.RatePolicySetup,
  }))
)

const travelAgentRoutes = [
  {
    props: { exact: true, path: '/travelagent' },
    component: <TravelAgent />,
  },

  {
    props: { exact: true, path: '/travelagent/agentlist' },
    component: <AgentList />,
  },

  {
    props: { exact: true, path: '/travelagent/ratepolicysetup' },
    component: <RatePolicySetup />,
  },
]

agentRecordsListRoutes.forEach(route => {
  travelAgentRoutes.push(route)
})

ratePolicyRoutes.forEach(route => {
  travelAgentRoutes.push(route)
})

export default travelAgentRoutes
