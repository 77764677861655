import React, { lazy } from 'react'

const RegenerateBillSchedule = lazy(() =>
  import('./RegenerateBillSchedule').then(module => ({
    default: module.RegenerateBillSchedule,
  }))
)

const regenerateBillScheduleRoutes = [
  {
    props: { exact: true, path: '/settings/ratepolicy/regeneratebillschedule' },
    component: <RegenerateBillSchedule />,
  },
]

export default regenerateBillScheduleRoutes
