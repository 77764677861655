import React, { useState } from 'react'

function useUploadAttachment() {
  const [previewFiles, setPreviewFiles] = useState([])
  const [files, setFiles] = useState([])

  const handleUploadChange = e => {
    const fileObj = []
    const fileArray = []
    const fileToPush = []

    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
      // fileToPush.push(e.target.files.item(i))
      fileToPush.push(fileObj[0][i])
    }

    const newPreview = [...previewFiles, ...fileArray]
    const newFiles = [...files, ...fileToPush]
    setPreviewFiles(newPreview)
    setFiles(newFiles)
  }
  //Remove img preview
  const remove = index => {
    setPreviewFiles(prevIndexes => [
      ...prevIndexes.filter((item, i) => i !== index),
    ])
    const newFiles = [...Array.from(files)]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const handleEditUpload = async (file: any) => {
    const prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.fileURL)
          .then(res => res.blob())
          .then(file => new File([file], x.fileName, { type: x?.mediaType }))
        prevFile.push(data)
      })
    )
    //end
    setFiles(prevFile)
  }

  // const handleEditUpload = async (file: any) => {
  //   const prevFile = []
  //   file.map((x, i) => {
  //     fetch(x.fileURL).then(res =>
  //       res.blob().then(y => {
  //         let a = new File([y], x?.fileName, { type: x?.mediaType })
  //         prevFile.push(a)
  //       })
  //     )
  //   })

  //   setFiles(prevFile)
  // }

  //// one with mapping files to ID or anything (string)

  const [fileMap, setFileMap] = useState({})
  const [previewMap, setPreviewMap] = useState({})

  const handleUploadMapping = (e, dataToMap: any) => {
    const fileObj = []
    const fileArray = []
    const fileToPush = []

    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
      fileToPush.push(e.target.files.item(i))
    }
    const prevFile: any[] = fileMap[dataToMap] ? fileMap[dataToMap] : []
    const prevPreview: any[] = previewMap[dataToMap]
      ? previewMap[dataToMap]
      : []

    previewMap[dataToMap] = [...prevPreview, ...fileArray]
    fileMap[dataToMap] = [...prevFile, ...fileToPush]

    return { preview: previewMap, file: fileMap }
  }

  const handleEditUploadMap = async (file: any, mappedData) => {
    let prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.fileURL)
          .then(res => res.blob())
          .then(file => new File([file], x.fileName, { type: x?.mediaType }))
        prevFile.unshift(data) // using unshift to ensure the order of the file remains the same
      })
    )
    //end
    fileMap[mappedData] = []
    fileMap[mappedData] = [...prevFile]

    return fileMap[mappedData]
  }

  const removeMappedFiles = (mappedData, index) => {
    previewMap[mappedData] = previewMap[mappedData]?.filter(
      (v, i) => i !== index
    )
    fileMap[mappedData] = fileMap[mappedData]?.filter((v, i) => i !== index)

    return { preview: previewMap, file: fileMap }
  }

  return {
    remove,
    files,
    setFiles,
    previewFiles,
    setPreviewFiles,
    handleUploadChange,
    handleEditUpload,

    // mapping part
    fileMap,
    previewMap,
    handleUploadMapping,
    removeMappedFiles,
    handleEditUploadMap,
  }
}

export default useUploadAttachment
