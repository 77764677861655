import React, { lazy } from 'react'
import { ChargesType, FrontDeskType } from '../Common'
import { AttachmentsListing } from 'containers/AttachmentsModule/AttachmentsListing'
const InHouse = lazy(() =>
  import('./InHouse').then(module => ({
    default: module.InHouse,
  }))
)
const GuestForm = lazy(() =>
  import('../DueIn/GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)

const LateCheckOut = lazy(() =>
  import('./LateCheckOut').then(module => ({
    default: module.LateCheckOut,
  }))
)
const EditGuest = lazy(() =>
  import('./EditGuest').then(module => ({
    default: module.EditGuest,
  }))
)



const RoomUpgrade = lazy(() =>
  import('./RoomUpgrade').then(module => ({
    default: module.RoomUpgrade,
  }))
)
const ChargesTypePayment = lazy(() =>
  import('./ChargesTypePayment/ChargesTypePayment').then(module => ({
    default: module.ChargesTypePayment,
  }))
)

const FolioHistory = lazy(() =>
  import('../DueOut/FolioHistory').then(module => ({
    default: module.FolioHistory,
  }))
)
const BIDocument = lazy(() =>
  import('../../BusinessInsightModule/BIDigitalDocument/BIDocument').then(module => ({
    default: module.BIDocument,
  }))
)
const ReceiptDocument = lazy(() =>
  import('../../BusinessInsightModule/BIDigitalDocument/ReceiptDocument').then(module => ({
    default: module.ReceiptDocument,
  }))
)
const RateAdjustment = lazy(() =>
  import('./RateAdjustment/RateAdjustment').then(module => ({
    default: module.RateAdjustment,
  }))
)
const RateAdjustmentDetail = lazy(() =>
  import('./RateAdjustment/RateAdjustmentDetail').then(module => ({
    default: module.RateAdjustmentDetail,
  }))
)
const RateAdjustmentForm = lazy(() =>
  import('./RateAdjustment/RateAdjustmentForm').then(module => ({
    default: module.RateAdjustmentForm,
  }))
)
const InHouseSubmenu = lazy(() =>
  import('./InHouseSubmenu').then(module => ({
    default: module.InHouseSubmenu,
  }))
)
const GuestList = lazy(() =>
  import('../RoomingList/GuestList').then(module => ({
    default: module.GuestList,
  }))
)
const AddnewGuest = lazy(() =>
  import('../RoomingList/GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)
const InHouseAuditLog = lazy(() =>
  import('./InHouseAuditLog').then(module => ({
    default: module.InHouseAuditLog,
  }))
)
const CompanyDetail = lazy(() =>
  import('../DueIn/CompanyDetail').then(module => ({
    default: module.CompanyDetail,
  }))
)
export const inHouseUrl = `/frontdesk/inhouse`
export const assignUrl = `/frontdesk/roomassignment/assign`
export const inHouseAddGuestUrl = `/frontdesk/inhouse/addguest`
export const EditGuestUrl = `/frontdesk/inhouse/bookinginfo`
export const lateCheckoutUrl = `/frontdesk/inhouse/latecheckout`
export const attachmentsUrl = `/frontdesk/inhouse/attachments`
export const lateCheckoutPaymentUrl = `/frontdesk/inhouse/latecheckout/payment`
export const extendStayUrl = `/frontdesk/inhouse/extendstay`
export const roomUpgradeUrl = `/frontdesk/inhouse/roomupgrade`
export const roomUpgradePaymentUrl = `/frontdesk/inhouse/roomupgrade/assign/payment`
export const inHouseFolioHistoryUrl = `/frontdesk/inhouse/history`
export const inHouseFolioHistoryDocUrl = `/frontdesk/inhouse/history/folio`
export const inHouseReceiptHistoryDocUrl = `/frontdesk/inhouse/history/receipt`
export const rateAdjustmentUrl = `/frontdesk/inhouse/rateadjustment`
export const rateAdjustmentAddUrl = `/frontdesk/inhouse/rateadjustment/add`
export const rateAdjustmentViewUrl = `/frontdesk/inhouse/rateadjustment/view`
// Charges Type Payment : Late Checkout , Extend Stay and room Upgrade

export const InHouseSubMenuPath = '/frontdesk/inhouse/detail'
export const InHouseRoomingListPath = '/frontdesk/inhouse/detail/roominglist'
export const InHouseAddNewGuestUrl = `/frontdesk/inhouse/detail/roominglist/addguest`
export const InHouseEditNewGuestUrl = `/frontdesk/inhouse/detail/roominglist/editguest`
export const InHouseAuditLogUrl = `/frontdesk/inhouse/auditlog`
export const inHouseCompanyUrl = `/frontdesk/inhouse/corporatedetail`
//Todo EditGuest -> rename to BookingInfo
export const InHouseRoutes = [
  {
    props: { exact: true, path: inHouseUrl },
    component: <InHouse />,
  },
  {
    props: { exact: true, path: InHouseSubMenuPath },
    component: <InHouseSubmenu />,
  },
  {
    props: { exact: true, path: EditGuestUrl },
    component: <EditGuest />,
  },
  {
    props: { exact: true, path: InHouseRoomingListPath },
    component: <GuestList mode={FrontDeskType.InHouse} />,
  },

  {
    props: { exact: true, path: InHouseAddNewGuestUrl },
    component: <AddnewGuest mode={'Add'} moduleMode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: InHouseEditNewGuestUrl },
    component: <AddnewGuest mode={'Edit'} moduleMode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: lateCheckoutUrl },
    component: <LateCheckOut mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: attachmentsUrl, mode: 'Inhouse'},
    component: <AttachmentsListing type={'Registration'}/>,
  },

  // LateCheckout payment
  {
    props: { exact: true, path: lateCheckoutPaymentUrl },
    component: (
      <ChargesTypePayment
        mode={FrontDeskType.InHouse}
        chargesType={ChargesType.LateCheckOut} // payment
      />
    ),
  },
  //Extend Stay
  {
    props: { exact: true, path: extendStayUrl },
    component: (
      <ChargesTypePayment
        mode={FrontDeskType.InHouse}
        chargesType={ChargesType.ExtendStay} // payment
      />
    ),
  },

  {
    props: { exact: true, path: roomUpgradeUrl },
    component: <RoomUpgrade mode={FrontDeskType.InHouse} />,
  },
  //Room Upgrade
  {
    props: { exact: true, path: roomUpgradePaymentUrl },
    component: (
      <ChargesTypePayment
        mode={FrontDeskType.InHouse}
        chargesType={ChargesType.RoomUpgrade} // payment
      />
    ),
  },
  {
    props: { exact: true, path: inHouseFolioHistoryUrl },
    component: <FolioHistory mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: inHouseFolioHistoryDocUrl },
    component: <BIDocument mode="FolioHistory" />,
  },
  {
    props: { exact: true, path: inHouseReceiptHistoryDocUrl },
    component: <ReceiptDocument mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: rateAdjustmentUrl },
    component: <RateAdjustment />,
  },
  {
    props: { exact: true, path: rateAdjustmentViewUrl },
    component: <RateAdjustmentDetail />,
  },
  {
    props: { exact: true, path: rateAdjustmentAddUrl },
    component: <RateAdjustmentForm mode="add" />,
  },
  {
    props: { exact: true, path: InHouseAuditLogUrl },
    component: <InHouseAuditLog />
  },
  {
    props: { exact: true, path: inHouseCompanyUrl },
    component: <CompanyDetail />,
  },
]
