import React, { lazy } from 'react'

const DebtorProfile = lazy(() =>
  import('./DebtorProfile').then(module => ({
    default: module.DebtorProfile,
  }))
)
const DebtorProfileForm = lazy(() =>
  import('./DebtorProfileForm').then(module => ({
    default: module.DebtorProfileForm,
  }))
)
const debtorProfileRoutes = [
  {
    props: { exact: true, path: '/settings/debtorprofile' },
    component: <DebtorProfile />,
  },
  {
    props: { exact: true, path: '/settings/debtorprofile/add' },
    component: <DebtorProfileForm FormMode={'Add'} />,
  },
  {
    props: { exact: true, path: '/settings/debtorprofile/edit' },
    component: <DebtorProfileForm FormMode={'Edit'} />,
  },
]

export default debtorProfileRoutes
