import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { useAuditLogDetailsQuery, useMiniBookingDetailsQuery } from 'generated/graphql'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Door from 'assets/icons/door-subinfoline.svg'
import Person from '@ifca-root/react-component/src/assets/hotelIcons/person.svg'
import { Price, TotalCheckedInPax, TotalCheckedInRoomCount, TotalRoomCount } from '../Common'
import { amtStr } from 'containers/helper/numFormatter'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import BookingAuditLogListing from '../BookingAuditLogListing'

export const RateAdjustmentAuditLog = () => {
  const history = useHistory()
  const location = useLocation()
  let dateFormat = require('dateformat')
  
  const localInfo: any = location.state

  const Option = [{
    DisplayValue: 'Rate Adjustment',
    TableName: 'RateAdjustmentAudit',
    FieldID: localInfo?.RateAdjustmentAuditID || '',
    SubDisplayValue: '',
    GroupName: 'Rate Adjustment',
  }]

  const [selectedValue, setSelectedValue] = useState({
    DisplayValue: 'Rate Adjustment',
    TableName: 'RateAdjustmentAudit',
    FieldID: localInfo?.RateAdjustmentAuditID || '',
    SubDisplayValue: '',
  })
  
  const {
    loading: loadingDetails,
    data: { BookingDetails } = { BookingDetails: null },
  } = useMiniBookingDetailsQuery({
    variables: {
      ID: localInfo?.BookingID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  
  console.log('localInfo >>>', localInfo)

  const {
    fetchMore,
    loading: loadingAuditLogDetails,
    data: { AuditLogDetails } = { AuditLogDetails: [] },
  } = useAuditLogDetailsQuery({
    variables: {
      FieldID: selectedValue?.FieldID,
      TableName: selectedValue?.TableName,
      limit: 20,
      offset: 0,
    },
    fetchPolicy: 'network-only',
  })
  
  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.goBack()}
        smTitle="HMS"
        title={JSON.parse(localStorage.getItem('Hotel'))?.HotelName}
        routeSegments={[
          { name: 'Booking' },
          { name: 'Audit Log', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <span className="display-flex">
            <span className=" xsTitle ">
              {`${dateFormat(BookingDetails?.ArrivalDate, 'dd mmm yyyy')} - 
        ${dateFormat(BookingDetails?.DepartureDate, 'dd mmm yyyy')}
        `}
            </span>
            <span className="icon-text desc">
              <img
                src={Door}
                alt="ifca"
                width={15}
                loading="lazy"
                style={{ paddingRight: '4px', paddingLeft: '4px' }}
              />
              <span>
                {' '}
                <span
                  className={`${
                    TotalCheckedInRoomCount(
                      BookingDetails?.BookedRegistration?.filter(
                        x => x?.RegistrationStatus !== 'Cancelled'
                      )
                    ) ===
                    TotalRoomCount(
                      BookingDetails?.BookedRegistration?.filter(
                        x => x?.RegistrationStatus !== 'Cancelled'
                      )
                    )
                      ? ``
                      : 'color-red'
                  }`}
                >
                  {TotalCheckedInRoomCount(
                    BookingDetails?.BookedRegistration?.filter(
                      x => x?.RegistrationStatus !== 'Cancelled'
                    )
                  )}
                </span>
                /
                {TotalRoomCount(
                  BookingDetails?.BookedRegistration?.filter(
                    x => x?.RegistrationStatus !== 'Cancelled'
                  )
                )}{' '}
              </span>
            </span>
            <span className="icon-text desc">
              <img
                src={Person}
                alt="ifca"
                width={15}
                loading="lazy"
                style={{ paddingRight: '4px', paddingLeft: '4px' }}
              />
              <span>
                {' '}
                <span
                  className={`${
                    TotalCheckedInPax(
                      BookingDetails?.BookedRegistration?.filter(
                        x => x?.RegistrationStatus !== 'Cancelled'
                      )
                    ) ===
                    BookingDetails?.BookedRegistration?.filter(
                      x =>
                        x?.IsPrincipal && x?.RegistrationStatus !== 'Cancelled'
                    )
                      .map(y => y?.NoOfAdult)
                      ?.reduce((a, b) => a + b, 0)
                      ? ``
                      : 'color-red'
                  }`}
                >
                  {TotalCheckedInPax(
                    BookingDetails?.BookedRegistration?.filter(
                      x => x?.RegistrationStatus !== 'Cancelled'
                    )
                  )}
                </span>
                /
                {BookingDetails?.BookedRegistration?.filter(
                  x => x?.IsPrincipal && x?.RegistrationStatus !== 'Cancelled'
                )
                  .map(y => y?.NoOfAdult)
                  ?.reduce((a, b) => a + b, 0)}{' '}
              </span>
            </span>
          </span>
        } // title text
        rightText={
          <>
            {/* <span className="icon-text color-red">
              <MonetizationOnIcon className="color-red" />
              {Price(BookingDetails?.BookedRegistration)}
            </span> */}

            <span
              className={`desc fw-medium icon-text ${
                Number(BookingDetails?.TotalAdvPayment) >=
                Number(Price(BookingDetails?.BookedRegistration))
                  ? 'color-green'
                  : 'color-red'
              }`}
            >
              {/* <MonetizationOnIcon />{' '} */}
              {amtStr(Price(BookingDetails?.BookedRegistration))}
            </span>
          </>
        }
        infoLine={
          // infoline text
          <>
            <div className="flex">
              <span className="desc flex-space">
                {BookingDetails?.BookingNo} <TextSeparator />{' '}
                {BookingDetails?.GroupName || BookingDetails?.Contact?.FullName}
              </span>
              <span className="desc">{BookingDetails?.Debtor?.Name || ''}</span>
            </div>
          </>
        }
      />

      <BookingAuditLogListing
        dropdownOptions={Option}
        queryData={AuditLogDetails}
        queryLoading={loadingAuditLogDetails}
        dropdownValue={selectedValue}
        setDropdownValue={setSelectedValue}
        infiniteScroll={{
          next: () => {
            const currentLength = AuditLogDetails?.length
            fetchMore({
              variables: {
                FieldID: selectedValue?.FieldID,
                TableName: selectedValue?.TableName,
                limit: 10,
                offset: currentLength
              },

              updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) return prev
                return Object.assign({}, prev, {
                  AuditLogDetails: [
                    ...prev?.AuditLogDetails,
                    ...fetchMoreResult?.AuditLogDetails
                  ]
                })
              }
            }).then(fetchMoreResult => { })
          }
        }}
        filterVariables={[
          'TableName',
          'ActionDate',
          'Action',
          'ModifiedByUserName',
          'CreatedByUserName',
          'FieldName',
          'OldValue',
          'NewValue',
          'Action',
        ]}
        hasSubheader={true}
      />
    </>
  )
}