import React, { lazy } from 'react'

const AllHotelList = lazy(() =>
  import('./AllHotelList').then(module => ({
    default: module.AllHotelList,
  }))
)
const HomeSelectHotel = lazy(() =>
  import('./HomeSelectHotel').then(module => ({
    default: module.HomeSelectHotel,
  }))
)
const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)
const Default = lazy(() =>
  import('./Default').then(module => ({
    default: module.Default,
  }))
)
const Notification = lazy(() =>
  import('./Notification').then(module => ({
    default: module.Notification,
  }))
)
const BookingDetail = lazy(() =>
  import('./BookingDetail').then(module => ({
    default: module.BookingDetail,
  }))
)
const homeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <Default />,
  },
  // {
  //   props: { exact: true, path: '/AllHotelList' },
  //   component: <AllHotelList route={'/home'} close={() => null} />,
  // },
  {
    props: { exact: true, path: '/homeselecthotel' },
    component: <HomeSelectHotel />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <Default />,
  },
  {
    props: { exact: true, path: '/home/notification' },
    component: <Notification />,
  },
  {
    props: { exact: true, path: '/home/notification/booking/detail' },
    component: <BookingDetail />,
  },
]

export default homeRoutes
