import React, { lazy } from 'react'

const AgentRecords = lazy(() =>
  import('./AgentsRecords/AgentRecords').then(module => ({
    default: module.AgentRecords,
  }))
)

const AgentForm = lazy(() =>
  import('./AgentsRecords/AgentForm').then(module => ({
    default: module.AgentForm,
  }))
)

const AgentContractList = lazy(() =>
  import('./AgentsRecords/AgentContractList').then(module => ({
    default: module.AgentContractList,
  }))
)

const AgentContractForm = lazy(() =>
  import('./AgentsRecords/AgentContractForm').then(module => ({
    default: module.AgentContractForm,
  }))
)

const AgentBookingList = lazy(() =>
  import('./AgentsRecords/AgentBookingList').then(module => ({
    default: module.AgentBookingList,
  }))
)

const AgentBookingDetails = lazy(() =>
  import('./AgentsRecords/AgentBookingDetails').then(module => ({
    default: module.AgentBookingDetails,
  }))
)

const AgentRoomTypeAllot = lazy(() =>
  import('./AgentsRecords/AgentRoomTypeAllotEdit').then(module => ({
    default: module.AgentRoomTypeAllot,
  }))
)

const AgentAnalysis = lazy(() =>
  import('./AgentsRecords/AgentAnalysis').then(module => ({
    default: module.AgentAnalysis,
  }))
)

const agentRecordsListRoutes = [
  {
    props: { exact: true, path: '/travelagent/agentlist/add' },
    component: <AgentForm mode="Add" />,
  },
  {
    props: { exact: true, path: '/travelagent/agentlist/edit' },
    component: <AgentForm mode="Edit" />,
  },
  {
    props: { exact: true, path: '/travelagent/agentlist/agentrecords' },
    component: <AgentRecords />,
  },
  {
    props: {
      exact: true,
      path: '/travelagent/agentlist/agentrecords/agentcontractlist',
    },
    component: <AgentContractList />,
  },
  {
    props: {
      exact: true,
      path: '/travelagent/agentlist/agentrecords/agentcontractlist/add',
    },
    component: <AgentContractForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/travelagent/agentlist/agentrecords/agentcontractlist/edit',
    },
    component: <AgentContractForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/travelagent/agentlist/agentrecords/agentbookinglist',
    },
    component: <AgentBookingList />,
  },
  {
    props: {
      exact: true,
      path:
        '/travelagent/agentlist/agentrecords/agentbookinglist/agentbookingdetails',
    },
    component: <AgentBookingDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/travelagent/agentlist/agentrecords/agentcontractlist/agentroomtypeallot',
    },
    component: <AgentRoomTypeAllot />,
  },

  {
    props: {
      exact: true,
      path: '/travelagent/agentlist/agentrecords/agentanalysis',
    },
    component: <AgentAnalysis />,
  },
]

export default agentRecordsListRoutes
