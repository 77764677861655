import React from 'react'

const TextSeparator = () => {
  return (
    <>
      <span className="text-separator">|</span>
    </>
  )
}

export default TextSeparator
