import React, { lazy } from 'react'

const RoomStatusSetup = lazy(() =>
  import('./RoomStatus').then(module => ({
    default: module.RoomStatusSetup,
  }))
)

const roomStatusRoutes = [
  {
    props: { exact: true, path: '/settings/roomstatussetup' },
    component: <RoomStatusSetup />,
  },
]

export default roomStatusRoutes
