import { useEffect, useState } from 'react'
import {
  TaxType,
} from 'generated/graphql'

interface outputData {
  SubTotal: Number,
  ServiceCharge: Number,
  ServiceTax: Number,
  Total: Number
}

export const useGetFolioChargesInfo = () => {

  // output state
  const [chargesInfo, setData] = useState<outputData>({
    SubTotal: 0,
    ServiceCharge: 0,
    ServiceTax: 0,
    Total: 0
  })

  // input state
  const [folioData, setFolioData] = useState(null)

  useEffect(() => {
    if (folioData?.ID) {
      console.log('folioData', folioData)
      const { Payment, RoomCharges, TaxLedger, BillLedger, TaxSchemeRoomLedger } = folioData
      let chargesItem;

      //  chargesItem = [...RoomCharges,...BillLedger]

      // print page
      if (RoomCharges?.length > 0 && BillLedger?.length > 0) {
        chargesItem = [...RoomCharges,...BillLedger]
      } else {
        if (RoomCharges?.length > 0) {
          chargesItem = [...RoomCharges]
        } else if (BillLedger?.length > 0) {
          chargesItem = [...BillLedger]
        } else {
          chargesItem = []
        }
      } 


      //payment
      let paymentAmt = 0;
      //charges
      let chargeBaseAmt = 0;
      let chargeServiceAmt = 0;
      let chargeTaxAmt = 0;
      //taxes
      let ttxAmt = 0;
      let levyAmt = 0;
      let ssfAmt = 0;
      let heritageAmt = 0;
      let taxSchemeRoomAmt = 0;

      // get total payment
      //===============================================================================
      if (
          folioData?.FolioType === 'NonGuest' &&
          folioData?.FolioNo === null
      ) {
        paymentAmt = Payment?.reduce((acc, curr) => {
          return acc + curr.Amount
        }, 0)
      }
      // get total charges
      //===============================================================================
      
      chargeBaseAmt = chargesItem?.reduce((acc, curr) => acc + curr?.BaseAmount, 0) // (base amt only)
      chargeServiceAmt = chargesItem?.reduce((acc, curr) => acc + curr?.ServiceCharge, 0) // (service amt only)
      chargeTaxAmt = chargesItem?.reduce((acc, curr) => acc + curr?.TaxAmount, 0) // (TaxAmount amt only)
      
      console.log(chargesItem,'chargesItem')
      // get taxes
      //===============================================================================
      
      ttxAmt = TaxLedger?.filter(x => x?.TaxType === (TaxType.TourismTax))?.reduce((acc, curr) => acc + curr?.TaxAmount, 0) || 0 //tourism tax
      levyAmt = TaxLedger?.filter(x => x?.TaxType === (TaxType.Levy))?.reduce((acc, curr) => acc + curr?.TaxAmount, 0) || 0  //Levy tax
      ssfAmt = TaxLedger?.filter(x => x?.TaxType === (TaxType.StateFund))?.reduce((acc, curr) => acc + curr?.TaxAmount, 0) || 0  //state fund tax
      heritageAmt = TaxLedger?.filter(x => x?.TaxType === (TaxType.HeritageTax))?.reduce((acc, curr) => acc + curr?.TaxAmount, 0) || 0  //state fund tax
      taxSchemeRoomAmt = TaxSchemeRoomLedger?.reduce((acc, curr) => acc + curr?.TaxAmount, 0) || 0


      // calculate
      //=================================================================================


if (paymentAmt > 0) {
  let totalChargeWOTax =
    chargeBaseAmt + ttxAmt + levyAmt + ssfAmt + heritageAmt || 0
  let SubTotal = totalChargeWOTax - paymentAmt  || 0
  let ServiceCharge = chargeServiceAmt || 0
  let ServiceTax = chargeTaxAmt || 0
  let Total =  (totalChargeWOTax + ServiceCharge + ServiceTax )- paymentAmt || 0


  setData({
    SubTotal,
    ServiceCharge,
    ServiceTax,
    Total,
  })
} else {
  let totalChargeWOTax =
    chargeBaseAmt + ttxAmt + levyAmt + ssfAmt + heritageAmt || 0
  let SubTotal = totalChargeWOTax || 0
  let ServiceCharge = chargeServiceAmt || 0
  let ServiceTax = chargeTaxAmt || 0
  let Total = (totalChargeWOTax + ServiceCharge + ServiceTax ) || 0

  setData({
    SubTotal,
    ServiceCharge,
    ServiceTax,
    Total,
  })
}

// if (data < 0) {
//   display = `(${amtStr(Math.abs(data))})`
// } else if (data > 0) {
//   display = amtStr(data)
// }
      
    }
  }, [folioData])
console.log(chargesInfo,'chargesInfo')
  return [setFolioData, chargesInfo] as const
}
