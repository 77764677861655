import React, { lazy } from 'react'

//Settings
import hotelRoutes from './Hotel/HotelRoutes'
import departmentRoutes from './Department/DepartmentRoutes'
import locationRoutes from './Location/LocationRoutes'
import roomTypeRoutes from './RoomType/RoomTypeRoutes'
import roomRoutes from './Room/RoomRoutes'
import roomStatusRoutes from './RoomStatus/RoomStatusRoutes'
import seasonCalendarRoutes from './SeasonCalendar/SeasonCalendarRoutes'
import ratePolicyRoutes from './RatePolicy/RatePolicyRoutes'
import roomSalesOptimizationRoutes from './RoomSalesOptimization/RoomSalesOptimizationRoutes'
import lateCheckOutChargesRoutes from './LateCheckOutCharges/LateCheckOutChargesRoutes'
import segmentRoutes from './Segment/SegmentRoutes'
import accountMappingRoutes from './AccountMapping/AccountMappingRoutes'
import ratePolicyV2Routes from './RatePolicyV2/RatePolicyRoutes'
import standardPolicyRoutes from './StandardPolicy/StandardPolicyRoutes'
import debtorTypeRoutes from './DebtorType/DebtorTypeRoutes'
import debtorProfileRoutes from './DebtorProfile/DebtorProfileRoutes'
import eDocumentRoutes from './E-Document/EDocumentRoutes'
import regenerateBillScheduleRoutes from './RegenerateBillSchedule/RegenerateBillScheduleRoutes'
const SettingsSelectHotel = lazy(() =>
  import('./SettingsSelectHotel').then(module => ({
    default: module.SettingsSelectHotel,
  }))
)
const Settings = lazy(() =>
  import('./Settings').then(module => ({
    default: module.Settings,
  }))
)

const Hotel = lazy(() =>
  import('./Hotel/Hotel').then(module => ({
    default: module.Hotel,
  }))
)

const Department = lazy(() =>
  import('./Department/Department').then(module => ({
    default: module.Department,
  }))
)

const Location = lazy(() =>
  import('./Location/Location').then(module => ({
    default: module.Location,
  }))
)

const FloorPlan = lazy(() =>
  import('./FloorPlan/FloorPlanNew').then(module => ({
    default: module.FloorPlanNew,
  }))
)

const RoomType = lazy(() =>
  import('./RoomType/RoomType').then(module => ({
    default: module.RoomType,
  }))
)

const Room = lazy(() =>
  import('./Room/RoomTypeList').then(module => ({
    default: module.RoomTypeList,
  }))
)

const RoomStatusSetup = lazy(() =>
  import('./RoomStatus/RoomStatus').then(module => ({
    default: module.RoomStatusSetup,
  }))
)

const SeasonCalendar = lazy(() =>
  import('./SeasonCalendar/SeasonCalendar').then(module => ({
    default: module.SeasonCalendar,
  }))
)

const OldRateSetup = lazy(() =>
  import('./RatePolicy/RatePolicy').then(module => ({
    default: module.RatePolicy,
  }))
)

const StandardPolicy = lazy(() =>
  import('./StandardPolicy/StandardPolicy').then(module => ({
    default: module.StandardPolicy,
  }))
)

const LateCheckOutCharges = lazy(() =>
  import('./LateCheckOutCharges/LateCheckOutCharges').then(module => ({
    default: module.LateCheckOutCharges,
  }))
)

const Segment = lazy(() =>
  import('./Segment/Segment').then(module => ({
    default: module.Segment,
  }))
)

const GuestAppPolicy = lazy(() =>
  import('./GuestAppPolicy/GuestAppPolicySetting').then(module => ({
    default: module.GuestAppPolicySetting,
  }))
)

const RoomAllocation = lazy(() =>
  import('./RoomAllocation/RoomAllocation').then(module => ({
    default: module.RoomAllocation,
  }))
)

const RoomAllocationForm = lazy(() =>
  import('./RoomAllocation/RoomAllocationForm').then(module => ({
    default: module.RoomAllocationForm,
  }))
)

const settingsRoutes = [
  {
    props: { exact: true, path: '/settingsselecthotel' },
    component: <SettingsSelectHotel />,
  },
  {
    props: { exact: true, path: '/settings' },
    component: <Settings />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup' },
    component: <Hotel />,
  },

  {
    props: { exact: true, path: '/settings/departmentsetup' },
    component: <Department />,
  },

  {
    props: { exact: true, path: '/settings/locationsetup' },
    component: <Location />,
  },
  {
    props: { exact: true, path: '/settings/floorplan' },
    component: <FloorPlan />,
  },
  {
    props: { exact: true, path: '/settings/roomtypesetup' },
    component: <RoomType />,
  },
  {
    props: { exact: true, path: '/settings/roomsetup' },
    component: <Room />,
  },
  {
    props: { exact: true, path: '/settings/roomstatussetup' },
    component: <RoomStatusSetup />,
  },
  {
    props: { exact: true, path: '/settings/seasoncalendarsetup' },
    component: <SeasonCalendar />,
  },

  {
    props: { exact: true, path: '/settings/oldratepolicysetup' },
    component: <OldRateSetup />,
  },

  {
    props: { exact: true, path: '/settings/standardpolicysetup' },
    component: <StandardPolicy />,
  },

  {
    props: { exact: true, path: '/settings/latecheckoutchargessetup' },
    component: <LateCheckOutCharges />,
  },
  {
    props: { exact: true, path: '/settings/segmentsetup' },
    component: <Segment />,
  },

  {
    props: { exact: true, path: '/settings/guestsetuppolicy' },
    component: <GuestAppPolicy />,
  },
  {
    props: { exact: true, path: '/settings/roomallocation' },
    component: <RoomAllocation />,
  },
  {
    props: { exact: true, path: '/settings/roomallocation/add' },
    component: <RoomAllocationForm mode={'Add'}/>,
  },
  {
    props: { exact: true, path: '/settings/roomallocation/edit' },
    component: <RoomAllocationForm mode={'Edit'}/>,
  },
]

hotelRoutes.forEach(route => {
  settingsRoutes.push(route)
})

departmentRoutes.forEach(route => {
  settingsRoutes.push(route)
})

locationRoutes.forEach(route => {
  settingsRoutes.push(route)
})

roomTypeRoutes.forEach(route => {
  settingsRoutes.push(route)
})

roomRoutes.forEach(route => {
  settingsRoutes.push(route)
})

roomStatusRoutes.forEach(route => {
  settingsRoutes.push(route)
})

seasonCalendarRoutes.forEach(route => {
  settingsRoutes.push(route)
})

ratePolicyRoutes.forEach(route => {
  settingsRoutes.push(route)
})
ratePolicyV2Routes.forEach(route => {
  settingsRoutes.push(route)
})
ratePolicyV2Routes.forEach(route => {
  settingsRoutes.push(route)
})
roomSalesOptimizationRoutes.forEach(route => {
  settingsRoutes.push(route)
})

lateCheckOutChargesRoutes.forEach(route => {
  settingsRoutes.push(route)
})
regenerateBillScheduleRoutes.forEach(route => {
  settingsRoutes.push(route)
})

segmentRoutes.forEach(route => {
  settingsRoutes.push(route)
})

// accountMappingRoutes.forEach(route => {
//   settingsRoutes.push(route)
// })
standardPolicyRoutes.forEach(route => {
  settingsRoutes.push(route)
})

debtorTypeRoutes.forEach(route => {
  settingsRoutes.push(route)
})
debtorProfileRoutes.forEach(route => {
  settingsRoutes.push(route)
})

eDocumentRoutes.forEach(route => {
  settingsRoutes.push(route)
})

export default settingsRoutes
