import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'

const IncidentalCharges = lazy(() =>
  import('./IncidentalCharges').then(module => ({
    default: module.IncidentalCharges,
  }))
)
//New check in flow due in detail

export const dueInIChargesUrl = `/frontdesk/duein/detail/incidentalcharges`
export const walkInIChargesUrl = `/frontdesk/walkin/incidentalcharges`
export const inHouseIChargesUrl = `/frontdesk/inhouse/incidentalcharges`
export const dueOutIChargesUrl = `/frontdesk/dueout/incidentalcharges`
export const InChargesRoutes = [
  {
    props: { exact: true, path: dueInIChargesUrl },
    component: <IncidentalCharges mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: walkInIChargesUrl },
    component: <IncidentalCharges mode={FrontDeskType.WalkIn} />,
  },
  {
    props: { exact: true, path: inHouseIChargesUrl },
    component: <IncidentalCharges mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: dueOutIChargesUrl },
    component: <IncidentalCharges mode={FrontDeskType.DueOut} />,
  },
]
