import { amtStr } from 'containers/helper/numFormatter'
import {
  useAdvancePaymentDetailsLazyQuery,
  useDepositClassLazyQuery,
  usePaymentDetailsLazyQuery,
  useOutGoingDocTemplateLazyQuery,
} from 'generated/graphql'
import React, { useEffect } from 'react'
import AddressConverter from '../addressConverter'
import { HotelXReceipt } from '../CustomizedDocument/Default/Receipt'
import ImperialLogo from 'assets/images/HotelImperial/ImperialLogo.jpg'
interface ReceiptProps {
  Type: string
  stateInfo: any
  HotelDetails: any
  BIUsersLisiting: any
  GetCurrentGovTax: any
  GetServiceChargeTax: any
  GetTaxScheme?: any[]
}
export const useReceipt = ({
  Type,
  stateInfo,
  HotelDetails,
  BIUsersLisiting,
  GetServiceChargeTax,
  GetCurrentGovTax,
  GetTaxScheme,
}: ReceiptProps) => {
  const dateFormat = require('dateformat')
  console.log(stateInfo, 'USERECIPT')
  const [
    PaymentQuery,
    { data: { PaymentDetails } = { PaymentDetails: null } },
  ] = usePaymentDetailsLazyQuery({
    variables: {
      ID: stateInfo?.ID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  const [
    AdvancePaymentQuery,
    { data: { AdvancePaymentDetails } = { AdvancePaymentDetails: null } },
  ] = useAdvancePaymentDetailsLazyQuery({
    variables: {
      ID: stateInfo?.ID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  const [
    DepositQuery,
    { data: { DepositClass } = { DepositClass: null } },
  ] = useDepositClassLazyQuery({
    variables: {
      ReversedID: !PaymentDetails?.Folio?.DepositLedger
        ? null
        : PaymentDetails?.Folio?.DepositLedger[0]?.ReversedID,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (Type === 'PaymentFolio') {
      PaymentQuery()
      AdvancePaymentQuery()
      DepositQuery()
    }
  }, [Type])
  const isNonGuestFolio = PaymentDetails?.Folio?.FolioType === 'NonGuest'
  const NonGuestFolioTitle = PaymentDetails?.Folio?.NonGuestInfo?.Title
  var NumberCount = 1

  const renderPaymentDesc = paymentType => {
    // if (paymentType === 'Cash') return `${PaymentDetails?.PaymentType} Payment`
    if (paymentType === 'CityLedger') {
      return `Charge to City Ledger - ${PaymentDetails?.DebtorAccount}`
    } else {
      return `${PaymentDetails?.PaymentType === "BankTT" ? "Bank TT" : PaymentDetails?.PaymentType } Payment`
    }
  }

  const [
    Template,
    {
      loading: Loadingtemp,
      data: { OutGoingDocTemplate } = {
        OutGoingDocTemplate: null,
      },
    },
  ] = useOutGoingDocTemplateLazyQuery({
    variables: {
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(()=>{
    Template()
  },[HotelXReceipt])

  const documentType = (Data) => {
    if (Data?.Folio?.DocumentType !== null) {
      return Data?.Folio?.DocumentType
    } else {
      return Data?.Folio?.FolioNo
    }
  }

  const HotelMayaTax = GetTaxScheme?.map(x => {
    return `
    <tr
      class="" 
      style="background-color: #fff;
      color: #2c2c2c;
      font-size: 10px;
      line-height: 15px;"
    >
      <td
        style="font-weight: 300; padding-left: 6px;"
      >
        ${x?.TaxInfo?.code || x?.taxCode || x?.TaxCode} (${x?.TaxRate || x?.taxRate || x?.TaxRate}%)
      </td>
      <td
        style="font-weight: 300; item-align:right; text-align: center;"
      >
        ${amtStr(0)}
      </td>
    </tr>
  `
  })

  var docItem = Object.assign({}, HotelMayaTax)
  //Object to string
  var HotelMayaTaxItem = ''
  for (const [key, value] of Object.entries(docItem)) {
    HotelMayaTaxItem = HotelMayaTaxItem + value
  }

  var mapObj: any = {
    //Hotel
    '!!HotelImage!!': !JSON.parse(localStorage.getItem('Hotel'))?.LogoImage
      ? 'https://hotelx-dev.ifca.io/document/ifca.png'
      : JSON.parse(localStorage.getItem('Hotel'))?.LogoImage.ImageURL,
    '!!ImperialLogo!!': ImperialLogo,
      '!!HotelName!!':` ${HotelDetails?.Company?.name} (${HotelDetails?.Company?.companyRegNo})`,
      '!!CompanyName!!': HotelDetails?.Company?.['name'],
    '!!RECEIPT!!':
      AdvancePaymentDetails?.PaymentClass === 'Refund' ||
      PaymentDetails?.PaymentClass === 'Refund'
        ? 'REFUND VOUCHER'
        : 'RECEIPT',
    '!!HotelEmail!!': !HotelDetails?.['Contact']
      ? ''
      : !HotelDetails?.['Contact']?.['BusinessEmail']
      ? ''
      : HotelDetails?.['Contact']?.['BusinessEmail'],
      '!!SSTRegNo!!': !HotelDetails?.Company?.['sstNo'] ? '' : HotelDetails?.Company['sstNo'],
      '!!HotelMobileNo!!': !HotelDetails?.['Contact']
      ? ''
      : HotelDetails?.['Contact']?.['PhoneNo'],
      '!!HotelFaxNo!!':HotelDetails?.Contact?.FaxNo || '',
      '!!HotelPhone!!': HotelDetails?.Contact?.MobileNo|| '',
      '!!HotelCustomAddress!!': ` <p style="color:#818181">
      ${ HotelDetails?.['HotelAddress']?.['address'].concat(' ').concat(HotelDetails?.['HotelAddress']?.['postCode'])}
      <br> ${HotelDetails?.['HotelAddress']?.['state']}${' '}${HotelDetails?.['HotelAddress']?.['country']}</p>` || '' ,
    '!!HotelWebsite!!': !HotelDetails?.['Contact']
      ? ''
      : !HotelDetails?.['Contact']?.['WebUrl']
      ? ''
      : HotelDetails?.['Contact']?.['WebUrl'],
    '!!HotelAddress!!': !HotelDetails?.['HotelAddress']
      ? ''
      : (!HotelDetails?.['HotelAddress']?.['address']
          ? ''
          : HotelDetails?.['HotelAddress']?.['address'] + ',') +
        (!HotelDetails?.['HotelAddress']?.['postCode']
          ? ''
          : HotelDetails?.['HotelAddress']?.['postCode'] + ', ') +
          (!HotelDetails?.['HotelAddress']?.['city']
          ? ''
          : HotelDetails?.['HotelAddress']?.['city'] + ', ') +
        (!HotelDetails?.['HotelAddress']?.['state']
          ? ''
          : HotelDetails?.['HotelAddress']?.['state'] + ', ') +
        (!HotelDetails?.['HotelAddress']?.['country']
          ? ''
          : HotelDetails?.['HotelAddress']?.['country'] + '. '),
    //Header top
    '!!ReceiptNo!!': PaymentDetails
      ? documentType(PaymentDetails)?.slice(0, 2) === 'RF'
        ? // ||
          //   PaymentDetails?.Folio?.FolioNo?.slice(0, 1) === 'D'
          PaymentDetails?.Folio?.FolioNo
        : PaymentDetails?.ReceiptNo
      : documentType(AdvancePaymentDetails)?.slice(0, 2) === 'RF'
      ? // ||
        //   AdvancePaymentDetails?.Folio?.FolioNo?.slice(0, 1) === 'D'
        AdvancePaymentDetails?.Folio?.FolioNo
      : AdvancePaymentDetails?.ReceiptNo,
    '!!BeneficiaryName!!': PaymentDetails?.Folio?.BeneficiaryName || '',
    '!!BeneficiaryAddress!!': PaymentDetails?.Folio?.BeneficiaryAddress || '',

    '!!CashierName!!': !BIUsersLisiting?.name
      ? JSON.parse(localStorage.getItem('LoggedInUser'))?.name
      : BIUsersLisiting?.name,
    '!!Date!!': dateFormat(
      PaymentDetails?.PaymentDate || AdvancePaymentDetails?.PaymentDate,
      'dd/mm/yyyy'
    ),
    '!!RoomNo!!':
      // PaymentDetails?.Registration?.Room?.RoomNo ||
      PaymentDetails?.Folio?.FolioNo ||
      AdvancePaymentDetails?.Booking?.Registration?.filter(
        x => x?.IsPrincipal
      )?.map(y => y?.Room?.RoomNo) ||
      '',
    '!!AgentName!!': !PaymentDetails?.Registration
      ? !AdvancePaymentDetails?.Registration
        ? ''
        : !AdvancePaymentDetails?.Registration[0]?.Booking?.DebtorAccount
        ? ''
        : `<td style="font-size:10px; font-weight: 400; line-height: 18px; width:50%;">
        Company/Travelling Agent Name:${AdvancePaymentDetails?.Registration[0]?.Booking?.Debtor?.Name}
      </td>`
      : !PaymentDetails?.Registration?.Booking?.DebtorAccount
      ? ''
      : `<td style="font-size:10px; font-weight: 400; line-height: 18px; width:50%;"> 
      Company/Travelling Agent Name: ${PaymentDetails?.Registration?.Booking?.Debtor?.Name}

      </td>`,
    '!!GuestName!!': isNonGuestFolio
      ? NonGuestFolioTitle
      : PaymentDetails?.Registration?.Guest?.Contact?.FullName ||
        AdvancePaymentDetails?.Booking?.Contact?.FullName ||
        '',
    '!!GuestMobileNo!!': PaymentDetails
      ? PaymentDetails?.Registration?.Guest?.Contact?.MobileNo || ''
      : AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.MobileNo || '',
    '!!GuestEmail!!': PaymentDetails
      ? PaymentDetails?.Registration?.Guest?.Contact?.Email || ''
      : AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.Email || '',
    '!!GuestAddress!!':
      AddressConverter({
        address: PaymentDetails?.Registration?.Guest?.Contact?.Address?.address,
        address2:
          PaymentDetails?.Registration?.Guest?.Contact?.Address?.address2,
        city: PaymentDetails?.Registration?.Guest?.Contact?.Address?.city,
        country: PaymentDetails?.Registration?.Guest?.Contact?.Address?.country,
        postCode:
          PaymentDetails?.Registration?.Guest?.Contact?.Address?.postCode,
        state: PaymentDetails?.Registration?.Guest?.Contact?.Address?.state,
      }) ||
      AddressConverter({
        address: AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.Address?.address,
        address2: AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.Address?.address2,
        city: AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.Address?.city,
        country: AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.Address?.country,
        postCode: AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.Address?.postCode,
        state: AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.Address?.state,
      }),
    '!!IdentityCard!!': PaymentDetails
      ? PaymentDetails?.Registration?.Guest?.Contact?.NRIC ||
        PaymentDetails?.Registration?.Guest?.Contact?.PassportNo ||
        ''
      : AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.NRIC ||
        AdvancePaymentDetails?.Booking?.Registration?.filter(
          x => x?.IsPrincipal
        )[0]?.Guest?.Contact?.PassportNo ||
        '',
    '!!ArrivalDate!!': isNonGuestFolio ? '' : PaymentDetails
      ? dateFormat(
          PaymentDetails?.Registration?.CheckInDate ||
            PaymentDetails?.Registration?.ArrivalDate,
          'dd/mm/yyyy'
        )
      : dateFormat(
          AdvancePaymentDetails?.Booking?.Registration?.filter(
            x => x?.IsPrincipal
          )[0]?.CheckInDate ||
            AdvancePaymentDetails?.Booking?.Registration?.filter(
              x => x?.IsPrincipal
            )[0]?.ArrivalDate,
          'dd/mm/yyyy'
        ),
    '!!ArrivalTime!!':isNonGuestFolio ? '' :  PaymentDetails
      ? dateFormat(
          PaymentDetails?.Registration?.CheckInDate ||
            PaymentDetails?.Registration?.ArrivalDate,
          'hh:MM:ss TT'
        )
      : dateFormat(
          AdvancePaymentDetails?.Booking?.Registration?.filter(
            x => x?.IsPrincipal
          )[0]?.CheckInDate ||
            AdvancePaymentDetails?.Booking?.Registration?.filter(
              x => x?.IsPrincipal
            )[0]?.ArrivalDate,
          'hh:MM:ss TT'
        ),
    '!!DepartureDate!!':isNonGuestFolio ? '' :  PaymentDetails
      ? dateFormat(
          PaymentDetails?.Registration?.CheckOutDate ||
            PaymentDetails?.Registration?.DepartureDate,
          'dd/mm/yyyy'
        )
      : dateFormat(
          AdvancePaymentDetails?.Booking?.Registration?.filter(
            x => x?.IsPrincipal
          )[0]?.CheckOutDate ||
            AdvancePaymentDetails?.Booking?.Registration?.filter(
              x => x?.IsPrincipal
            )[0]?.DepartureDate,
          'dd/mm/yyyy'
        ),
    '!!DepartureTime!!':isNonGuestFolio ? '' :  PaymentDetails
      ? dateFormat(
          PaymentDetails?.Registration?.CheckOutDate ||
            PaymentDetails?.Registration?.DepartureDate,
          'hh:MM:ss TT'
        )
      : dateFormat(
          AdvancePaymentDetails?.Booking?.Registration?.filter(
            x => x?.IsPrincipal
          )[0]?.CheckOutDate ||
            AdvancePaymentDetails?.Booking?.Registration?.filter(
              x => x?.IsPrincipal
            )[0]?.DepartureDate,
          'hh:MM:ss TT'
        ),

    '!!BookingNo!!': isNonGuestFolio ? '' : 
      PaymentDetails?.Registration?.Booking?.BookingNo ||
      AdvancePaymentDetails?.Booking?.BookingNo ||
      '',
    //description
    '!!NewItem!!': `
        <tr  
        class="room-detail" 
        style="
          font-family: poppins;
          -webkit-print-color-adjust: exact;
          -webkit-text-size-adjust:none;
          border: 2px solid #fff;
          color: #212121;
          font-size: 10px;
          line-height: 28px;
          text-align: center;">
          <td style="text-align: center;">${
            PaymentDetails
              ? dateFormat(PaymentDetails?.PaymentDate, 'dd/mm/yyyy')
              : dateFormat(AdvancePaymentDetails?.PaymentDate, 'dd/mm/yyyy')
          }</td>
          <td style="text-align: left;">${
            isNonGuestFolio
              ? `${PaymentDetails?.PaymentType  === "BankTT" ? "Bank TT" : PaymentDetails?.PaymentType } Payment`
              : PaymentDetails
              ? PaymentDetails?.Registration?.Booking?.DebtorAccount === null
                ? documentType(PaymentDetails)?.slice(0, 2) === 'RF'
                  ? `${PaymentDetails?.PaymentClass} - ${
                      DepositClass?.DepositClass === 'SecurityDeposit'
                        ? 'Security Deposit'
                        : 'Key Card Deposit'
                    }`
                  : documentType(PaymentDetails)?.slice(0, 1) === 'D' && documentType(PaymentDetails)?.slice(0,2) !== 'DN'
                  ? PaymentDetails?.Folio?.DepositLedger &&
                    PaymentDetails?.Folio?.DepositLedger[0]?.DepositClass ===
                      'SecurityDeposit'
                    ? 'Security Deposit'
                    : 'Key Card Deposit'
                  : // : `${PaymentDetails?.PaymentType} Payment - ${PaymentDetails?.DebtorAccount}`
                    // : `${PaymentDetails?.PaymentType} Payment`
                    renderPaymentDesc(PaymentDetails?.PaymentType)
                : PaymentDetails?.PaymentType === 'CityLedger' 
                ? `Charge to City Ledger - ${PaymentDetails?.DebtorAccount}`
                : renderPaymentDesc(PaymentDetails?.PaymentType)
              : AdvancePaymentDetails?.Booking?.DebtorAccount === null
              ? `${AdvancePaymentDetails?.PaymentType === "BankTT" ? "Bank TT" : AdvancePaymentDetails?.PaymentType  } Payment`
              : `Charge to City Ledger - ${AdvancePaymentDetails?.DebtorAccount}`
          }</td>
          <td style="text-align: left;">${
            PaymentDetails
              ? documentType(PaymentDetails)?.slice(0, 2) === 'RF'
                ? PaymentDetails?.Folio?.DepositLedger[0]?.Remarks
                : PaymentDetails?.ReferenceNo === null
                ? !PaymentDetails?.CardNo
                ? ''
                : PaymentDetails?.CardNo
                : PaymentDetails?.ReferenceNo
              : AdvancePaymentDetails?.ReferenceNo === null
              ? !PaymentDetails?.CardNo
                ? ''
                : PaymentDetails?.CardNo
              : AdvancePaymentDetails?.ReferenceNo
          }</td>
          <td style="text-align: center; ">${
            PaymentDetails
              ? amtStr(PaymentDetails?.Amount)
              : amtStr(AdvancePaymentDetails?.Amount)
          }</td>
    </tr>`,
    //tax count

    '!!ServiceCharge!!': GetServiceChargeTax['Value'],
    '!!TotalServiceCharge!!': amtStr(0),
    '!!Tax!!': GetCurrentGovTax['Value'],
    '!!TotalGovTax!!': amtStr(0),
    '!!TotalTourismTax!!': '',

    '!!TaxType!!': ``,
    '!!TaxSchemeItem!!': OutGoingDocTemplate?.Receipt ? HotelMayaTaxItem || '' : '',

    '!!TaxTypeAmount!!': amtStr(0),

    '!!TotalAmount!!': PaymentDetails
      ? amtStr(PaymentDetails?.Amount)
      : amtStr(AdvancePaymentDetails?.Amount),
  }
  
  var ReceiptTemplate =  OutGoingDocTemplate !== null ? OutGoingDocTemplate?.Receipt?.replace(
    /!!RECEIPT!!|!!HotelImage!!|!!ReceiptNo!!|!!NewItem!!|!!HotelCustomAddress!!|!!HotelPhone!!|!!HotelFaxNo!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!CompanyName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!TaxSchemeItem!!|!!ImperialLogo!!/gi,
    function (matched) {
      return mapObj[matched]
    }
  ) : HotelXReceipt.replace(
    /!!RECEIPT!!|!!HotelImage!!|!!ReceiptNo!!|!!NewItem!!|!!TotalAmount!!|!!TotalTourismTax!!|!!TotalGovTax!!|!!TotalServiceCharge!!|!!TaxTypeAmount!!|!!TaxType!!|!!Tax!!|!!ServiceCharge!!|!!AgentName!!|!!GuestAddress!!|!!IdentityCard!!|!!CashierName!!|!!SSTRegNo!!|!!HotelWebsite!!|!!HotelMobileNo!!|!!HotelEmail!!|!!HotelAddress!!|!!BookingNo!!|!!DepartureTime!!|!!DepartureDate!!|!!GuestName!!|!!HotelName!!|!!CompanyName!!|!!Date!!|!!RoomNo!!|!!ArrivalTime!!|!!GuestMobileNo!!|!!ArrivalDate!!|!!GuestEmail!!|!!BeneficiaryName!!|!!BeneficiaryAddress!!|!!TaxSchemeItem!!/gi,
    function (matched) {
      return mapObj[matched]
    }
  ) 

  console.log(ReceiptTemplate,'ReceiptTemplate')
  return {
    ReceiptTemplate,
    PaymentDetails,
    AdvancePaymentDetails,
  }
}
