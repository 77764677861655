import React, { lazy } from 'react'
const SeasonCalendar = lazy(() =>
  import('./SeasonCalendar').then(module => ({
    default: module.SeasonCalendar,
  }))
)

const seasonCalendarRoutes = [
  {
    props: { exact: true, path: '/settings/seasoncalendarsetup' },
    component: <SeasonCalendar />,
  },
]

export default seasonCalendarRoutes
