import React, { lazy } from 'react'
import debtorRoutes from './DebtorModule/DebtorRoutes'
import ScheduleListingRoutes from './ScheduleBillingModule/ScheduleBillingRoutes'
import debtorTypeRoutes from './Setup/DebtorType/DebtorTypeRoutes'
import debtorProfileRoutes from './Setup/DebtorProfile/DebtorProfileRoutes'
// import agentRecordsListRoutes from './AgentList/AgentListRoutes'
// import ratePolicyRoutes from './RatePolicySetup/RatePolicySetupRoutes'
import { LedgerType } from '../CityLedgerModule/DebtorModule/DebtorAccountModule/Models'

const CityLedger = lazy(() =>
  import('./CityLedger').then(module => ({
    default: module.CityLedger,
  }))
)

const SetupListing = lazy(() =>
  import('./Setup/SetupListing').then(module => ({
    default: module.SetupListing,
  }))
)

const NoteListing = lazy(() =>
  import('./Setup/NoteListing').then(module => ({
    default: module.NoteListing,
  }))
)

const AutoNumberingForm = lazy(() =>
  import('./Setup/AutoNumbering/AutoNumberingForm').then(module => ({
    default: module.AutoNumberingForm,
  }))
)



const DebtorListing = lazy(() =>
  import('./DebtorModule/DebtorListing').then(module => ({
    default: module.DebtorListing,
  }))
)
const ScheduleBillingListing = lazy(() =>
  import('./ScheduleBillingModule/ScheduleBillingListing').then(module => ({
    default: module.ScheduleBillingListing,
  }))
)
const ScheduleInvoice = lazy(() =>
  import('./ScheduleBillingModule/ScheduleBillingDocument/ScheduleInvoice').then(module => ({
    default: module.ScheduleInvoice,
  }))
)

const CreditControl = lazy(() =>
  import('./CreditControlModule/CreditControl').then(module => ({
    default: module.CreditControl,
  }))
)

const CollectionActivities = lazy(() =>
  import('./CreditControlModule/CollectionActivities').then(module => ({
    default: module.CollectionActivities,
  }))
)

const CollectionActivitiesAdd = lazy(() =>
  import('./CreditControlModule/CollectionActivitiesForm').then(module => ({
    default: module.CollectionActivitiesForm,
  }))
)

const StatementOfAccount = lazy(() =>
  import('./StatementOfAccount/StatementOfAccount').then(module => ({
    default: module.StatementOfAccount,
  }))
)

const MonthlyStatementLisiting = lazy(() =>
  import('./StatementOfAccount/MonthlyStatementLisiting').then(module => ({
    default: module.MonthlyStatementLisiting,
  }))
)
const MonthlyStatementDetail = lazy(() =>
  import('./StatementOfAccount/MonthlyStatementDetail').then(module => ({
    default: module.MonthlyStatementDetail,
  }))
)

const Invoice = lazy(() =>
  import('./StatementOfAccount/Invoice').then(module => ({
    default: module.Invoice,
  }))
)

const OrReceipt = lazy(() =>
  import('./StatementOfAccount/OrRefundDoc').then(module => ({
    default: module.OrRefundDoc,
  }))
)

const cityLedgerRoutes = [
  {
    props: { exact: true, path: '/cityledger' },
    component: <CityLedger />,
  },

  {
    props: { exact: true, path: '/cityledger/setup' }, 
    component: <SetupListing />,
  },
  {
    props: { exact: true, path: '/cityledger/setup/NoteListing' }, 
    component: <NoteListing />,
  },
  {
    props: { exact: true, path: '/cityledger/setup/notelisting/invoiceformatsetup' },
    component: <AutoNumberingForm mode='Invoice'/>,
  },
  {
    props: { exact: true, path: '/cityledger/setup/notelisting/debitnoteformatsetup' },
    component: <AutoNumberingForm mode='Debit Note'/>,
  },
  {
    props: { exact: true, path: '/cityledger/setup/notelisting/creditnoteformatsetup' },
    component: <AutoNumberingForm mode='Credit Note'/>,
  },
  {
    props: { exact: true, path: '/cityledger/setup/notelisting/officialreceiptformatsetup' },
    component: <AutoNumberingForm mode='Official Receipt'/>,
  },
  {
    props: { exact: true, path: '/cityledger/setup/notelisting/refundformatsetup' },
    component: <AutoNumberingForm mode='Refund'/>,
  },
  {
    props: { exact: true, path: '/cityledger/setup/notelisting/groupinvoicesetup' },
    component: <AutoNumberingForm mode='Group Invoice'/>,
  },

  {
    props: { exact: true, path: '/cityledger/debtor' },
    component: <DebtorListing />,
  },

  {
    props: { exact: true, path: '/cityledger/schedulebilling' },
    component: <ScheduleBillingListing />,
  },
  {
    props: { exact: true, path: '/cityledger/schedulebillinginvoice' },
    component: <ScheduleInvoice ledgerType={LedgerType.Invoices} />, // tobe confirm
  },

  {
    props: { exact: true, path: '/cityledger/creditcontrol' },
    component: <CreditControl />,
  },

  {
    props: { exact: true, path: '/cityledger/creditcontrol/collectionactivities' },
    component: <CollectionActivities />,
  },

  {
    props: { exact: true, path: '/cityledger/creditcontrol/collectionactivities/add' },
    component: <CollectionActivitiesAdd mode='Add'/>,
  },

  {
    props: { exact: true, path: '/cityledger/creditcontrol/collectionactivities/edit' },
    component: <CollectionActivitiesAdd mode='Edit'/>,
  },

  {
    props: { exact: true, path: '/cityledger/statementofaccount' },
    component: <StatementOfAccount />,
  },


  {
    props: { exact: true, path: '/cityledger/statementofaccount/MonthlyStatementLisiting' },
    component: <MonthlyStatementLisiting />,
  },

  {
    props: { exact: true, path: '/cityledger/statementofaccount/MonthlyStatementLisiting/MonthlyStatementDetail' },
    component: <MonthlyStatementDetail />,
  },

  {
    props: { exact: true, path: '/cityledger/statementofaccount/MonthlyStatementLisiting/MonthlyStatementDetail/Invoice' },
    component: <Invoice />,
  },

  {
    props: { exact: true, path: '/cityledger/statementofaccount/MonthlyStatementLisiting/MonthlyStatementDetail/OfficialReceipt' },
    component: <OrReceipt ledgerType={LedgerType.OfficialReceipt} />,
  },

  {
    props: { exact: true, path: '/cityledger/statementofaccount/MonthlyStatementLisiting/MonthlyStatementDetail/Refund' },
    component: <OrReceipt ledgerType={LedgerType.Refund} />,
  },


]

ScheduleListingRoutes.forEach(route => {
  cityLedgerRoutes.push(route)
})

debtorRoutes.forEach(route => {
  cityLedgerRoutes.push(route)
})

debtorTypeRoutes.forEach(route => {
  cityLedgerRoutes.push(route)
})

debtorProfileRoutes.forEach(route => {
  cityLedgerRoutes.push(route)
})

export default cityLedgerRoutes
