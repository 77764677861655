import React, { lazy } from 'react'

const StandardPolicy = lazy(() =>
  import('./StandardPolicy').then(module => ({
    default: module.StandardPolicy,
  }))
)


const HotelOperationalPolicy = lazy(() =>
  import('./HotelOperationalPolicy').then(module => ({
    default: module.HotelOperationalPolicy,
  }))
)

const SecurityDepositPolicy = lazy(() =>
  import('./SecurityDepositPolicy').then(module => ({
    default: module.SecurityDepositPolicy,
  }))
)

const TaxServiceChargePolicy = lazy(() =>
  import('./TaxServiceChargePolicy').then(module => ({
    default: module.TaxServiceChargePolicy,
  }))
)

const ServiceCharge = lazy(() =>
  import('./ServiceCharge').then(module => ({
    default: module.ServiceCharge,
  }))
)
const TourismTax = lazy(() =>
  import('./TourismTax').then(module => ({
    default: module.TourismTax,
  }))
)
const RoomStatusPolicy = lazy(() =>
  import('./RoomStatusPolicy').then(module => ({
    default: module.RoomStatusPolicy,
  }))
)
const CeilingFloorPricePolicy = lazy(() =>
  import('./CeilingFloorPricePolicy').then(module => ({
    default: module.CeilingFloorPricePolicy,
  }))
)
const LevyHeritageTax = lazy(() =>
  import('./LevyHeritageTax').then(module => ({
    default: module.LevyHeritageTax,
  }))
)
const TermCondition = lazy(() =>
  import('./TermCondition').then(module => ({
    default: module.TermCondition,
  }))
)
const TermConditionEdit = lazy(() =>
  import('./TermConditionEdit').then(module => ({
    default: module.TermConditionEdit,
  }))
)

const SOAFooter = lazy(() =>
  import('./SOAFooter').then(module => ({
    default: module.SOAFooter,
  }))
)

const AdvancePaymentPolicy = lazy(() =>
  import('./AdvancePaymentPolicy').then(module => ({
    default: module.AdvancePaymentPolicy,
  }))
)


const EInvoicePolicy = lazy(() =>
  import('./EInvoicePolicy').then(module => ({
    default: module.EInvoicePolicy,
  }))
)

export const TNC = {
  OnlineBooking: 'Online Booking',
  RegistrationCard: 'Registration Card',
  RegulationClause: 'Regulation Clause',
  PDPAPolicy: 'PDPA Policy',
  OnlinePayment: 'Online Payment Gateway Terms & Conditions',
  InvoiceRemark: 'Invoice Remark',
  SOAFooter: 'SOA Footer',
}

const standardPolicyRoutes = [
  {
    props: { exact: true, path: '/settings/standardpolicysetup' },
    component: <StandardPolicy />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/hoteloperationalpolicy/edit',
    },
    component: <HotelOperationalPolicy mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/securitydepositpolicy/edit',
    },
    component: <SecurityDepositPolicy mode="Edit" />,
  },

  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/taxservicechargepolicy/edit',
    },
    component: <TaxServiceChargePolicy mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/taxservicechargepolicy/servicecharge',
    },
    component: <ServiceCharge />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/taxservicechargepolicy/levyheritagetax',
    },
    component: <LevyHeritageTax />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/taxservicechargepolicy/tourismtax',
    },
    component: <TourismTax />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/roomstatuspolicy/edit',
    },
    component: <RoomStatusPolicy mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/generalpolicy/edit',
    },
    component: <CeilingFloorPricePolicy mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions',
    },
    component: <TermCondition />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions/online-booking',
    },
    component: <TermConditionEdit Mode={TNC.OnlineBooking} />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions/reg-card',
    },
    component: <TermConditionEdit Mode={TNC.RegistrationCard} />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions/regulation-clause',
    },
    component: <TermConditionEdit Mode={TNC.RegulationClause} />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions/pdpa-policy',
    },
    component: <TermConditionEdit Mode={TNC.PDPAPolicy} />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions/online-payment',
    },
    component: <TermConditionEdit Mode={TNC.OnlinePayment} />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions/invoice-remark',
    },
    component: <TermConditionEdit Mode={TNC.InvoiceRemark} />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/term-conditions/soa-footer',
    },
    component: <SOAFooter Mode={TNC.SOAFooter} />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/advancepaymentpolicy/edit',
    },
    component: <AdvancePaymentPolicy mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/settings/standardpolicy/einvoicepolicy',
    },
    component: <EInvoicePolicy />,
  },
]

export default standardPolicyRoutes
