import React, { lazy } from 'react'
const CompanyListing = lazy(() => 
  import ('./CompanyListing').then(module => ({
    default: module.CompanyListing
  }))
)
const CompanyForm = lazy(() => 
  import ('./CompanyForm').then(module => ({
    default: module.CompanyForm
  }))
)
const CompanyDetail = lazy(() => 
  import ('./CompanyDetail').then(module => ({
    default: module.CompanyDetail
  }))
)
const CompanyRoutes = [
  {
    props: { exact: true, path: '/common-settings/company' },
    component: <CompanyListing />
  },
  {
    props: { exact: true, path: '/common-settings/company/add' },
    component: <CompanyForm mode="Add"/>
  },
  {
    props: { exact: true, path: '/common-settings/company/edit' },
    component: <CompanyForm mode="Edit"/>
  },
  {
    props: { exact: true, path: '/common-settings/company/view' },
    component: <CompanyDetail />
  }
]
export default CompanyRoutes