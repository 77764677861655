import {
  useGetHotelPermissionQuery,
  useGetRolePermissionQuery,
} from 'generated/graphql'
import localForage from 'localforage'
import React, { useEffect } from 'react'
export const PermissionWrapper = ({ children }) => {
  // const {
  //   data: { getHotelPermission } = {
  //     getHotelPermission: [],
  //   },
  // } = useGetHotelPermissionQuery({
  //   fetchPolicy: 'network-only',
  // })
  // const {
  //   data: { getRolePermission } = {
  //     getRolePermission: [],
  //   },
  // } = useGetRolePermissionQuery({
  //   fetchPolicy: 'network-only',
  // })
  // // role perm info
  // useEffect(() => {
  //   if (getHotelPermission) {
  //     localForage.setItem('HotelPermission', getHotelPermission)
  //   }
  //   if (getRolePermission) {
  //     localForage.setItem('permission', getRolePermission)
  //   }
  // }, [getHotelPermission, getRolePermission])

  return <>{children}</>
}
