import React, { lazy } from 'react'
const Hotel = lazy(() =>
  import('./Hotel').then(module => ({
    default: module.Hotel,
  }))
)
const HotelEdit = lazy(() =>
  import('./HotelEdit').then(module => ({
    default: module.HotelEdit,
  }))
)
const ContactEdit = lazy(() =>
  import('./ContactEdit').then(module => ({
    default: module.ContactEdit,
  }))
)
const TermsConditionsEdit = lazy(() =>
  import('./TermsConditionsEdit').then(module => ({
    default: module.TermsConditionsEdit,
  }))
)
const AboutEdit = lazy(() =>
  import('./AboutEdit').then(module => ({
    default: module.AboutEdit,
  }))
)
const GalleryEdit = lazy(() =>
  import('./GalleryEdit').then(module => ({
    default: module.GalleryEdit,
  }))
)

const FacilitiesForm = lazy(() =>
  import('./FacilitiesForm').then(module => ({
    default: module.FacilitiesForm,
  }))
)

const NewsForm = lazy(() =>
  import('./NewsForm').then(module => ({
    default: module.NewsForm,
  }))
)

const hotelRoutes = [
  {
    props: { exact: true, path: '/settings/hotelsetup' },
    component: <Hotel />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/hoteledit' },
    component: <HotelEdit />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/contactedit' },
    component: <ContactEdit />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/termsconditionsedit' },
    component: <TermsConditionsEdit />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/aboutedit' },
    component: <AboutEdit />,
  },
  {
    props: { exact: true, path: '/settings/hotelsetup/galleryedit' },
    component: <GalleryEdit />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/facilities/add' },
    component: <FacilitiesForm mode="Add" />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/facilities/edit' },
    component: <FacilitiesForm mode="Edit" />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/news/add' },
    component: <NewsForm mode="Add" />,
  },

  {
    props: { exact: true, path: '/settings/hotelsetup/news/edit' },
    component: <NewsForm mode="Edit" />,
  },
]

export default hotelRoutes
