import { Fab, FabProps } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react'
interface Props extends FabProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  footer?: boolean
  floatTotalAmt?: boolean
  icon: React.ReactNode
}
export default function RefreshButton(props) {
  const { onClick, footer, floatTotalAmt, icon, ...rest } = props
  return (
    <Fab
      {...rest}
      aria-label="add"
      style={{ bottom: floatTotalAmt ? `100px` : '' }}
      className={`float-btn ${footer ? 'with-footer' : ''}`}
      onClick={onClick}
    >
      {icon ? icon : <RefreshIcon />}
    </Fab>
  )
}
