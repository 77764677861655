import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { Grid, List } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
// import { ContentWrapper } from 'components/Layout/ContentWrapper'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import { AllHotelList } from 'containers/HomeModule/AllHotelList'
import { useBookFormQuery, useBookingDetailsQuery } from 'generated/graphql'
import { amtStr } from 'containers/helper/numFormatter'
import { differenceInDays } from 'date-fns'
// import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import Person from '@ifca-root/react-component/src/assets/hotelIcons/person.svg'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import Door from 'assets/icons/door-subinfoline.svg'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
// import { MonetizationOn } from '@material-ui/icons'

export const BookingDetails = () => {
  let history = useHistory()
  const dateFormat = require('dateformat')
  const { state }: any = useLocation()
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
  const [RoomTypeList, setRoomTypeList] = useState([])
  const [RoomingList, setRoomingList] = useState([])
  const NoOfDays =
    differenceInDays(
      new Date(
        dateFormat(state?.CorporateBooking?.DepartureDate, 'yyyy-mm-dd')
      ),
      new Date(dateFormat(state?.CorporateBooking?.ArrivalDate, 'yyyy-mm-dd'))
    ) || 1
  // const {
  //   data: {
  //     SegmentListing,
  //     RoomTypeListing,
  //     PromotionListing,
  //     HotelSalesChannelListing,
  //     DebtorListing,
  //   } = {
  //     SegmentListing: [],
  //     RoomTypeListing: [],
  //     PromotionListing: [],
  //     HotelSalesChannelListing: [],
  //     DebtorListing: [],
  //   },
  // } = useBookFormQuery({
  //   variables: {
  //     HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
  //     StartDate: state?.CorporateBooking?.ArrivalDate,
  //     EndDate: state?.CorporateBooking?.DepartureDate,
  //   },
  //   fetchPolicy: 'no-cache',
  // })

  const {
    refetch,
    loading: bookingLoading,
    data: { BookingDetails } = { BookingDetails: null },
  } = useBookingDetailsQuery({
    variables: {
      ID: state?.CorporateBooking?.ID,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  console.log(`BookingDetails`, BookingDetails)
  useEffect(() => {
    if (BookingDetails?.ID) {
      BookingDetails?.BookedRegistration?.map((i, index) => {
        if (i.IsPrincipal === true) {
          if (
            !RoomTypeList.find(
              j =>
                j?.RoomTypeID === i?.RoomTypeID &&
                j.RatePolicyID === i.RatePolicyID &&
                j.PromoCode === i.PromoCode
            )
          ) {
            let NoOfRoom = 0
            BookingDetails?.BookedRegistration?.map(j => {
              j.RoomTypeID === i.RoomTypeID &&
                j.RatePolicyID === i.RatePolicyID &&
                j.PromoCode === i.PromoCode &&
                j.IsPrincipal &&
                NoOfRoom++
            })
            RoomTypeList.push({
              RatePolicyCode: i?.RatePolicy?.RateCode,
              RatePolicyID: i?.RatePolicy?.ID,
              RoomTypeCode: i?.RoomType?.Code,
              RoomTypeID: i?.RoomType?.ID,
              RoomRate: i?.RoomRate,
              TotalRate: i?.RoomRate * NoOfRoom,
              NoOfRoom: NoOfRoom,
              DiscountAmount: i?.DiscountAmount * NoOfRoom,
              PromoCode: i?.PromoCode,
              UniqueID: index,
            })
          }
        }
        ///RoomingList
        if (i.IsPrincipal) {
          RoomingList.push([i])
        } else {
          if (RoomingList.find(j => j[0].SharerNo === i.SharerNo)) {
            let index = RoomingList.map(j => {
              return j[0].SharerNo
            }).indexOf(i.SharerNo)
            RoomingList[index].push(i)
          }
        }
        setRoomingList(RoomingList)
      })
      setRoomTypeList(RoomTypeList)
      forceUpdate()
    }
  }, [BookingDetails])

  const MasterTotalPrice = () => {
    let totalPrice = 0
    RoomTypeList?.map(i => {
      totalPrice += i?.TotalRate * NoOfDays - i?.DiscountAmount
    })
    return Number(totalPrice)
  }

  const GuestNames = Guests => {
    let NamesString = null
    Guests.map(i => {
      if (!NamesString) {
        NamesString = i.Guest?.Contact?.FullName
      } else {
        NamesString += ', '
        NamesString += i.Guest?.Contact?.FullName
      }
    })
    return NamesString
  }

  const TotalCheckedInRoomCount = (Registrations: any) => {
    let Count = 0
    Registrations?.map(i => {
      i.RegistrationStatus === 'Inhouse' && i.IsPrincipal && Count++
    })
    return Count
  }

  const TotalRoomCount = (Registrations: any) => {
    let Count = 0
    Registrations?.map(i => {
      i.IsPrincipal && Count++
    })
    return Count
  }

  const TotalCheckedInGuest = (Registrations: any) => {
    let Count = 0
    Registrations?.map(i => {
      i.RegistrationStatus === 'Inhouse' && Count++
    })
    return Count
  }

  const Price = Registrations => {
    let Price = 0
    Registrations?.map(i => {
      i?.IsPrincipal && (Price += i?.TotalRoomPrice || 0)
    })
    // Price -= Booking?.TotalAdvPayment || 0
    return Number(Price)
  }

  const coreBlock = (
    <ContentWrapper multiDynamicInfoSearch>
      <CardContents
        section={{
          header: {
            title: 'Stay Information',
          },
        }}
      >
        <div className="content-wrap">
          <div className="desc">Arrival Date</div>
          <div className="xsTitle">
            {dateFormat(BookingDetails?.ArrivalDate, 'dd mmm yyyy')}
          </div>
        </div>

        <div className="content-wrap">
          <div className="desc">Departure Date</div>
          <div className="xsTitle">
            {dateFormat(BookingDetails?.DepartureDate, 'dd mmm yyyy')}
          </div>
        </div>

        <div className="content-wrap">
          <div className="desc">Night(s)</div>
          <div className="xsTitle">
            {differenceInDays(
              new Date(dateFormat(BookingDetails?.DepartureDate, 'yyyy-mm-dd')),
              new Date(dateFormat(BookingDetails?.ArrivalDate, 'yyyy-mm-dd'))
            ) || 1}
          </div>
        </div>

        <div className="content-wrap">
          <div className="desc">Group Booking</div>
          <div className="xsTitle">
            {BookingDetails?.IsGroupBooking ? 'Yes' : 'No'}
          </div>
        </div>
      </CardContents>

      <CardContents
        section={{
          header: {
            title: 'Contact Information',
          },
        }}
      >
        <div className="content-wrap full">
          <div className="desc">Book By</div>
          <div className="xsTitle">{BookingDetails?.Contact?.FullName}</div>
        </div>

        <div className="content-wrap">
          <div className="desc">Phone No. (Optional)</div>
          <div className="xsTitle">{BookingDetails?.Contact?.PhoneNo}</div>
        </div>

        <div className="content-wrap">
          <div className="desc">Nationality</div>
          <div className="xsTitle">{BookingDetails?.Contact?.Nationality}</div>
        </div>

        <div className="content-wrap full">
          <div className="desc">Account Name (if applicable)</div>
          <div className="xsTitle">{BookingDetails?.Debtor?.Name}</div>
        </div>

        <div className="content-wrap full">
          <div className="desc">Email Address</div>
          <div className="xsTitle">{BookingDetails?.Contact?.Email}</div>
        </div>

        <div className="content-wrap">
          <div className="desc">Sales Channel</div>
          <div className="xsTitle">
            {BookingDetails?.SalesChannel?.Description}
          </div>
        </div>

        <div className="content-wrap">
          <div className="desc">Source</div>
          <div className="xsTitle">
            {BookingDetails?.Source?.replace(/_/g, ' ')}
          </div>
        </div>

        <div className="content-wrap full">
          <div className="desc">Segment</div>
          <div className="xsTitle">{BookingDetails?.Segment?.Description}</div>
        </div>
      </CardContents>

      <CardContents
        section={{
          header: {
            title: 'Room Type',
          },
        }}
      >
        <div style={{ display: 'block', width: '100%' }}>
          <Grid
            container
            justify="flex-start"
            className="fw-medium xsTitle flex-space color-grey"
          >
            <Grid item xs={1} component="span">
              No.
            </Grid>
            <Grid item xs={3} component="span">
              Room Type
            </Grid>
            <Grid item xs={5} component="span">
              Rate Code
            </Grid>
            <Grid item xs={3} component="span">
              No. of Room
            </Grid>
          </Grid>

          {RoomTypeList?.map((i, index) => (
            <Grid
              key={index}
              container
              className="fw-medium xsTitle flex-space  m-t-10 m-b-10"
            >
              <Grid item xs={1} component="span">
                {index + 1}
              </Grid>
              <Grid item xs={3} component="span">
                {i?.RoomTypeCode}
              </Grid>
              <Grid
                item
                xs={5}
                component="span"
                style={{ display: 'inline-grid' }}
              >
                {i?.RatePolicyCode}
                <span className="desc fw-medium">SubTotal</span>
              </Grid>
              <Grid
                item
                xs={3}
                component="span"
                style={{ display: 'inline-grid' }}
              >
                {i?.NoOfRoom || 1}
                <span className="desc fw-medium icon-text">
                  {/* <MonetizationOn color="primary" /> */}

                  {amtStr(i?.TotalRate * NoOfDays - i?.DiscountAmount) || 0.0}
                </span>
              </Grid>
            </Grid>
          ))}
          <div
            className="m-b-10"
            style={{ borderTop: '1px solid black', display: 'flex' }}
          >
            <span className="fw-medium xsTitle flex-space m-t-10"> Total </span>
            <span className="fw-medium xsTitle  m-t-10 icon-text">
              {' '}
              {/* <MonetizationOn color="primary" /> */}
              {amtStr(MasterTotalPrice())}{' '}
            </span>
          </div>
        </div>
      </CardContents>
      <CardContents
        section={{
          header: {
            title: 'Special Request', 
          },
        }}
      >
        {/* <div className="content-wrap full">
          <div className="xsTitle">
            {state?.CorporateBooking?.SpecialRequest || 'No special request'}
          </div>
        </div> */}
        {state?.CorporateBooking?.BookedRegistration.length ? (
          state?.CorporateBooking?.BookedRegistration?.map(x => {
            return (
              <div className="content-wrap full">
                <div className="desc fs-14">{x.SpecialRequest}</div>
              </div>
            )
          })
        ) : (
          <div className="content-wrap full">
            <div className="xsTitle">No Special Request</div>
          </div>
        )}
      </CardContents>

      <CardContents
        section={{
          header: {
            title: 'Billing Instruction',
          },
        }}
      >
        <div className="content-wrap full">
          <div className="desc">Charge Type</div>
          <div className="xsTitle">City Account</div>
        </div>

        <div className="content-wrap full">
          <div className="desc">Remark</div>
          <div className="xsTitle">
            {state?.CorporateBooking?.BillingInstruction || 'No remarks'}
          </div>
        </div>
      </CardContents>
      <CardContents
        section={{
          header: {
            title: 'Rooming List',
          },
        }}
      >
        <div style={{ display: 'block', width: '100%' }}>
          <Grid
            container
            justify="flex-start"
            className="fw-medium xsTitle flex-space color-grey"
          >
            <Grid item xs={2} component="span">
              No.
            </Grid>
            <Grid item xs={3} component="span">
              Room Type
            </Grid>
            <Grid item xs={3} component="span">
              No. of Pax
            </Grid>
            <Grid item xs={4} component="span">
              Guest Name(s)
            </Grid>
          </Grid>

          {RoomingList?.map((i, index) => (
            <Grid
              key={index}
              container
              className="fw-medium xsTitle flex-space  m-t-10 m-b-10"
            >
              <Grid item xs={2} component="span">
                {index + 1}
              </Grid>
              <Grid item xs={3} component="span">
                {i[0]?.RoomType?.Code}
              </Grid>
              <Grid
                item
                xs={3}
                component="span"
                style={{ display: 'inline-grid' }}
              >
                {i?.length}
              </Grid>
              <Grid item xs={4} component="span" className="overflow-txt">
                {GuestNames(i)}
              </Grid>
            </Grid>
          ))}
        </div>
      </CardContents>
    </ContentWrapper>
  )

  //! Handle Click HotelList Dialog Box
  const [change, setSwitch] = useState(false)
  const handleClickSwitchDialog = () => {
    setSwitch(true)
  }

  const CompanyListDialog = (
    <StandardDialog
      fullWidth={true}
      open={change}
      onClose={() => setSwitch(false)}
      sections={{
        header: {
          title: 'Switch Hotel',
        },
        body: () => (
          <>
            <AllHotelList route={'/corporate'} close={() => setSwitch(false)} />
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => setSwitch(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )

  return (
    <>
      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        // sideBtn="switch"
        // onClickAction={() => history.push('/AllHotelList')}
        smTitle="HMS"
        title={JSON.parse(localStorage.getItem('Hotel'))?.HotelName}
        routeSegments={[
          //{ name: 'Main' },
          { name: '' },
          { name: '' },
          { name: '' },
          { name: `${state?.CorporateBooking?.Debtor?.Name}` },
          { name: 'Booking Records', current: true },
          //{ name: 'Details', current: true },
        ]}
        // rightRouteSegments={[
        //   { name: `${state?.CorporateBooking?.BookingNo}`, current: true },
        // ]}
      />
      <DynamicSubHeader
        title={
          <span className="display-flex">
            <span className=" xsTitle ">
              {`${dateFormat(BookingDetails?.ArrivalDate, 'dd mmm yyyy')} - 
        ${dateFormat(BookingDetails?.DepartureDate, 'dd mmm yyyy')}
        `}
            </span>
            <span className="icon-text desc">
              <img
                src={Door}
                alt="ifca"
                width={15}
                loading="lazy"
                style={{ paddingRight: '4px', paddingLeft: '4px' }}
              />
              <span>
                {' '}
                <span
                  className={`${
                    TotalCheckedInRoomCount(
                      BookingDetails?.BookedRegistration
                    ) === TotalRoomCount(BookingDetails?.BookedRegistration)
                      ? ``
                      : 'color-red'
                  }`}
                >
                  {TotalCheckedInRoomCount(BookingDetails?.BookedRegistration)}
                </span>
                /{TotalRoomCount(BookingDetails?.BookedRegistration)}{' '}
              </span>
            </span>
            <span className="icon-text desc">
              <img
                src={Person}
                alt="ifca"
                width={15}
                loading="lazy"
                style={{ paddingRight: '4px', paddingLeft: '4px' }}
              />
              <span>
                {' '}
                <span
                  className={`${
                    TotalCheckedInGuest(BookingDetails?.BookedRegistration) ===
                    BookingDetails?.BookedRegistration?.length
                      ? ``
                      : 'color-red'
                  }`}
                >
                  {TotalCheckedInGuest(BookingDetails?.BookedRegistration)}
                </span>
                /{BookingDetails?.BookedRegistration?.length}{' '}
              </span>
            </span>
          </span>
        } // title text
        rightText={
          <>
            {/* <span className="icon-text color-red">
              <MonetizationOnIcon className="color-red" />
              {Price(BookingDetails?.BookedRegistration)}
            </span> */}

            <span
              className={`desc fw-medium icon-text ${
                Number(BookingDetails?.TotalAdvPayment) >=
                Number(Price(BookingDetails?.BookedRegistration))
                  ? 'color-green'
                  : 'color-red'
              }`}
            >
              {/* <MonetizationOnOutlinedIcon />{' '} */}
              {amtStr(Price(BookingDetails?.BookedRegistration))}
            </span>
          </>
        }
        infoLine={
          // infoline text
          <>
            <span className="flex desc">
              {BookingDetails?.BookingNo} <TextSeparator />{' '}
              {BookingDetails?.Debtor?.Name}
            </span>
          </>
        }
        subInfoLine={
          <span className="desc">{BookingDetails?.Contact?.FullName}</span>
        }
      />

      <List className="core-list" style={{ marginTop: '-40px' }}>
        {coreBlock}
      </List>
      {CompanyListDialog}
    </>
  )
}
