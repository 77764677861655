import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'
import { inHouseAddGuestUrl } from '../InHouse/InHouseRoutes'
import { AttachmentsListing } from 'containers/AttachmentsModule/AttachmentsListing'

const DueInListing = lazy(() =>
  import('./DueInListing').then(module => ({
    default: module.DueInListing,
  }))
)

const CheckInListing = lazy(() =>
  import('../CheckIn/CheckIn').then(module => ({
    default: module.CheckInListing,
  }))
)

const DueInSubmenu = lazy(() =>
  import('./DueInSubmenu').then(module => ({
    default: module.DueInSubmenu,
  }))
)
const CheckInForm = lazy(() =>
  import('./CheckInForm').then(module => ({
    default: module.CheckInForm,
  }))
)

const GuestForm = lazy(() =>
  import('./GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)
const CompanyDetail = lazy(() =>
  import('./CompanyDetail').then(module => ({
    default: module.CompanyDetail,
  }))
)
const AddGuest = lazy(() =>
  import('./AddGuest').then(module => ({
    default: module.AddGuest,
  }))
)
const EditBookingInfo = lazy(() =>
  import('./EditBookingInfo').then(module => ({
    default: module.EditBookingInfo,
  }))
)
const GuestList = lazy(() =>
  import('../RoomingList/GuestList').then(module => ({
    default: module.GuestList,
  }))
)
const AddnewGuest = lazy(() =>
  import('../RoomingList/GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)

const MultiCheckin= lazy(() =>
  import('./MainMultiCheckIn').then(module => ({
    default: module.MainMultiCheckIn,
  }))
)
export const dueInUrl = `/frontdesk/duein`
// New check in flow

export const dueInDetailUrl = `/frontdesk/duein/detail`
export const dueInCompanyUrl = `/frontdesk/duein/corporatedetail`
export const checkInUrl = `/frontdesk/duein/checkin`
export const multicheckInUrl = `/frontdesk/duein/multicheckin`
export const addGuestUrl = `/frontdesk/duein/detail/addguest`
export const walkinAddGuestUrl = '/frontdesk/walkin/addguest'
export const editBookingInfoUrl = '/frontdesk/duein/detail/editbookinginfo'
export const GuestListUrl = '/frontdesk/duein/detail/RoomingList'
export const AttachmentsUrl = '/frontdesk/duein/detail/Attachments'
export const addNewGuestUrl = `/frontdesk/duein/detail/roominglist/addguest`
export const editNewGuestUrl = `/frontdesk/duein/detail/roominglist/editguest`
export const groupCheckInCompanyUrl = `/frontdesk/groupcheckin/corporatedetail`

export const DueInRoutes = [
  {
    props: { exact: true, path: dueInUrl },
    component: <CheckInListing />,
    // component: <DueInListing />,
  },
  {
    props: { exact: true, path: GuestListUrl },
    component: <GuestList mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: AttachmentsUrl },
    component: <AttachmentsListing type={'Registration'} />,
  },

  {
    props: { exact: true, path: dueInDetailUrl },
    component: <DueInSubmenu />,
  },
  {
    props: { exact: true, path: dueInCompanyUrl },
    component: <CompanyDetail />,
  },
  {
    props: { exact: true, path: groupCheckInCompanyUrl },
    component: <CompanyDetail />,
  },
  {
    props: { exact: true, path: checkInUrl },
    component: <CheckInForm mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: multicheckInUrl },
    component: <MultiCheckin mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: addGuestUrl },
    component: <AddGuest mode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: walkinAddGuestUrl },
    component: <AddGuest mode={FrontDeskType.WalkIn} />,
  },
  {
    props: { exact: true, path: inHouseAddGuestUrl },
    component: <AddGuest mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: editBookingInfoUrl },
    component: <EditBookingInfo />,
  },
  {
    props: { exact: true, path: addNewGuestUrl },
    component: <AddnewGuest mode={'Add'} moduleMode={FrontDeskType.DueIn} />,
  },
  {
    props: { exact: true, path: editNewGuestUrl },
    component: <AddnewGuest mode={'Edit'} moduleMode={FrontDeskType.DueIn} />,
  },
]
