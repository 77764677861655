import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight, Search } from '@material-ui/icons'
import { SearchActionHeader } from 'components/Header/SearchActionHeader'
import { ContentWrapperForHomeList } from 'components/Layout/ContentWrapper'
import AppContext from 'containers/App/Store/AppContext'
import { Filter } from 'containers/helper/SearchFilter'
// import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  useHotelListingLazyQuery,
  useLoggedInUserProfileQuery,
  useUpdateLastestAccessHotelQuery,
  useGetHotelPermissionQuery,
  useGetRolePermissionQuery,
  useGetHotelPermissionLazyQuery,
  useGetRolePermissionLazyQuery,
  useUpdateLastestAccessHotelLazyQuery,
  useIsQ3FInterfaceLazyQuery,
  useHotelDetailsLazyQuery,
} from 'generated/graphql'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import localForage from 'localforage'
export const AllHotelList = (props: any) => {
  const { globalState, dispatch } = useContext(AppContext as any)

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: globalState.drawerOpen,
    })
  }
  let history = useHistory()
  const [filterSearch, setFilterSearch] = useState('')

  // localStorage.removeItem('Hotel')

  const {
    data: { loggedInUserProfile } = { loggedInUserProfile: null },
  } = useLoggedInUserProfileQuery({})

  const [
    UpdateLastest,
    {
      called: calledLastest,
      data: { updateLastestAccessHotel } = { updateLastestAccessHotel: null },
    },
  ] = useUpdateLastestAccessHotelLazyQuery({
    variables: {
      userIDs: loggedInUserProfile?.ID,
      hotelID: loggedInUserProfile?.lastestAccessedEntity,
    },
  })
  // const [
  //   getHotelPermissionQuery,
  //   {
  //     data: { getHotelPermission } = {
  //       getHotelPermission: [],
  //     },
  //   },
  // ] = useGetHotelPermissionLazyQuery({
  //   fetchPolicy: 'network-only',
  // })
  // const [
  //   getRolePermissionQuery,
  //   {
  //     data: { getRolePermission } = {
  //       getRolePermission: [],
  //     },
  //   },
  // ] = useGetRolePermissionLazyQuery({
  //   fetchPolicy: 'network-only',
  // })
  localStorage.setItem('LoggedInUser', JSON.stringify(loggedInUserProfile))

  const [
    IsQ3FInterfaceQuery,
    {
      data: { IsQ3FInterface } = { IsQ3FInterface: [] },
    }
  ]
   = useIsQ3FInterfaceLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      localStorage.setItem('IsQ3FInterface', JSON.stringify(data?.IsQ3FInterface))
      // history.push(props.route)
      // handleDrawer()
      // props.close()
    }
  })

  const [
    HotelDetailData,
    {  data: { HotelDetails } = { HotelDetails: [] } },
  ] = useHotelDetailsLazyQuery({
    
    fetchPolicy: 'no-cache',

    onCompleted: data => {
      localStorage.setItem('Hotel', JSON.stringify(data?.HotelDetails))
      history.push(props.route)
      handleDrawer()
      props.close()
    }
  })

  const [
    HotelData,
    { called, data: { HotelListing } = { HotelListing: [] } },
  ] = useHotelListingLazyQuery({
    variables: {
      AccountID: loggedInUserProfile?.accountID,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    loggedInUserProfile && !called && HotelData()
    loggedInUserProfile && !calledLastest && UpdateLastest()

    // localForage.setItem('permission', getRolePermission)
    // localForage.setItem('HotelPermission', getHotelPermission)
    // if (HotelListing.length > 0) {
    //   let Hotels: any = []
    //   HotelListing.forEach(i => {
    //     Hotels.push({
    //       ID: i?.ID,
    //       HotelName: i?.HotelName,
    //       State: i?.HotelAddress?.state,
    //     })
    //   })
    //   localStorage.setItem('Hotels', JSON.stringify(Hotels))
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserProfile, HotelListing.length, called])

  // role perm info

  return (
    <>
      {/* <div style={{ marginLeft: props?.setting ? 0 : -10 }}>
        {HotelListing?.length > 0 && (
          <SearchActionHeader
            title="Hotels"
            lengthNumber={Filter(HotelListing, filterSearch).length}
            AllList={!props?.setting}
            notFixed={props?.setting}
            value={HotelListing.length}
            search
            onChangeAction={e => {
              setFilterSearch(e.target.value)
            }}
            onCloseAction={e => setFilterSearch('')}
          />
        )}
      </div> */}
      <div className=" flex">
        <div className="rm-padding ">
          <List
            className={`search-field  dialog-search `}
            style={{ paddingTop: '0px' }}
          >
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <>
                    <InputBase
                      placeholder="Search here.."
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={e => {
                        // dispatch({ type: 'filterSearch', payload: e.target.value })
                        setFilterSearch(e.target.value)
                      }}
                      // inputRef={input => input && searchBar && input.focus()}
                    />
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="close">
                  <Search />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <div
            style={{
              padding: '8px 6px',
            }}
          >
            {HotelListing?.length === 0 ? (
              <EmptyList title="No Records Found" />
            ) : (
              <List
                className="core-list"

                // style={{ marginTop: props?.setting ? 0 : 60 }}
              >
                {Filter(HotelListing, filterSearch).map((i, index) => {
                  return (
                    <ListItem
                      // style={{ boxShadow: 'none' }}
                      // divider
                      key={index}
                      onClick={() => {
                        // history.push(props.route)
                        // localStorage.setItem('Hotel', JSON.stringify(i))
                        UpdateLastest({
                          variables: {
                            userIDs: loggedInUserProfile?.ID,
                            hotelID: i.ID,
                          },
                        })
                        IsQ3FInterfaceQuery({
                          variables: {
                            HotelID: i.ID,
                            UserID: loggedInUserProfile?.ID,
                          }
                        })
                        HotelDetailData({
                          variables: {
                            HotelID: i.ID,
                          }
                        })
                        // getRolePermissionQuery()
                        // getHotelPermissionQuery()
                        // props.close()
                      }}
                    >
                      <IconItem
                        image={i?.LogoImage?.ImageURL}
                        imageType="img"
                        badgeType="default"
                      />
                      <ListItemText
                        primary={
                          <>
                            <span className="xsTitle flex-space">
                              {i?.HotelName}{' '}
                              <span className="mdDesc">({i.CompanyRegNo})</span>
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <span className="desc">
                              {i?.HotelAddress?.address || ''},<br />
                              {i?.HotelAddress?.postCode || ''},{' '}
                              {i?.HotelAddress?.city || ''},{' '}
                              {i?.HotelAddress?.state || ''},{' '}
                              {i?.HotelAddress?.country || ''}
                            </span>
                          </>
                        }
                      />
                      <ListItemSecondaryAction
                        onClick={() => {
                          // history.push(props.route)
                          localStorage.setItem('Hotel', JSON.stringify(i))
                          IsQ3FInterfaceQuery({
                            variables: {
                              HotelID: i.ID,
                              UserID: loggedInUserProfile?.ID,
                            }
                          })
                          HotelDetailData({
                            variables: {
                              HotelID: i.ID,
                            }
                          })
                        }}
                      >
                        <IconButton edge="end" aria-label="delete">
                          <KeyboardArrowRight />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </List>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
