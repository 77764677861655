import Person2 from '@ifca-root/react-component/src/assets/hotelIcons/person.svg'
import StandardCardContent from '@ifca-root/react-component/src/components/CardList/StandardCardContent'
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core'
import { CheckBox, ExpandMore } from '@material-ui/icons'
import {
  useBookingRoomAssignmentMutation, 
  useRoomAssignListingLazyQuery,
} from 'generated/graphql'
import {
  Grid,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { useSnackBar } from 'containers/helper/hooks/useSnackBar'
import React, { useEffect, useState } from 'react'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import SnackBarMsg from 'components/SnackBar/SnackBarMsg'

const dateFormat = require('dateformat')

interface Props {
  state: any
  dispatch: any
  ScannedInfo: any
  setScannedInfo: any
  HotelDetails: any
  qrUrlData: any
}

export const PreRegAssignRoom = (props: Props) => {
  const {
    state,
    dispatch,
    ScannedInfo,
    setScannedInfo,
    HotelDetails,
    qrUrlData,
  } = props

  let galleryID: string = '';
  const [roomArray, setRoomArray] = useState([])
  const [autoAssign, setAutoAssign] = useState(false)
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const NumberOfRoomSelected = state.AvailableRoomList?.map(x => x.Rooms)
  .flat()
  ?.filter(el => el.IsSelected)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const [
    RoomAssignListingAPI,
    {
      loading: loadingRoomAssign,
      data: { RoomAssignListing } = { RoomAssignListing: [] },
    },
   ] = useRoomAssignListingLazyQuery({
    variables: {
      RoomTypeID: ScannedInfo?.RoomTypeID,
      IsBooking: ScannedInfo?.RoomID ? true : false,
      HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
      StartDate: dateFormat( ScannedInfo?.ArrivalDate  || new Date(), 'yyyy-mm-dd'),
      EndDate: dateFormat(ScannedInfo?.DepartureDate || new Date(), 'yyyy-mm-dd'),
      RegistrationIDs: [ScannedInfo?.ID],
    },
    fetchPolicy: 'no-cache',
  })

  const [
    BookingRoomAssignment,
    { loading },
  ] = useBookingRoomAssignmentMutation()

  useEffect(() => {
    if (qrUrlData !== "") {
      RoomAssignListingAPI()
    }
  }, [qrUrlData])

  useEffect(() => {
    let tempLocation: any[] = []
    let tempVC: number
    let tempVD: number
    let tempVI: number
    let tempVR: number
    RoomAssignListing?.map(i => {
      if (!tempLocation?.find(x => x.Location?.ID === i?.Location?.ID)) {
        tempLocation.push({
          Location: i?.Location,
          Rooms: RoomAssignListing?.filter(
            j => j.Location?.ID === i?.Location.ID && i.IsActive
          )?.map(x => {
            return {
              ...x,
              IsSelected:
                (
                  ScannedInfo?.IsPrincipal &&
                  ScannedInfo?.RoomID === x.ID &&
                  ScannedInfo?.RegistrationStatus !== 'CheckOut' &&
                  ScannedInfo?.RegistrationStatus !== 'Cancelled'
                ) ? true : false,
            }
          }),
        })
      }
    })

    tempVC = RoomAssignListing?.filter(x => x?.RoomAssignmentStatus?.Code === "VC").length
    tempVD = RoomAssignListing?.filter(x => x?.RoomAssignmentStatus?.Code === "VD").length
    tempVI = RoomAssignListing?.filter(x => x?.RoomAssignmentStatus?.Code === "VI").length
    tempVR = RoomAssignListing?.filter(x => x?.RoomAssignmentStatus?.Code === "VR").length

    dispatch({ type: 'AvailableRoomList', payload: tempLocation})
    dispatch({ type: 'countVC', payload: tempVC })
    dispatch({ type: 'countVD', payload: tempVD })
    dispatch({ type: 'countVI', payload: tempVI })
    dispatch({ type: 'countVR', payload: tempVR })
    setScannedInfo({
      ...ScannedInfo,
      groupByRoomType: [ScannedInfo],
    })
  }, [RoomAssignListing.length])

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: any
  ) => {
    index?.RoomAssignmentStatus?.CleanOrDirty === 'Dirty' &&
      snackFunc(`Room ${index?.RoomNo} is dirty!`, false)
    index?.RoomAssignmentStatus?.VacantOrOccupied === 'Occupied' &&
      snackFunc(`Room ${index?.RoomNo} is occupied!`, false)
    dispatch({
      type: 'roomSelected',
      payload: index,
    })
  }

  const handleAutoAssign = (event: any, index: number, LocationID: string) => {
    event.stopPropagation()
    let totalRoomCount = 1

    let results = []

    let available = state?.AvailableRoomList?.filter((i, idx) => idx === index)
      .map(x =>
        x.Rooms?.filter(
          y => y?.RoomAssignmentStatus?.VacantOrOccupied === 'Vacant'
        ).slice()
      )
      .flat()
    for (let i = 0; i < totalRoomCount; i++) {
      let selected = available[i]
      results.push({
        ...selected,
        IsSelected: true,
      })
    }

    let AvailableRoomList = state?.AvailableRoomList?.map(x => ({
      ...x,

      Rooms: x.Rooms?.map(el => ({
        ...el,
        IsSelected: results?.some(x => x.ID === el.ID),
      })),
    }))

    setRoomArray(AvailableRoomList?.filter(x => x?.Location?.ID === LocationID)[0]?.Rooms)
    setAutoAssign(true)

    results[0]?.RoomAssignmentStatus?.CleanOrDirty === 'Dirty' &&
      snackFunc(`Room ${results[0]?.RoomNo} is dirty!`, false)
    results[0]?.RoomAssignmentStatus?.VacantOrOccupied === 'Occupied' &&
      snackFunc(`Room ${results[0]?.RoomNo} is occupied!`, false)

    dispatch({
      type: 'roomSelected',
      payload: results[0],
    })
    dispatch({
      type: 'AvailableRoomList',
      payload: AvailableRoomList,
    })
    dispatch({ type: 'isTouched', payload: true })

    setScannedInfo({
      ...ScannedInfo,
      RoomID: results[0]?.ID,
      RoomNo: results[0]?.RoomNo,
    })
  }

  const handleSubmit = () => {
    BookingRoomAssignment({
      variables: {
        BookingID: ScannedInfo?.BookingID,
        RoomTypeID: ScannedInfo?.RoomTypeID,
        RegistrationIDs: [ScannedInfo?.ID],
        RoomIDs: state?.roomSelected?.ID,
      },
    }).then(i => {
      dispatch({ type: 'roomAssignDialog', payload: false })
      setScannedInfo({
        ...ScannedInfo,
        RoomID: state?.roomSelected?.ID,
        RoomNo: state?.roomSelected?.RoomNo,
      })
      if (i.data.BookingRoomAssignment === true) {
        snackFunc("Assign Room Succesfully", false)
      } else {
        snackFunc("Assign Room Fail", false)
      }
    })
  }

  const FloorPlanRoom = roomArray?.map(x => {
    if (x?.FloorPlan?.filter(x => x?.IsPreCheckin === false && x?.IsHotel === true)?.length > 0) {
      return x
    }
  })?.filter(v => v !== undefined)

  const roomAssignContent = (
    <div className="override-contentheader">
      <StandardCardContent
        section={{
          header: {
            title: (
              <ListItemText
                primary={
                  <span className="flex">
                    <span className="xxTitle color-primary-orange flex-space">
                      Stay Information
                    </span>
                  </span>
                }
                secondary={
                  <>
                    <span className="flex" style={{ color: '#212121' }}>
                      <span className="desc flex-space">
                        {ScannedInfo?.RoomType?.Code}
                      </span>
                    </span>
                    <span className="flex" style={{ color: '#212121' }}>
                      <span className="desc flex-space">
                        VC: {state?.countVC || 0}
                        <TextSeparator />{' '}
                        VD: {state?.countVD || 0}
                        <TextSeparator />{' '}
                        VI: {state?.countVI || 0}
                        <TextSeparator />{' '}
                        VR: {state?.countVR || 0}
                      </span>
                    </span>

                  </>
                }
              />
            )
          }
        }}
      >
        {state.AvailableRoomList?.map((el, index) => (
          <Accordion
            key={index}
            className="accordion-roomassign"
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{
                background:
                  state.Expanded === index ? '#faf2e8' : '#dddddd',
              }}
            >
              <div className="flex">
                <span className="flex-space xsTitle margin-auto">
                  {el?.Location?.Description}
                  {''}
                  <span className="desc" style={{ paddingLeft: '4px' }}>
                    (
                    <span
                      className={`${
                        el?.Rooms?.filter(x => x.IsSelected)?.length ===
                        el.Rooms?.length
                          ? ''
                          : 'color-red'
                      }`}
                    >
                      {el?.Rooms?.filter(x => x.IsSelected)?.length}
                    </span>
                    /{el.Rooms?.length})
                  </span>
                </span>{' '}
                {state.Expanded === index && (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      className="header-button"
                      startIcon={<CheckBox />}
                      disableFocusRipple
                      disableTouchRipple
                      disabled={
                        NumberOfRoomSelected?.length > 0 || 
                        (HotelDetails?.IsFloorPlan === true  && FloorPlanRoom?.length === 0)
                      }
                      onClick={event => handleAutoAssign(event, index, el?.Location?.ID)}
                    >
                      Auto
                    </Button>
                  </>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                justify={'flex-start'}
                className="room-block"
                spacing={1}
              >
                {el?.Rooms?.map((r, roomIndex) => (
                  <Grid item xs={3} key={roomIndex}>
                    <ListItem
                      dense
                      button
                      disableGutters
                      className="xsTitle"
                      style={{
                        background: r?.RoomAssignmentStatus?.RGBColor,
                      }}
                      selected={
                        state?.AvailableRoomList?.[index]?.Rooms[roomIndex]
                          ?.IsSelected
                      }
                      disabled={
                        state?.AvailableRoomList?.[index]?.Rooms[roomIndex]?.IsSelected
                          ? false
                          : NumberOfRoomSelected.length === 1
                            ? true
                            : false
                      }
                      onClick={e => {
                        const newArr: any = state.AvailableRoomList
                        let selected = newArr[index]?.Rooms[roomIndex]
                        selected.IsSelected = !selected.IsSelected

                        console.log(selected, 'selectedheree')

                        if (selected.IsSelected === true) {
                          handleListItemClick(e, el?.Rooms[roomIndex])
                          handleClick(e)

                          dispatch({
                            type: 'roomSelected',
                            payload: {
                              ...selected,
                              IsSelected: true,
                            },
                          })

                          dispatch({
                            type: 'AvailableRoomList',
                            payload: newArr,
                          })
                        } else {
                          dispatch({
                            type: 'roomSelected',
                            payload: {
                              ...selected,
                              IsSelected: false,
                            },
                          })

                          dispatch({
                            type: 'roomSelected',
                            payload: null,
                          })
                        }
                        dispatch({ type: 'isTouched', payload: true })

                      }}
                    >
                      <ListItemText
                        primary={
                          <span className="xsTitle"> {r.RoomNo}</span>
                        }
                        secondary={
                          <>
                            <span className=" desc" style={{ float: "left"}}>
                              {r.RoomAssignmentStatus?.Code}
                            </span>
                            <span className="desc ">
                              <img
                                src={Person2}
                                alt="ifca"
                                width={10}
                                loading="lazy"
                              />
                              {r.MaxGuest}
                            </span>
                          </>
                        }
                      />
                    </ListItem>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </StandardCardContent>
    </div>
  )

  return (
    <>
      <CommonDialog
        fullWidth={true}
        open={state.roomAssignDialog}
        onClose={() => { 
          dispatch({ type: 'roomAssignDialog', payload: false}) 
          dispatch({ type: 'roomSelected', payload: null })
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div className="session">
                    <div className="flex session">
                      <div className="title flex-space">Assign Room</div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          },
          body: () => (
            <>
            {roomAssignContent}
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    dispatch({ type: 'roomAssignDialog', payload: false}) 
                    dispatch({ type: 'roomSelected', payload: null })
                    setScannedInfo({
                      ...ScannedInfo,
                      RoomID: null,
                      RoomNo: null,
                    })
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleSubmit()
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              }
            ],
          }
        }}
      />
      <SnackBarMsg open={openSnackBar} message={snackBarMessage}  />
    </>
    
  )
}