export const Filter = (data, filterValue?, filterkey?) => {
  // console.log("Filter -> data", data)

  //! to get sub element must put the root node first in front
  //guest profile filterkey=['BookingNo', 'ArrivalDate','DepartureDate','Contact','FullName','Email','PhoneNo','NRIC', 'Registration','Room','RoomNo']
  // filterkey=['BookingNo', 'ArrivalDate','DepartureDate']

  if (filterkey === undefined) {
    return data?.filter(i => {
      let currentID: any
      if (i.ID !== undefined) {
        currentID = i.ID
      }

      //HX-2527
      // delete i.ID //! remove ID uuid

      var jsonStringify = JSON.stringify(i) //! Field Selected
      // console.log("Filter -> jsonStringify", jsonStringify)
      var jsonObj = JSON.parse(jsonStringify)
      var arr = []
      Object.keys(jsonObj).forEach(function (key) {
        arr.push(jsonObj[key]) //! Create New record with out FieldName
      })
      // console.log("Filter -> arr", arr)

      if (
        JSON.stringify(arr)?.toLowerCase().includes(filterValue.toLowerCase())
      ) {
        i.ID = currentID //!Add back ID
        return i
      }
    })
  } else {
    return data?.filter(i => {
      var jsonStringify = JSON.stringify(i, filterkey) //! Field Selected
      // console.log("Filter -> jsonStringify", jsonStringify)
      var jsonObj = JSON.parse(jsonStringify)
      var arr = []
      Object.keys(jsonObj).forEach(function (key) {
        arr.push(jsonObj[key]) //! Create New record with out FieldName
      })
      // console.log("Filter -> arr", arr)
      if (
        JSON.stringify(arr)?.toLowerCase().includes(filterValue?.toLowerCase())
      ) {
        return i
      }
    })
  }
}
