import React, {
} from 'react'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import {
    ListItem, ListItemText, List, Button,
} from '@material-ui/core'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';

interface Props {
    open: boolean
    close: () => void
    state: any
    index: any
}
export const AttachmentsDownloadDialog = ({
    open,
    close,
    state,
    index
}:
    Props) => {

    const buttonStyle = {
        fontSize: '10px',  // Adjust the font size to make it smaller
        padding: '3px 6px',  // Adjust the padding to control the button's size
    };
    return (
        <>
            <CommonDialog
                fullWidth={true}
                open={open}
                onClose={close}
                sections={{
                    header: {
                        dynamic: (
                            <div className="">
                                <div className="dialog-dynamic-content">
                                    <div className="session">
                                        <div className="flex session">
                                            <div className="xsTitle color-primary-orange flex-space">
                                                View and Download Attachments
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="infoline-content">
                                    <div className="infoline desc  display-flex"></div>
                                </div>
                            </div>
                        ),
                    },
                    body: () => (
                        <>
                            <List className="core-list">
                                {/* {state[index]?.AttachmentDetail?.length === index ? (
                                    <EmptyList title="No Record Found" />
                                ) : null} */}
                                {state[index]?.AttachmentDetail?.map((el, index) => (
                                    <ListItem
                                        key={index}
                                    >
                                        <ListItemText
                                            primary={
                                                <>
                                                 <span className="xsTitle">{index + 1}. </span>
                                                 <span>&nbsp;&nbsp;&nbsp;</span>
                                                    <span className="xsTitle flex-space ">
                                                        {el?.Gallery?.BucketFileName}
                                                    </span>
                                                    <span className="desc">
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            style={buttonStyle} // Apply custom styles
                                                            onClick={() => {
                                                                window.open(el?.Gallery?.ImageURL, '_blank');
                                                            }}
                                                        >
                                                            Download
                                                        </Button>
                                                    </span>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ),
                    footer: {
                        actions: [
                            {
                                displayText: 'Close',
                                props: {
                                    onClick: () => {
                                        close()
                                    },
                                    variant: 'contained',
                                    color: 'primary',
                                },
                            },
                        ],
                    },
                }}
            />
        </>
    )
}